import React, { useState, useEffect } from 'react'


import { domToPng } from 'modern-screenshot'
import pptxgen from "pptxgenjs";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import * as actions from "./_redux/Actions";
import { exportReportToPdf } from "../../../setup/features/report.slice";
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import SocialListingHeader from './components/shared/header';
import SocialListingSummary from './components/SocialListingSummary';
import SocialListingBenchmark from './components/SocialListingBenchmark';
import SocialListingContentAnalysis from './components/SocialListingAnalysis';

const dateConverter = (dateString: any) => {
    var dateParts = dateString.split("/");

  
    
    var dateObject = new Date(Number(`20${dateParts[2]}`), dateParts[1] - 1, +dateParts[0]);
    return dateObject.toISOString();
}

const cloneDate = (dateObject: any) => {
    return new Date(dateObject.valueOf());
}

const GetDateDiff = (start: any, end: any) => {
    const date1 = start;
    const date2 = end;
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


    return diffDays;

}


const ReturnComparedDates = (start: any, end: any, dateDiff: any) => {


    
    let startNew = cloneDate(start)
    let comparedEnd = startNew.setDate(startNew.getDate() - 1);
 
    let dateEndTemp = comparedEnd;
    let endNew = cloneDate(comparedEnd)
    let comparedStart = endNew.setDate(endNew.getDate() - (dateDiff));
  


    return {
        startDateNew: new Date(comparedStart),
        endDateNew: new Date(comparedEnd)
    };

}

const CopyExternalUrl = (landscapeId: any) => {
    let shareableLink = `${window.location.origin}/reports/social-intelligence/${landscapeId}`

    navigator.clipboard.writeText(shareableLink);
    toast.success('Shareable link has been copied');
}



const SocialIntelligence = ({ isExternal, landscapeId, isPdf }: { isExternal?: boolean, landscapeId?: any, isPdf?: boolean }) => {
    const dispatch = useDispatch<any>();
    let today = new Date();
    const [activeNav, setSectionInView] = useState("brand_glance");
    const [startDate, setStartDate] = useState<any>(new Date(new Date().setDate(today.getDate() - 90)));
    const [endDate, setEndDate] = useState<any>(today);
    const [startDateToCompare, seStartDateToCompare] = useState<any>();
    const [endDateToCompare, setEndDateToCompare] = useState<any>();
    const [dateFilterOption, setDateFilterOption] = useState<any>("Last Quarter");
    const [dateDiffValue, setDateDiffValue] = useState<any>();
    const [chosenBrand, setChosenBrand] = useState<any>();
    const [brandsList, setBrandsList] = useState<any[]>();
    const [brandLandscapeList, setBrandLandscapeList] = useState<any[]>();
    const [bothDateChosen, setBothDateChosen] = useState<boolean>(true);
    const [isFetchingData, setFetchingData] = useState<boolean>(true);
    const [pdfToDownload, setPdfToDownload] = useState<any>("")
    const [dateFilter, setDateFilter] = useState<any>(2)
    const [compareFilter, setCompareFilter] = useState<any>(1)
    const [dateRangeText, setDateRangeText] = useState<any>("Last Quarter")

    const [currentFromTxt, setCurrentFromTxt] = useState<any>(startDate);
    const [currentEndTxt, setCurrentEndTxt] = useState<any>(endDate);

    const [compareStartTxt, setCompareStartTxt] = useState<any>(startDateToCompare);
    const [compareEndTxt, setCompareEndTxt] = useState<any>(endDateToCompare);
    let pptx = new pptxgen();

    const { brandsListInfo,
        brandsSummaryInfo,
        socialTimeSeriesInfo,
        brandsOptimizationInfoData,
        brandSocialPostsInfo } = useSelector(
            (state: any) => ({
                brandsListInfo: state.brandAtGlance.brandsListInfo,
                brandsSummaryInfo: state.brandAtGlance.brandsSummaryInfo,
                brandsOptimizationInfoData: state.brandAtGlance.brandsOptimizationInfoData,
                socialTimeSeriesInfo: state.brandAtGlance.socialTimeSeriesInfo,
                brandSocialPostsInfo: state.brandAtGlance.brandSocialPostsInfo,
            })
        );


    useEffect(() => {

        if (!brandsListInfo) {
            if (isExternal && landscapeId) {
                dispatch(actions.fetchCampaignCompanies(landscapeId, isExternal))

            } else {
                dispatch(actions.fetchCampaignCompanies())
            }
            setDefaultDateToLastQuarter()
        }
    }, []);



    useEffect(() => {


        if (brandsListInfo) {

            setBrandsList(brandsListInfo)
            setBrandLandscapeList(brandsListInfo[0]?.companies)


            if (Array.isArray(brandsListInfo)) {
                setChosenBrand(brandsListInfo[0])
            } else {
                setChosenBrand(brandsListInfo)
            }



        }

    }, [brandsListInfo]);

    useEffect(() => {

        if (chosenBrand && bothDateChosen) {

            setBrandLandscapeList(chosenBrand?.companies)
            setFetchingData(true)
            let formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1) >= 10 ? startDate.getMonth() + 1 : `0${startDate.getMonth() + 1}`}-${(startDate.getDate()) >= 10 ? startDate.getDate() : `0${startDate.getDate()}`}`
            let formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1) >= 10 ? endDate.getMonth() + 1 : `0${endDate.getMonth() + 1}`}-${(endDate.getDate()) >= 10 ? endDate.getDate() : `0${endDate.getDate()}`}`
            let startDateTemp = startDate;
            let endDateTemp = endDate;
            let dateDiff = GetDateDiff(startDateTemp, endDateTemp) + 1;
            setDateDiffValue(dateDiff);

            let { startDateNew, endDateNew } = ReturnComparedDates(startDateTemp, endDateTemp, dateDiff)

            if(startDateToCompare){
                startDateNew = startDateToCompare
            }
            if(endDateToCompare){
                endDateNew = endDateToCompare
            }


            let formattedStartDate2, formattedEndDate2;

            if ((dateFilterOption === "custom" || !dateFilterOption) && startDateNew && endDateNew) {
                formattedStartDate2 = `${startDateNew.getFullYear()}-${(startDateNew.getMonth() + 1) >= 10 ? startDateNew.getMonth() + 1 : `0${startDateNew.getMonth() + 1}`}-${(startDateNew.getDate()) >= 10 ? startDateNew.getDate() : `0${startDateNew.getDate()}`}`
                formattedEndDate2 = `${endDateNew.getFullYear()}-${(endDateNew.getMonth() + 1) >= 10 ? endDateNew.getMonth() + 1 : `0${endDateNew.getMonth() + 1}`}-${(endDateNew.getDate()) >= 10 ? endDateNew.getDate() : `0${endDateNew.getDate()}`}`
            }

           

            if ((dateFilterOption === "Last Month" || dateFilterOption === "Last Quarter")) {

                formattedStartDate2 = `${startDateToCompare.getFullYear()}-${(startDateToCompare.getMonth() + 1) >= 10 ? startDateToCompare.getMonth() + 1 : `0${startDateToCompare.getMonth() + 1}`}-${(startDateToCompare.getDate()) >= 10 ? startDateToCompare.getDate() : `0${startDateToCompare.getDate()}`}`
                formattedEndDate2 = `${endDateToCompare.getFullYear()}-${(endDateToCompare.getMonth() + 1) >= 10 ? endDateToCompare.getMonth() + 1 : `0${endDateToCompare.getMonth() + 1}`}-${(endDateToCompare.getDate()) >= 10 ? endDateToCompare.getDate() : `0${endDateToCompare.getDate()}`}`
            }


            let queryParams = `startDate=${formattedStartDate}&endDate=${formattedEndDate}&companyId=${chosenBrand?.focusCompanyId}`;
            let queryParams2 = `startDate=${formattedStartDate2}&endDate=${formattedEndDate2}&companyId=${chosenBrand?.focusCompanyId}`;
            let queryParamsExc = `startDate=${formattedStartDate}&endDate=${formattedEndDate}&companyIdExc=${chosenBrand?.focusCompanyId}`;
            let queryParamsAll = `startDate=${formattedStartDate}&endDate=${formattedEndDate}${dateDiff > 7 ? `&dateType=weekly` : ''}`;

            dispatch(actions.fetchBrandSummary(chosenBrand?.id, queryParams, queryParams2, isExternal))
                .then((res1: any) => {

                    dispatch(actions.fetchSocialPosts(chosenBrand?.id, queryParams, queryParamsExc, isExternal))
                        .then((res2: any) => {
                            dispatch(actions.fetchSocialTimeSeries(chosenBrand?.id, queryParamsAll, isExternal))
                                .then((res3: any) => {
                                    dispatch(actions.fetchCampaignOptimization(chosenBrand?.focusCompanyId, isExternal))
                                        .then((res4: any) => {
                                            setFetchingData(false)
                                        })
                                        .catch(() => {

                                        });
                                })
                                .catch(() => {

                                });
                        })
                        .catch(() => {

                        });

                })
                .catch(() => {

                });

            
        }

    }, [chosenBrand, bothDateChosen, startDate, endDate]);

    useEffect(() => {
        if (pdfToDownload) {
            let payload: any = {
                url: pdfToDownload,
                style: ".pdf-wrap { height: 1100px !important;} .pdf-wrap>div{height:80%} .pdf-wrap .mt-4.p-8.bg-white.section_wrap_content, .pdf-wrap .mt-16, .pdf-wrap .mt-8.py-8 { padding-top:0 !important; margin-top: 0 !important} #area_chart1>div{width:100% !important; height:300px !important}",
                options: {
                    "format": "A4",
                    "landscape": true,
                    "printBackground": true,

                }
            }

            dispatch(exportReportToPdf({
                data: payload
            }));

        }
    }, [pdfToDownload])


    const setDefaultDateToLastQuarter = () => {
        let lastMonthStart = moment(new Date()).subtract(1, 'quarter').startOf('quarter').format('DD/MM/YY');
        let lastMonthEnd = moment(new Date()).subtract(1, 'quarter').endOf('quarter').format('DD/MM/YY');


        let previousMonthStartToCompare = moment(new Date()).subtract(2, 'quarter').startOf('quarter').format('DD/MM/YY');
        let previousMonthEndToCompare = moment(new Date()).subtract(2, 'quarter').endOf('quarter').format('DD/MM/YY');


        setStartDate(moment(dateConverter(lastMonthStart)).toDate());
        setEndDate(moment(dateConverter(lastMonthEnd)).toDate());

        seStartDateToCompare(moment(dateConverter(previousMonthStartToCompare)).toDate());
        setEndDateToCompare(moment(dateConverter(previousMonthEndToCompare)).toDate());
    }

    const downloadPdf = (landscapeId: any) => {
        setPdfToDownload("")

        let shareableLink = `${window.location.origin}/reports/social-intelligence/download/pdf/${landscapeId}`
        setPdfToDownload(shareableLink)
    }


    const ExportPPTReport = () => {

        let formattedStartDate = `${startDate.getDate()}_${startDate.getMonth() + 1}_${startDate.getFullYear()}`
        let formattedEndDate = `${endDate.getDate()}_${endDate.getMonth() + 1}_${endDate.getFullYear()}`

        pptx.layout = "LAYOUT_WIDE";

        pptx.defineSlideMaster({
            title: "MASTER_SLIDE",
            background: { color: "#FFFFFF" },

            objects: [
                { rect: { x: 0, y: 6.9, w: '100%', h: 0.6, fill: { color: '#FFFFFF' } } },

                { image: { x: 11.3, y: 7.0, w: 1.2, h: 0.45, path: toAbsoluteUrl('/media/logos/social-listing.png') } },
            ],
            slideNumber: { x: 1.0, y: 7.0, color: '000000' },
        });

        let slide1 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide2 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide3 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide4 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide5 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        
        let slide7 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide8 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide9 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide10 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide11 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide12 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide13 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide14 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide15 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide16 = pptx.addSlide({ masterName: "MASTER_SLIDE" });

        let slide1Elem = document.getElementById("slide1") as HTMLDivElement;
        let slide2Elem = document.getElementById("slide2") as HTMLDivElement;
        let slide3Elem = document.getElementById("slide3") as HTMLDivElement;
        let slide4Elem = document.getElementById("slide4") as HTMLDivElement;
        let slide5Elem = document.getElementById("slide5") as HTMLDivElement;
        let slide6Elem = document.getElementById("slide6") as HTMLDivElement;
        let slide7Elem = document.getElementById("slide7") as HTMLDivElement;
        let slide8Elem = document.getElementById("slide8") as HTMLDivElement;
        let slide9Elem = document.getElementById("slide9") as HTMLDivElement;
        let slide10Elem = document.getElementById("slide10") as HTMLDivElement;
        let slide11Elem = document.getElementById("slide11") as HTMLDivElement;
        let slide12Elem = document.getElementById("slide12") as HTMLDivElement;
        let slide13Elem = document.getElementById("slide13") as HTMLDivElement;
        let slide14Elem = document.getElementById("slide14") as HTMLDivElement;
        let slide15Elem = document.getElementById("slide15") as HTMLDivElement;
        let slide16Elem = document.getElementById("slide16") as HTMLDivElement;


        domToPng(slide1Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide1.addImage({ data: bannerImg, w: "85%", h: "15%", x: "7.5%", y: "25%" })








        })
        domToPng(slide2Elem).then(dataUrl => {


            let bannerImg = dataUrl.split("data:")[1]
            slide2.addImage({ data: bannerImg, w: "48%", h: "44%", x: "22.5%", y: "25%" })



        })
        slide3Elem.classList.add("b-l-0")
        domToPng(slide3Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide3.addImage({ data: bannerImg, w: "51%", h: "50%", x: "20%", y: "15%" })



        })

        slide4Elem.classList.add("b-l-0")
        domToPng(slide4Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide4.addImage({ data: bannerImg, w: "51%", h: "50%", x: "20%", y: "15%" })


        })

        slide5Elem.classList.add("all_borders")
        domToPng(slide5Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide5.addImage({ data: bannerImg, w: "85%", h: "18%", x: "7.5%", y: "25%" })




        })
        domToPng(slide6Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide5.addImage({ data: bannerImg, w: "85%", h: "8%", x: "7.5%", y: "60%" })




        })
        slide7Elem.classList.add("no_borders")
        slide8Elem.classList.add("no_borders")
        slide9Elem.classList.add("no_borders")
        slide10Elem.classList.add("no_borders")
        slide11Elem.classList.add("no_borders")
        slide12Elem.classList.add("no_borders")

        domToPng(slide7Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide7.addImage({ data: bannerImg, w: "51%", h: "50%", x: "20%", y: "15%" })


        })
        domToPng(slide8Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide8.addImage({ data: bannerImg, w: "51%", h: "50%", x: "20%", y: "15%" })




        })

        domToPng(slide9Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide9.addImage({ data: bannerImg, w: "51%", h: "50%", x: "20%", y: "15%" })




        })

        domToPng(slide10Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide10.addImage({ data: bannerImg, w: "51%", h: "50%", x: "20%", y: "15%" })



        })

        domToPng(slide11Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide11.addImage({ data: bannerImg, w: "51%", h: "50%", x: "20%", y: "15%" })




        })
        domToPng(slide12Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide12.addImage({ data: bannerImg, w: "51%", h: "50%", x: "20%", y: "15%" })




        })
        domToPng(slide13Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide13.addImage({ data: bannerImg, w: "96%", h: "8%", x: "2%", y: "25%" })




        })
        domToPng(slide14Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide14.addImage({ data: bannerImg, w: "80%", h: "29%", x: "10%", y: "10%" })




        })
        domToPng(slide15Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide15.addImage({ data: bannerImg, w: "85%", h: "90%", x: "7.5%", y: "2%" })




        })
        domToPng(slide16Elem).then(dataUrl => {



            let bannerImg = dataUrl.split("data:")[1]
            slide16.addImage({ data: bannerImg, w: "85%", h: "90%", x: "7.5%", y: "2%" })


            pptx.writeFile({ fileName: `${chosenBrand?.name}_${formattedStartDate}-to-${formattedEndDate}` });

        })
        
    }


    return (
        <>
            <div className="social_listing_container">
                {chosenBrand && !isFetchingData &&
                    // {chosenBrand && !isFetchingData && brandsSummaryInfo && socialTimeSeriesInfo &&
                    <>
                        <SocialListingHeader
                            isExternal={isExternal}
                            setSectionInView={setSectionInView}
                            startDate={startDate}

                            currentFromTxt={currentFromTxt}
                            setCurrentFromTxt={setCurrentFromTxt}
                            currentEndTxt={currentEndTxt}
                            setCurrentEndTxt={setCurrentEndTxt}
                            compareStartTxt={compareStartTxt}
                            setCompareStartTxt={setCompareStartTxt}
                            compareEndTxt={compareEndTxt}
                            setCompareEndTxt={setCompareEndTxt}

                            setDateRangeText={setDateRangeText}
                            compareFilter={compareFilter}
                            setCompareFilter={setCompareFilter}
                            dateRangeText={dateRangeText}
                            setDateFilter={setDateFilter}
                            dateFilter={dateFilter}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            returnComparedDates={ReturnComparedDates}
                            getDateDiff={GetDateDiff}
                            setEndDate={setEndDate}
                            startDateToCompare={startDateToCompare}
                            seStartDateToCompare={seStartDateToCompare}
                            endDateToCompare={endDateToCompare}
                            setEndDateToCompare={setEndDateToCompare}
                            setDateFilterOption={setDateFilterOption}
                            dateFilterOption={dateFilterOption}
                            chosenBrand={chosenBrand}
                            setChosenBrand={setChosenBrand}
                            brandsList={brandsList}
                            setBothDateChosen={setBothDateChosen}
                            setFetchingData={setFetchingData}
                        />
                        <div className="s_l_content">
                            {
                                (brandsSummaryInfo && chosenBrand) &&
                                <SocialListingSummary startDateToCompare={startDateToCompare} endDateToCompare={endDateToCompare} isPdfPage={isPdf} isExternal={isExternal} brandsOptimizationInfoData={brandsOptimizationInfoData} brandSocialPosts={brandSocialPostsInfo} brandsList={brandLandscapeList} timeSeries={socialTimeSeriesInfo} chosenBrand={chosenBrand} brandsSummaryInfo={brandsSummaryInfo} />
                            }
                            {(socialTimeSeriesInfo && brandsSummaryInfo && socialTimeSeriesInfo && brandLandscapeList && brandSocialPostsInfo && chosenBrand) &&
                                <>
                                    <SocialListingBenchmark isPdfPage={isPdf} brandsSummaryInfo={brandsSummaryInfo} timeSeries={socialTimeSeriesInfo} brandsList={brandLandscapeList} />
                                    <SocialListingContentAnalysis dateDiff={dateDiffValue} isPdfPage={isPdf} brandSocialPosts={brandSocialPostsInfo} brandsList={brandLandscapeList} timeSeries={socialTimeSeriesInfo} chosenBrand={chosenBrand} brandsSummaryInfo={brandsSummaryInfo} />
                                </>
                            }
                            {(!isExternal && brandsSummaryInfo && socialTimeSeriesInfo && brandsSummaryInfo && socialTimeSeriesInfo && brandLandscapeList && brandSocialPostsInfo && chosenBrand) &&
                                <div className="wrapp">
                                    <div className="copy_shareable_link" onClick={() => CopyExternalUrl(chosenBrand?.id)}>
                                        Copy Shareable Link
                                    </div>
                                </div>
                            }

                            {(!isExternal && brandsSummaryInfo && socialTimeSeriesInfo && brandsSummaryInfo && socialTimeSeriesInfo && brandLandscapeList && brandSocialPostsInfo && chosenBrand) &&
                                <div className="pdf_link_wrapp">
                                    <div className="pdf_downlink_cta" onClick={() => downloadPdf(chosenBrand?.id)}>
                                        Download as PDF
                                    </div>
                                </div>
                            }
                            {(!isExternal && brandsSummaryInfo && socialTimeSeriesInfo && brandsSummaryInfo && socialTimeSeriesInfo && brandLandscapeList && brandSocialPostsInfo && chosenBrand) &&
                                <div className="ppt_link_wrapp">
                                    <div className="ppt_downlink_cta" onClick={() => ExportPPTReport()}>
                                        Download as powerpoint
                                    </div>
                                </div>
                            }

                        </div>
                    </>
                }
                {(isFetchingData) &&
               
               
                    <div className="fetching-data">
                        <img src={toAbsoluteUrl('/media/logos/logo-5.png')} alt="" />

                        <div className="loading">Please wait...</div>
                    </div>
                }

                {(!isFetchingData && !socialTimeSeriesInfo) &&
                    <div className="fetching-data">
                        {/* <img src={toAbsoluteUrl('/media/logos/logo-5.png')} alt="" /> */}

                        <div className="loading">Unable to load Time Series Data</div>
                    </div>
                }

                {(!isFetchingData && !brandsSummaryInfo) &&
                    <div className="fetching-data">
                        {/* <img src={toAbsoluteUrl('/media/logos/logo-5.png')} alt="" /> */}

                        <div className="loading">Unable to load Brand Summary Data</div>
                    </div>
                }

            </div>

        </>
    )
}

export { SocialIntelligence }
