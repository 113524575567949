import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ValueChange from '../shared/ValueChange';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'


export default function AnalysisHeadStats(props: any) {
    let { chosenBrand, timeSeries, dataToCompare } = props;

    // let seriesStartDate = timeSeries[0];
    // let seriesEndDate = timeSeries[timeSeries.length - 1];

    let seriesStartDate = chosenBrand;
    let seriesEndDate = dataToCompare;

    let totalPostsTrendStart = (seriesStartDate?.instagramPosts || 0) + (seriesStartDate?.twitterTweets || 0) + (seriesStartDate?.facebookPosts || 0) + (seriesStartDate?.youTubePosts || 0) + (seriesStartDate?.tikTokPosts || 0);
    let totalPostsTrendEnd = (seriesEndDate?.instagramPosts || 0) + (seriesEndDate?.twitterTweets || 0) + (seriesEndDate?.facebookPosts || 0) + (seriesEndDate?.youTubePosts || 0) + (seriesEndDate?.tikTokPosts || 0);

    let totalPostsTrendChange: any = (((totalPostsTrendStart - totalPostsTrendEnd) / totalPostsTrendEnd) * 100).toFixed(2);


    let totalFollowersTrendStart = seriesStartDate?.tikTokFollowers + seriesStartDate?.instagramFollowedBy + seriesStartDate?.facebookPageLikes + seriesStartDate?.youTubeSubscribers;
    let totalFollowersTrendEnd = seriesEndDate?.tikTokFollowers + seriesEndDate?.instagramFollowedBy + seriesEndDate?.facebookPageLikes + seriesEndDate?.youTubeSubscribers;;





    let totalFollowersTrendChange: any = (((totalFollowersTrendStart - totalFollowersTrendEnd) / totalFollowersTrendEnd) * 100).toFixed(2);





    let totalVideoViewsTrendStart = (seriesStartDate?.twitterVideoViews || 0) + (seriesStartDate?.youTubePostViews || 0) + (seriesStartDate?.tikTokViews || 0) + (seriesStartDate?.reelviews || 0);
    let totalVideoViewsTrendEnd = (seriesEndDate?.twitterVideoViews || 0) + (seriesEndDate?.youTubePostViews || 0) + (seriesEndDate?.tikTokViews || 0) + (seriesEndDate?.reelviews || 0);


    let totalVideoViewsTrendChange: any = (((totalVideoViewsTrendStart - totalVideoViewsTrendEnd) / totalVideoViewsTrendEnd) * 100).toFixed(2);








    let totalEngagementTrendStart = seriesStartDate?.twitterTweetEngagementTotal + seriesStartDate?.youTubePostsEngagementTotal + seriesStartDate?.tikTokPostsEngagementTotal + seriesStartDate?.facebookPostEngagementTotal + seriesStartDate?.instagramPostsEngagementTotal;
    let totalEngagementTrendEnd = seriesEndDate?.twitterTweetEngagementTotal + seriesEndDate?.youTubePostsEngagementTotal + seriesEndDate?.tikTokPostsEngagementTotal + seriesEndDate?.facebookPostEngagementTotal + seriesEndDate?.instagramPostsEngagementTotal;




    let totalEngagementTrendChange: any = (((totalEngagementTrendStart - totalEngagementTrendEnd) / totalEngagementTrendEnd) * 100).toFixed(2);







    let totalPosts = (chosenBrand?.instagramPosts || 0) + (chosenBrand?.twitterTweets || 0) + (chosenBrand?.facebookPosts || 0) + (chosenBrand?.youTubePosts || 0) + (chosenBrand?.tikTokPosts || 0);
    let totalFollowers = (chosenBrand?.tikTokFollowers || 0) + (chosenBrand?.instagramFollowedBy || 0) + (chosenBrand?.facebookPageLikes || 0) + (chosenBrand?.youTubeSubscribers || 0);
    let totalVideoViews = (chosenBrand?.twitterVideoViews || 0) + (chosenBrand?.youTubePostViews || 0) + (chosenBrand?.tikTokViews || 0) + (chosenBrand?.reelviews || 0);
    let totalEngagement = (chosenBrand?.twitterTweetEngagementTotal || 0) + (chosenBrand?.youTubePostsEngagementTotal || 0) + (chosenBrand?.tikTokPostsEngagementTotal || 0) + (chosenBrand?.facebookPostEngagementTotal || 0) + (chosenBrand?.instagramPostsEngagementTotal || 0);

    let youtubeTubeER = ((chosenBrand?.youTubePostsEngagementTotal / chosenBrand?.youTubeSubscribers))
    // let twitterER = ((chosenBrand?.twitterTweetEngagementTotal/ chosenBrand?.youTubeSubscribers) )
    let tiktokER = ((chosenBrand?.tikTokPostsEngagementTotal / chosenBrand?.tikTokFollowers))
    let instagramER = ((chosenBrand?.instagramPostsEngagementTotal / chosenBrand?.instagramFollowedBy))
    let facebookER = ((chosenBrand?.facebookPostEngagementTotal / chosenBrand?.facebookPageLikes))

    // let aveEngagementRate: any = ((youtubeTubeER + tiktokER + instagramER + facebookER)/4)*100;
    let aveEngagementRate: any = totalFollowers > 0 ? (totalEngagement / totalFollowers) * 100 : 0;

    aveEngagementRate = totalPosts > 0 ? (aveEngagementRate || 0) / totalPosts : 0;


    let previousTotalFollowers = (dataToCompare?.tikTokFollowers || 0) + (dataToCompare?.instagramFollowedBy || 0) + (dataToCompare?.facebookPageLikes || 0) + (dataToCompare?.youTubeSubscribers || 0);
    let previousTotalPosts = (dataToCompare?.instagramPosts || 0) + (dataToCompare?.twitterTweets || 0) + (dataToCompare?.facebookPosts || 0) + (dataToCompare?.youTubePosts || 0) + (dataToCompare?.tikTokPosts || 0);

    let previousTotalEngagement = (dataToCompare?.twitterTweetEngagementTotal || 0) + (dataToCompare?.youTubePostsEngagementTotal || 0) + (dataToCompare?.tikTokPostsEngagementTotal || 0) + (dataToCompare?.facebookPostEngagementTotal || 0) + (dataToCompare?.instagramPostsEngagementTotal || 0);


    // let youtubeTubePreviousER = ((dataToCompare?.youTubePostsEngagementTotal/ dataToCompare?.youTubeSubscribers) )

    // let tiktokPreviousER = ((dataToCompare?.tikTokPostsEngagementTotal/ dataToCompare?.tikTokFollowers) )
    // let instagramPreviousER = ((dataToCompare?.instagramPostsEngagementTotal/ dataToCompare?.instagramFollowedBy) )
    // let facebookPreviousER = ((dataToCompare?.facebookPostEngagementTotal/ dataToCompare?.facebookPageLikes) )

    let aveEngagemenPrevioustRate: any = previousTotalFollowers > 0 ? (previousTotalEngagement / previousTotalFollowers) * 100 : 0;
    aveEngagemenPrevioustRate = previousTotalPosts > 0 ? (aveEngagemenPrevioustRate || 0) / previousTotalPosts : 0;
    // let aveEngagemenPrevioustRate: any = (youtubeTubePreviousER + tiktokPreviousER + instagramPreviousER + facebookPreviousER)/4;

    let totalAveEngagementRateTrendChange: any = (aveEngagemenPrevioustRate > 0 && totalPosts > 0) ? ((((aveEngagementRate - aveEngagemenPrevioustRate) / aveEngagemenPrevioustRate) * 100) / totalPosts).toFixed(2) : 0;


    let allEngagements = [
        { channel: "instagram", value: chosenBrand?.instagramPostsEngagementTotal },
        { channel: "youtube", value: chosenBrand?.youTubePostsEngagementTotal },
        { channel: "twitter", value: chosenBrand?.twitterTweetEngagementTotal },
        { channel: "facebook", value: chosenBrand?.facebookPostEngagementTotal },
        { channel: "tiktok", value: chosenBrand?.tikTokPostsEngagementTotal },
    ]

    const mec: any = allEngagements.reduce((accumulator, current) => {
        return accumulator.value > current.value ? accumulator : current;
    });







    const RoundUpNumber = ({ number, hasDecmals, id }: any) => {
        // let breakDown = number < 1000 ? number : `${Math.floor(number / 1000)}`

        let valToUse = number.toFixed(0);


        if (number >= 1000000) {
            // valToUse = `${Number(number.toFixed(0)) / 1000000}`;
            valToUse = `${(number / 1000000).toFixed(1)}`;
            return (
                <div id={id} className="value_txt">{valToUse}<span className="value_unit">m</span></div>
            )
        }
        if (number >= 1000) {
            valToUse = `${(number / 1000).toFixed(1)}`;
            return (
                <div id={id} className="value_txt">{valToUse}<span className="value_unit">k</span></div>
            )
        }

        if (hasDecmals) {
            valToUse = `${number.toFixed(2)}`

            return (
                <div id={id} className="value_txt">{valToUse}</div>
            )
        }


        return (
            <div id={id} className="value_txt">{valToUse}</div>
        )


    }
    return (
        <div className="summary_head for_analysis" id="slide13">
            <div className="each_summary_heading">
                <div className="summary_label">Number of Posts</div>
                <div className="summary_value_txt">
                    <RoundUpNumber id="analysis_posts" number={totalPosts} />
                    {/* <div className="value_txt">{roundUpNumber(totalPosts)}{totalPosts >= 1000 && <span className="value_unit">k</span>}</div> */}
                    <ValueChange value={`${Math.abs(totalPostsTrendChange)}%`} change={totalPostsTrendChange > 0 ? "rise" : "fall"} />
                    <div className="info_ico" id="post_count">
                        <img src={toAbsoluteUrl('/media/cpg/info_ico.png')}
                            alt=""
                        />
                    </div>
                </div>
                <Tooltip className="figures_tool_tip_wrap" anchorSelect="#analysis_posts" place="right">
                    {totalPosts.toLocaleString()}
                </Tooltip>
                <Tooltip className="tool_tip_wrap" anchorSelect="#post_count" place="right">
                    <div className="tip_wrap">
                        <div className="tip_header">Posts</div>
                        <div className="tip_msg">Total number of posts across all tracked social media</div>
                    </div>
                </Tooltip>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Video Views</div>
                <div className="summary_value_txt">
                    <RoundUpNumber id="analysis_views" number={totalVideoViews} />
                    {/* <div className="value_txt">{roundUpNumber(totalVideoViews)}{totalPosts >= 1000 && <span className="value_unit">k</span>}</div> */}
                    <ValueChange value={`${Math.abs(totalVideoViewsTrendChange)}%`} change={totalVideoViewsTrendChange > 0 ? "rise" : "fall"} />
                    <div className="info_ico" id="views_count">
                        <img src={toAbsoluteUrl('/media/cpg/info_ico.png')}
                            alt=""
                        />
                    </div>
                </div>
                <Tooltip className="figures_tool_tip_wrap" anchorSelect="#analysis_views" place="right">
                    {totalVideoViews.toLocaleString()}
                </Tooltip>
                <Tooltip className="tool_tip_wrap" anchorSelect="#views_count" place="right">
                    <div className="tip_wrap">
                        <div className="tip_header">Views</div>
                        <div className="tip_msg">Total number of video views across all tracked social media</div>
                    </div>
                </Tooltip>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Total Engagement</div>
                <div className="summary_value_txt">
                    <RoundUpNumber id="analysis_eng" number={totalEngagement} />
                    {/* <div className="value_txt">{roundUpNumber(totalEngagement)}{totalPosts >= 1000 && <span className="value_unit">k</span>}</div> */}
                    <ValueChange value={`${Math.abs(totalEngagementTrendChange)}%`} change={totalEngagementTrendChange > 0 ? "rise" : "fall"} />
                    <div className="info_ico" id="eng_count">
                        <img src={toAbsoluteUrl('/media/cpg/info_ico.png')}
                            alt=""
                        />
                    </div>
                </div>
                <Tooltip className="tool_tip_wrap" anchorSelect="#analysis_eng" place="right">
                    {totalEngagement.toLocaleString()}
                </Tooltip>
                <Tooltip className="tool_tip_wrap" anchorSelect="#eng_count" place="right">
                    <div className="tip_wrap">
                        <div className="tip_header">Engagements</div>
                        <div className="tip_msg">Total number of engagements across all tracked social media</div>
                    </div>
                </Tooltip>
            </div>

            <div className="each_summary_heading">
                <div className="summary_label">Average Engagement Rate Per Post</div>
                <div className="summary_value_txt">
                    {isNaN(aveEngagementRate) && "-"}
                    {!isNaN(aveEngagementRate) &&
                        <>
                            <RoundUpNumber id="analysis_er" number={aveEngagementRate} hasDecmals={true} />%

                            <ValueChange value={`${Math.abs(totalAveEngagementRateTrendChange)}%`} change={totalAveEngagementRateTrendChange > 0 ? "rise" : "fall"} />
                            <div className="info_ico" id="ave_eng_count">
                                <img src={toAbsoluteUrl('/media/cpg/info_ico.png')}
                                    alt=""
                                />
                            </div>
                        </>
                    }
                </div>
                <Tooltip className="tool_tip_wrap" anchorSelect="#analysis_er" place="right">
                    {aveEngagementRate.toLocaleString()}
                </Tooltip>
                <Tooltip className="tool_tip_wrap" anchorSelect="#ave_eng_count" place="right">
                <div className="tip_wrap">
                        <div className="tip_header">Engagement Rate</div>
                        <div className="tip_msg">The total number of interactions(likes and comments) per followers, per posts published during the selected time period expressed as a percentage</div>
                    </div>
                </Tooltip>

            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Your Most Engaging Channel</div>
                <div className="summary_value_txt">
                    {
                        mec?.channel == "instagram" &&
                        <img
                            className="mec_ico"
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                        />
                    }
                    {
                        mec?.channel == "youtube" &&
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/yt_main.svg')}
                        />
                    }
                    {
                        mec?.channel == "twitter" &&
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                        />
                    }
                    {
                        mec?.channel == "facebook" &&
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                        />
                    }
                    {
                        mec?.channel == "tiktok" &&
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                        />
                    }
                </div>

            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Your Top Hour of the Day</div>
                <div className="summary_value_txt">
                    <div className="value_txt">{chosenBrand?.topHour}</div>
                    {/* <ValueChange value="5%" change="rise" /> */}
                </div>

            </div>
        </div>
    )
}