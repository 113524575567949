import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { CustomSelect } from "../../../../../../_metronic/layout/components/CustomSelect";
import { getInfluencerEfficiency } from "../../../../../../setup/features/efficiency.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { influencerData } from "../../../../../../setup/types/cmpgnEfficiency";
import Skeleton from "react-loading-skeleton";
import * as functions from "../../../../../global/functions";

import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"
import { motion, AnimatePresence } from "framer-motion";

import {FiEye, FiEyeOff} from 'react-icons/fi'


// const tableData = [
//     {}
// ]

function addDefaultSrc(ev: any) {
    ev.target.src = toAbsoluteUrl('/media/cpg/na.png');
  }

const tableHeadings = [
    "Name", "CTR%", "Posts", "CPM", "CPC", "CPE", "CPV", "ROAS", "Fee/Budget"
]

const Header = () => {
    const filter1 = [
        { label: "Reach", value: "Reach" },
    ];

    return (
        <div className="d-flex justify-content-between align-content-center ">
            <h3 className="fs-3 NeueMontreal-bold text-dark ">Influencer Efficiencies To Date</h3>

            {/* <div className="d-flex justify-content-between align-content-center"
                style={{ gap: 10 }}
            >
                <CustomSelect className="custom-select" options={filter1} />
            </div> */}
        </div>
    )
}

const TableValueText = ({ text }: { text: string }) => (
    <p className="Helvetica-Neue-normal" style={{
        fontSize: "16px",
        fontWeight: 600,
        // fontFamily:'Helvetica-Neue',
        color: "#000",
        margin: 0
    }}>{text}</p>
)

// const TableEditText = ({ text, data }: { text?: string, data?:influencerData |null }) => (
//     <input 
//     type="number"
//     style={{
//         fontSize: "16px",
//         fontWeight: 600,
//         color: "#000",
//         border:"none",
//         textDecoration:"underline dahsed",
//         margin: 0,
//         maxWidth:"60px"
//     }}

//     value={data? data[text] : 0}
//     // onChange={(e) => }
//     />
// )

const TableEditPost = ({ text }: { text: string }) => (
    <input className="Helvetica-Neue-normal" style={{
        border:"none",
        textDecoration:"underline dahsed",
        margin: 0
    }}
    type="number" 
    value={text}
    />
)

const TableEditFee = ({ fee, budget}: { fee: number, budget:number }) => (
    <p className="Helvetica-Neue-bold" style={{
        fontSize: "16px",
        fontWeight: 600,
        color: "#000",
        margin: 0
    }}>{fee}</p>
)

// const TableEditButton = ({ fee, budget, name, edit }: { fee: number, budget:number, name:string, edit:string }) => (
//     <button onClick={() =>{console.log(name)}} className="d-flex align-items-center button " style={{background:"none", border:"none"}}>
//         <img src={toAbsoluteUrl("/media/cpg/pen-head-gray.png")} alt="pen edit"/>
//         <p className="mb-0 text__gray font_600">edit</p>
//     </button>
// )



export const InfluencerEfficiencies = ({ campaignId, isExternal}: { campaignId: string, isExternal?:boolean }) => {

    const [itemHover , setItemHover] = useState("")
    const [itemEdit , setItemEdit] = useState("")
    const [hideFee, setHideFee] = useState<boolean>(true)

    const [tableData, setTableData] = useState<influencerData | null>(null)

    const dispatch = useAppDispatch();

    const { loading, error, data } = useAppSelector((state) => state.influencerEfficiency);

    const range  = useAppSelector((state) => state.report.range).toString() ;

    const { campaignCurrency } = useSelector(
        (state: any) => ({
            campaignCurrency: state.campaign.campaignCurrency,
        })
    );

    let currency = campaignCurrency?.currency || "$"

    useEffect(() => {
        dispatch(getInfluencerEfficiency({
            campaign_id: campaignId, 
            is_external:isExternal,
            date_range: range.toString()
        }));
    }, [campaignId, dispatch, range]);

    return (
        <div className="mt-10 p-8 bg-white rounded" id="influencer_breakdown">
            <Header />

            {loading  && <Skeleton height={40} count={5}/>}

            {!loading && (error || data === null || data?.length === 0) ? (
                <ErrorBoundary error={error}/>
            ) : (
                <div className="influencertable">
                <table className="table table-borderless mt-6">
                <thead style={{ backgroundColor: "#F4F4FA", borderRadius: 100 }}>
                    <tr>
                        {tableHeadings.map((heading, index) => {
                            if(heading === "ROAS"){
                                return
                            }
                            if(heading === "Name"){
                                return(
                                    <th key={index} scope="col" className="font__700  Helvetica-Neue-bold text__16">{heading}</th>
                                )
                            }
                            if(heading === "Fee/Budget"){
                                return(
                                    <th key={index} scope="col" className="font__600 Helvetica-Neue-Medium  text__16">{heading} 
                                    <span className="ml-2" style={{marginLeft:"6px"}}>
                                        {
                                            hideFee ? <FiEye onClick={()=>setHideFee(!hideFee)} className="" style={{cursor:"pointer", fontSize:"20px"}}/> 
                                            : <FiEyeOff onClick={()=>setHideFee(!hideFee)} className="" style={{cursor:"pointer", fontSize:"20px"}}/>
                                        }
                                    </span>
                                    </th>
                                )
                            }
                            return (
                            <th key={index} scope="col" className="font__600 Helvetica-Neue-Medium text__16">{heading}</th>
                            )
                        })}
                    </tr>
                </thead>

                <tbody>
                    {data?.map((item:influencerData , index:number) => (
                        <tr key={index}>
                            <th scope="row">
                                <div className="d-flex align-items-center">
                                    <p style={{ fontSize: "16px", color: "#8C8C8C", fontWeight: 600, margin: "0 16px 0 0" }}>#{index + 1}</p>
                                    <a href={item.influencerLink} target="_blank" rel="noreferrer" style={{cursor:"pointer"}}>
                                    <img    
                                        onError={(ev) => addDefaultSrc(ev)}
                                        src={item.publisherLogo || toAbsoluteUrl("/media/cpg/reportImg1.png")}
                                        alt="hero-card"
                                        className="image-fluid d-block" 
                                        style={{ 
                                            width: '30px', 
                                            maxHeight: '30px', 
                                            resize: "both", 
                                            objectFit: "cover", 
                                            borderRadius: "8px",
                                            margin: "0 10px",
                                            marginRight:"12px",
                                        }}
                                    /> </a>

                                    <a href={item.influencerLink} target="_blank" rel="noreferrer">
                                        <TableValueText text={`${item?.name}`} />
                                    </a>
                                    
                                </div>
                            </th>
                            <td>
                            <TableValueText  text={`${item?.ctr?.toFixed(2)}%`}/>
                            </td>


                            <td className="d-flex align-items-center">
                            <p style={{ fontSize: "18px", color: "#000", fontWeight: 600, margin: "0 8px 0 0" }}>{item?.posts?.current || 0}</p>
                            {/* <span style={{ fontSize: "12px", color: "#000", opacity: 0.6, fontWeight: 600, margin: 0 }}>/{item?.posts?.required || 0} </span> */}
                        
                            </td>
                            <td>
                                <TableValueText text={`${currency}${functions.abvNum(item?.cpm?.toFixed(2) || 0) || 0}`}/>
                            </td>
                            <td>
                                <TableValueText text={`${currency}${functions.abvNum(item?.cpc?.toFixed(2) || 0) || 0}`} />
                            </td>
                            <td>
                                <TableValueText text={`${currency}${functions.abvNum(item?.cpe?.toFixed(2) || 0) || 0}`} />
                            </td>
                            <td>
                                <TableValueText text={`${currency}${functions.abvNum(item?.cpv?.toFixed(2) || 0) || 0}`} /> 
                            </td>
                            {/* <td>
                                
                                <TableValueText text={`${currency}${functions.abvNum(item?.roas?.toFixed(2) || 0) || '0'}`} />
                            </td> */}
                            <td
                                onMouseEnter={() => setItemHover(item?.name)}
                                onMouseLeave={() => setItemHover("")}
                                className="d-flex align-items-center"
                                style={{width:"100%"}}
                            >   
                                {!hideFee ? <TableValueText text={`$${functions.number_format(item?.budget?.fee) || 0}`}/> 
                                : <TableValueText text={`---`}/>}
                                <p style={{fontSize: "16px",
                                    fontWeight: 600,
                                    color: "#000",margin: "0 8px 0 8px"}}> / </p>
                                {!hideFee ? <TableValueText text={`${currency}${functions.number_format(item?.budget?.budget) || 0}`}/> 
                                : <TableValueText text={`---`}/>}
                                {/* <TableValueText text={`$${functions.number_format(item?.budget?.fee) || 0}`}/>
                                <p style={{fontSize: "16px",
                                    fontWeight: 600,
                                    color: "#000",margin: "0 8px 0 8px"}}> / </p>
                                <TableValueText text={`$${functions.number_format(item?.budget?.totalBudget) || 0}`}/>
                                
                                <AnimatePresence>
                                    {itemHover === item.name && (
                                        <motion.button 
                                        initial={{height:0, opacity: 0, x:'50px'}}
                                        animate={{height:"auto", opacity: 1, x:0 }}
                                        exit={{ height:0, opacity: 0 }}
                                        onClick={() =>{
                                            setTableData(item)
                                            setItemEdit(itemHover)
                                            
                                        }} 
                                        className="d-flex align-items-center button " 
                                        style={{background:"none", border:"none", margin:"10px 0 0 8px"}}>
                                            <img src={toAbsoluteUrl("/media/cpg/pen-head-gray.png")} alt="pen edit"/>
                                            <p className="mb-0 text__gray font_600">edit</p>
                                        </motion.button>
                                    )}
                                </AnimatePresence> */}
                            </td>

                            {/* <td>
                            <AnimatePresence>
                                {itemHover === item.name && (
                                    <motion.button 
                                    initial={{height:0, opacity: 0, x:"50px"}}
                                    animate={{height:"auto", opacity: 1, x:0 }}
                                    exit={{ height:0, opacity: 0 }}
                                    onClick={() =>{console.log(item.name)}} 
                                    className="d-flex align-items-center button " 
                                    style={{background:"none", border:"none", margin:"10px 0 0 8px"}}>
                                        <img src={toAbsoluteUrl("/media/cpg/pen-head-gray.png")} alt="pen edit"/>
                                        <p className="mb-0 text__gray font_600">edit</p>
                                    </motion.button>
                                )}
                                </AnimatePresence>
                            </td> */}

                            {/* <td 
                                
                            >
                                <button onClick={() =>{console.log(item.name)}} className="d-flex align-items-center button " style={{background:"none", border:"none"}}>
                                    <img src={toAbsoluteUrl("/media/cpg/pen-head-gray.png")} alt="pen edit"/>
                                    <p className="mb-0 text__gray font_600">edit</p>
                                </button>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            )}
                

        </div>
    )
}