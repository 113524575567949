import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import ReactApexChart from "react-apexcharts";
import distinctColors from 'distinct-colors'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import ValueChange from '../shared/ValueChange';
import { ApexOptions } from 'apexcharts';

import ExpandedChart from '../shared/ExpandChart'
import ExpandedPostDetails from '../shared/ExpandPost'

window.onclick = function (event: any) {
    var myBox = document.querySelector(".top_post_msg");

    if (event.target.contains(myBox) && event.target !== myBox) {

        document.querySelector(".top_post_msg")?.remove()
    }
}


const getMonthText = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const datediff = (start: any, end: any) => {
    return Math.round((end - start) / (1000 * 60 * 60 * 24)) + 1;
}

const FormateDateValue = (dateValue: any) => {
    let formatted: any = ""
    if (dateValue >= 10) {
        formatted = dateValue
    } else {
        formatted = `0${dateValue}`
    }

    return formatted;
}

const RoundUpNumber = ({ number, hasDecmals }: any) => {



    if (number) {
        let valToUse = number.toFixed(0);

        if (number >= 1000000) {

            valToUse = `${Number(number / 1000000).toFixed(0)}`;
            return (
                <div className="value_txt">{valToUse}<span className="value_unit">m</span></div>
            )
        }
        if (number >= 1000) {
            valToUse = `${Number(number / 1000).toFixed(0)}`;
            return (
                <div className="value_txt">{valToUse}<span className="value_unit">k</span></div>
            )
        }

        if (hasDecmals) {
            valToUse = `${Number(number).toFixed(2)}`

            return (
                <div className="value_txt">{valToUse}</div>
            )
        }


        return (
            <div className="value_txt">{valToUse}</div>
        )
    }

    return (
        <div className="value_txt"></div>
    )

}



const generateSeriesData = (brandInfo: any, filterType: any, timeSeries: any) => {

    let brandData = timeSeries.filter((eachData: any) => eachData?.companyId == brandInfo?.id)
    let dataFromFilter: any;


    if (filterType === "Followers") {
        let followerCount = 0;

        let data = brandData.map((chosenBrand: any) => followerCount + (chosenBrand?.tikTokFollowers || 0) + (chosenBrand?.instagramFollowedBy || 0) + (chosenBrand?.facebookPageLikes || 0) + (chosenBrand?.youTubeSubscribers || 0))
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }

        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate
            })
        };
    }

    if (filterType === "Engagement") {
        let countData = 0
        let data = brandData.map((chosenBrand: any) => countData + (chosenBrand?.twitterTweetEngagementTotal || 0) + (chosenBrand?.tikTokPostsEngagementTotal || 0) + (chosenBrand?.instagramPostsEngagementTotal || 0) + (chosenBrand?.facebookPostEngagementTotal || 0) + (chosenBrand?.youTubePostsEngagementTotal || 0))
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }
        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate

            })
        };
    }

    if (filterType === "EngagementPercent") {
        let countData = 0

        let data = brandData.map((chosenBrand: any) => {


            let totalEngagement = (chosenBrand?.instagramPostsEngagementTotal || 0) + (chosenBrand?.tikTokPostsEngagementTotal || 0) + (chosenBrand?.facebookPostEngagementTotal || 0) + (chosenBrand?.youTubePostsEngagementTotal || 0);

            let totalFollowers = (chosenBrand?.instagramFollowedBy || 0) + (chosenBrand?.tikTokFollowers || 0) + (chosenBrand?.facebookPageLikes || 0) + (chosenBrand?.youTubeSubscribers || 0);
            let totalPosts = (chosenBrand?.twitterTweets || 0) + (chosenBrand?.tikTokPosts || 0) + (chosenBrand?.youTubePosts || 0) + (chosenBrand?.instagramPosts || 0) + (chosenBrand?.facebookPosts || 0)


            let engRateValue: any = totalFollowers > 0 ? ((totalEngagement || 0) / (totalFollowers / 7)) * 100 : 0;
            engRateValue = totalPosts > 0 ? engRateValue / totalPosts : 0;
            engRateValue = engRateValue > 0 ? Number(engRateValue).toFixed(2) : 0
            return engRateValue;


        })
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }


        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate
            })
        };
    }
    if (filterType === "VideoViews") {
        let countData = 0
        let data = brandData.map((chosenBrand: any) => Math.floor(countData + (chosenBrand?.twitterVideoViews || 0) + (chosenBrand?.tikTokViews || 0) + (chosenBrand?.youTubePostViews || 0) + (chosenBrand?.reelViews || 0)))
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.video }))
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }
        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate
            })
        };
    }
    if (filterType === "PostsPerday") {
        let countData = 0
        let data = brandData.map((chosenBrand: any) => Math.floor((countData + (chosenBrand?.twitterAverageTweetsPerDay || 0) + (chosenBrand?.tikTokAvgPostsPerDay || 0) + (chosenBrand?.youTubeAveragePostsPerDay || 0) + (chosenBrand?.instagramAveragePostsPerDay || 0) + (chosenBrand?.facebookAveragePostsPerDay || 0)) / 7))
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }
        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate
            })
        };
    }

    return null
}

const insertAfter = (newNode: any, existingNode: any) => {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}


const EngagementTopContent = (props: any) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [postDetails, setPostDetails] = useState<any>();
    let { brandSocialPosts } = props;

    let allSocialPosts: any[] = brandSocialPosts?.socialPosts;

    let allSocialPostsToSort: any[] = [...allSocialPosts]

    allSocialPostsToSort = allSocialPostsToSort.map((eachItem: any) => {
        let calculatedRates = (eachItem?.followers && eachItem?.engagementTotal && eachItem?.followers > 0) ? eachItem?.engagementTotal / eachItem.followers : '-';

        return {
            ...eachItem,
            calculatedRates
        }
    })



    allSocialPostsToSort.sort((a: any, b: any) => (b.calculatedRates - a.calculatedRates))

    allSocialPostsToSort = allSocialPostsToSort.slice(0, 4)

    const handleShowModal = () => {
        setShowModal((prev) => !prev);

    }


    return (
        <div className="each_engagement_section top_content" id="slide2">
            {showModal && <ExpandedPostDetails postDetails={postDetails} showModal={showModal} handleShowModal={handleShowModal} />}
            <div className="small_listing_heading">Top Content (by Engagement %)</div>
            <div className="all_top_posts">
                {
                    allSocialPostsToSort.map((eachPost: any, index: any) => {
                        let socialIcon: any;

                        if (eachPost?.channel === "instagram") {
                            socialIcon = toAbsoluteUrl('/media/icons/socials/ig.svg')
                        }
                        if (eachPost?.channel === "tiktok") {
                            socialIcon = toAbsoluteUrl('/media/icons/socials/tiktok.svg')
                        }
                        if (eachPost?.channel === "youtube") {
                            socialIcon = toAbsoluteUrl('/media/icons/socials/yt_main.svg')
                        }
                        if (eachPost?.channel === "facebook") {
                            socialIcon = toAbsoluteUrl('/media/icons/socials/fb.svg')
                        }
                        if (eachPost?.channel === "twitter") {
                            socialIcon = toAbsoluteUrl('/media/icons/socials/tw.svg')
                        }
                        return (
                            <div className="each_top_post" key={index}>
                                {/* <Link to={{ pathname: eachPost?.postLink }} target="_blank"
                                    className="post_thumbnail"

                                >
                                    <img src={eachPost?.image} alt="" className="post_thumb_img" />
                                </Link> */}

                                <div
                                    className="post_thumbnail"
                                    onClick={() => {
                                        setPostDetails(eachPost)
                                        handleShowModal()
                                    }}
                                >
                                    <img src={eachPost?.image} alt="" className="post_thumb_img" />
                                </div>
                                <div className="post_meta">
                                    <div className="media-icon">
                                        <img
                                            alt='Logo'
                                            src={socialIcon}
                                        />
                                    </div>
                                    <div className="each_meta">
                                        <div className="meta_name">Type</div>
                                        <div className="meta_value">{eachPost?.type}</div>
                                    </div>
                                    <div className="each_meta">
                                        <div className="meta_name">ER</div>
                                        <div className="meta_value">
                                            {isNaN(eachPost?.calculatedRates) ? "-" : `${(eachPost?.calculatedRates * 100).toFixed(2)}%`}
                                        </div>
                                    </div>
                                    <div className="each_meta">
                                        <div className="meta_name">Likes</div>
                                        <div className="meta_value"><RoundUpNumber number={eachPost?.applause} /> </div>
                                    </div>
                                    <div className="each_meta">
                                        <div className="meta_name">Comments</div>
                                        <div className="meta_value"> <RoundUpNumber number={eachPost?.conversation} /></div>
                                    </div>
                                    <div className="each_meta">
                                        <div className="meta_name">Views</div>
                                        <div className="meta_value"> <RoundUpNumber number={eachPost?.estimatedImpressions} /> </div>
                                    </div>


                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

const EngagementChartOne = (props: any) => {
    const [chosenFilter, setChosenFilter] = useState<any>("Followers");
    const [newSeriesData, setSeriesData] = useState<any[]>([]);
    const [newOptionsData, setOptionsData] = useState<any>();

    let { timeSeries, brandsList, setChartType, setChartSeriesData,setChartPointDates, setChartOptions, handleShowModal } = props;


    useEffect(() => {

        let seriesData = brandsList.map((eachData: any) => generateSeriesData(eachData, chosenFilter, timeSeries?.social_post_series));




        let seriesOnly = seriesData.map((eachData: any) => eachData?.series)
        let labelOnly = seriesData.map((eachData: any) => eachData?.labels)[0]



        const series = [

            ...seriesOnly

        ];

        let palette: any[] = distinctColors({ count: series.length }) || [];
        let chartColors: any[] = palette.map((each: any) => `${each}`)

        const options: ApexOptions = {
            xaxis: {
                categories: [...labelOnly],
                labels: {
                    rotate: -55,
                    style: {
                        fontSize: "10px"
                    }
                },
                axisTicks: {
                    show: false
                },
            },

            yaxis: [{

                labels: {

                    formatter: function (val: any) {
                        let valToUse = chosenFilter === "EngagementPercent" ? val.toFixed(3) : val.toFixed(0);
                        if (val >= 1000) {
                            if (chosenFilter === "EngagementPercent") {

                                valToUse = `${Number(val).toFixed(2)}%`;
                            } else {
                                valToUse = `${Number(val / 1000).toFixed(2)}k`;
                                // valToUse = `${Math.round(Number(val.toFixed(0)) / 1000)}k`;
                            }
                        }
                        if (val >= 1000000) {
                            if (chosenFilter === "EngagementPercent") {
                                valToUse = `${Number(val).toFixed(2)}`;

                            } else {
                                valToUse = `${Number(val / 1000000).toFixed(2)}m`;
                                // valToUse = `${Math.round(Number(val.toFixed(0)) / 1000000)}m`;
                            }
                        }

                        if (val < 1000) {
                            if (chosenFilter === "EngagementPercent") {

                                valToUse = `${Number(val).toFixed(2)}%`;
                            }
                        }

                        return valToUse
                    }
                }
            }],

            tooltip: {

                custom: function ({ series, seriesIndex, dataPointIndex, w }) {

                    let allDataSets: any[] = w.globals.initialSeries;




                    let dataPointItems: any[] = [];
                    let trendLineVal, trendPercentVal: any = 0;

                  
                    for (let index = 0; index <= allDataSets.length - 1; index++) {
                        let brandDataValue = allDataSets[index].data[dataPointIndex];

                        let firstBrandDataValue = allDataSets[index].data[0];

                        let trendLine, trendPercent;
                        if (dataPointIndex >= 1) {
                            trendPercent = firstBrandDataValue > 0 ? Number(((brandDataValue - firstBrandDataValue) / firstBrandDataValue) * 100).toFixed(2) : 0
                            if (trendPercent !== 0) {
                                trendLine = Number(trendPercent) > 0 ? "rise" : "fall"
                            }
                        }

                        trendLineVal = trendLine
                        trendPercentVal = trendPercent


                        dataPointItems.push({
                            brandName: allDataSets[index].name,
                            brandDataValue,
                            weekPeekPost: allDataSets[index].otherData[dataPointIndex].moreData?.postLink,
                            color: chartColors[index],
                            trendLineVal,
                            trendPercentVal,
                            // label: w.globals.categoryLabels[dataPointIndex]
                            label: labelOnly[dataPointIndex]
                        })
                    }

                    dataPointItems.sort((a: any, b: any) => (b.brandDataValue - a.brandDataValue))
              

                    //  <span class={eachItem?.trendLineVal === "rise" ? "rise_trend" : "fall_trend"} >
                    return `<div class="chart_tooltip"> 
                        <div class="label_head">${dataPointItems[0]?.label}
                            <img
                                class="click_ico"
                                alt='trend'
                                src="${toAbsoluteUrl('/media/cpg/click.gif')}"
                            /> 
                        </div>
                        <div class="other_chart_info">
                                ${dataPointItems.map((eachItem, index) => {

                        return `
                                        <div class="eachbranchInfo" key={index}> 
                                            
                                                <span class="chart_brand_color" style="background-color: ${eachItem.color}"> </span>
                                                <span class="chart_brand_name">${eachItem?.brandName} </span>: 
                                                <span class="chart_brand_value">  ${eachItem?.brandDataValue.toLocaleString()} ${chosenFilter === "EngagementPercent" ? "%" : ""} </span> 
                                                ${(eachItem?.trendLineVal && eachItem?.trendPercentVal && Number(eachItem?.trendPercentVal) !== 0) ? `
                                                    
                                                    <span class="${eachItem?.trendLineVal === "rise" ? `rise_trend trend_move` : `fall_trend trend_move`}"  >
                                                    <img
                                                        alt='trend'
                                                        src="${eachItem?.trendLineVal === "rise" ? toAbsoluteUrl('/media/icons/socials/rise.svg') : toAbsoluteUrl('/media/icons/socials/fall.svg')}"
                                                    /> 
                                                    ${Math.abs(eachItem?.trendPercentVal)}%
                                                    </span>
                                                `: ""}
                                            
                                        </div>`

                    })
                        }
                        </div>
                            
                        </div>`

                }
            },

            chart: {
                toolbar: {
                    show: false,
                },
                events: {

                    markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
                        let allDataSets = chartContext?.series?.ctx?.w?.globals?.initialSeries;
                        let allInfo = chartContext?.series?.ctx?.w?.globals;
                        let chartElem = document.getElementById("chart1") as HTMLElement;

                        if (document.querySelector(".top_post_msg")) {
                            document.querySelector(".top_post_msg")?.remove()
                        }

                        let dataPointItems: any[] = [];
                        for (let index = 0; index <= allDataSets.length - 1; index++) {
                            dataPointItems.push({
                                brandName: allDataSets[index].name,
                                date: allInfo?.categoryLabels[dataPointIndex],
                                engagementTotal: allDataSets[index].otherData[dataPointIndex].moreData?.engagementTotal,
                                weekPeekPost: allDataSets[index].otherData[dataPointIndex].moreData?.postLink,
                            })
                        }
                        dataPointItems.sort((a: any, b: any) => (b.engagementTotal - a.engagementTotal))


                        if (dataPointItems[0]?.weekPeekPost) {
                            let elem: any = document.createElement('div');

                            elem.innerHTML = `<div class="chart_tooltip top_post_msg" style="left: ${event?.layerX}px; top: ${event?.layerY}px"> 
                                            <div class="date_txt">${dataPointItems[0]?.date}</div>
                                            <div class="post_txt">
                                            ${chosenFilter === "VideoViews"?"Highest Video This Week" :"Most Engaging Post This Week"}
                                            <div> <a class="linkto" href="${dataPointItems[0]?.weekPeekPost}" target="_blank">${chosenFilter === "VideoViews"?"Link to Video" :"Link to Post"}</a> </div>
                                    </div>
                                    
                                </div>`;

                            chartElem.appendChild(elem)

                        }else{
                        
                            let elem: any = document.createElement('div');
    
                            elem.innerHTML = `<div class="chart_tooltip top_post_msg"> 
                                            <div class="date_txt">${dataPointItems[0]?.date}</div>
                                            <div class="post_txt">
                                            ${chosenFilter === "VideoViews"?" No Highest Video This Week" :"No Most Engaging Post This Week"}
                                                
                                           
                                    </div>
                                    
                                </div>`;
    
                            chartElem.appendChild(elem)
    
                        
                    }

                    }

                }

            },
            legend: {
                markers: {
                    width: 8,
                    height: 8,
                }
            },
            stroke: {
                width: 2.5
            },
            colors: [...chartColors],
        };
        setSeriesData(series)

        setOptionsData(options)
        setChartPointDates(labelOnly)

    }, [chosenFilter]);




    const handleSetModalData = () => {
        setChartSeriesData(newSeriesData)
        setChartOptions(newOptionsData)
        setChartType(chosenFilter)
        handleShowModal()
    }






    return (
        <div className="each_engagement_section" id="slide3">
            <div className="chart_filter">
                <select name="" id=""
                    value={chosenFilter}
                    onChange={(e) => {
                        setChosenFilter(e.target.value)
                        
                        setChartType(e.target.value)
                    }}
                >
                    <option value="Followers">Followers</option>
                    <option value="Engagement">Engagement</option>
                    <option value="EngagementPercent">Engagement %</option>
                    <option value="VideoViews">Video Views</option>
                    <option value="PostsPerday">Posts Per day</option>
                </select>
                <div className="expand_cta" onClick={() => handleSetModalData()}>
                    <img src={toAbsoluteUrl('/media/cpg/full-screen.png')} alt="" />
                </div>
            </div>
            {newSeriesData.length >= 1 && <ReactApexChart id="chart1" options={newOptionsData} series={newSeriesData} type="line" />}

        </div>
    )
}

const EngagementChartTwo = (props: any) => {
    const [chosenFilter, setChosenFilter] = useState<any>("Engagement");
    const [newSeriesData, setSeriesData] = useState<any[]>([]);
    const [newOptionsData, setOptionsData] = useState<any>();
    let { timeSeries, brandsList, setChartType, setChartSeriesData, setChartPointDates, setChartOptions, handleShowModal } = props;


    useEffect(() => {
        let seriesData = brandsList.map((eachData: any) => generateSeriesData(eachData, chosenFilter, timeSeries?.social_post_series));



        let seriesOnly = seriesData.map((eachData: any) => eachData?.series)
        let labelOnly = seriesData.map((eachData: any) => eachData?.labels)[0]


        const series = [

            ...seriesOnly

        ];



        let palette: any[] = distinctColors({ count: series.length }) || [];
        let chartColors: any[] = palette.map((each: any) => `${each}`)
        const options: ApexOptions = {
            xaxis: {
                categories: [...labelOnly],
                labels: {
                    rotate: -55,
                    style: {
                        fontSize: "10px"
                    }
                },
                axisTicks: {
                    show: false
                },
            },
            yaxis: [{

                labels: {

                    formatter: function (val: any) {
                        let valToUse = chosenFilter === "EngagementPercent" ? val.toFixed(3) : val.toFixed(0);
                        if (val >= 1000) {
                            if (chosenFilter === "EngagementPercent") {

                                valToUse = `${Number(val).toFixed(2)}%`;
                            } else {
                                valToUse = `${Number(val / 1000).toFixed(2)}k`;
                                // valToUse = `${Math.round(Number(val.toFixed(0)) / 1000)}k`;
                            }
                        }
                        if (val >= 1000000) {
                            if (chosenFilter === "EngagementPercent") {
                                valToUse = `${Number(val).toFixed(2)}`;

                            } else {
                                valToUse = `${Number(val / 1000000).toFixed(2)}m`;
                                // valToUse = `${Math.round(Number(val.toFixed(0)) / 1000000)}m`;
                            }
                        }

                        if (val < 1000) {
                            if (chosenFilter === "EngagementPercent") {

                                valToUse = `${Number(val).toFixed(2)}%`;
                            }
                        }

                        return valToUse
                    }

                }
            }],
            tooltip: {

                custom: function ({ series, seriesIndex, dataPointIndex, w }) {

                    let allDataSets: any[] = w.globals.initialSeries;




                    let dataPointItems: any[] = [];
                    let trendLineVal, trendPercentVal: any = 0;

                    for (let index = 0; index <= allDataSets.length - 1; index++) {
                        let brandDataValue = allDataSets[index].data[dataPointIndex];

                        let firstBrandDataValue = allDataSets[index].data[0];

                        let trendLine, trendPercent;
                        if (dataPointIndex >= 1) {
                            trendPercent = firstBrandDataValue > 0 ? Number(((brandDataValue - firstBrandDataValue) / firstBrandDataValue) * 100).toFixed(2) : 0
                            if (trendPercent !== 0) {
                                trendLine = Number(trendPercent) > 0 ? "rise" : "fall"
                            }
                        }

                        trendLineVal = trendLine
                        trendPercentVal = trendPercent


                        dataPointItems.push({
                            brandName: allDataSets[index].name,
                            brandDataValue,
                            weekPeekPost: allDataSets[index].otherData[dataPointIndex].moreData?.postLink,
                            color: chartColors[index],
                            trendLineVal,
                            trendPercentVal,
                            // label: w.globals.categoryLabels[dataPointIndex]
                            label: labelOnly[dataPointIndex]
                        })
                    }

                    dataPointItems.sort((a: any, b: any) => (b.brandDataValue - a.brandDataValue))

                    //  <span class={eachItem?.trendLineVal === "rise" ? "rise_trend" : "fall_trend"} >
                    return `<div class="chart_tooltip"> 
                        <div class="label_head">${dataPointItems[0]?.label}
                            <img
                                class="click_ico"
                                alt='trend'
                                src="${toAbsoluteUrl('/media/cpg/click.gif')}"
                            /> 
                        </div>
                        <div class="other_chart_info">
                                ${dataPointItems.map((eachItem, index) => {

                        return `
                                        <div class="eachbranchInfo" key={index}> 
                                            
                                                <span class="chart_brand_color" style="background-color: ${eachItem.color}"> </span>
                                                <span class="chart_brand_name">${eachItem?.brandName} </span>: 
                                                <span class="chart_brand_value">  ${eachItem?.brandDataValue.toLocaleString()} ${chosenFilter === "EngagementPercent" ? "%" : ""} </span> 
                                                ${(eachItem?.trendLineVal && eachItem?.trendPercentVal && Number(eachItem?.trendPercentVal) !== 0) ? `
                                                    
                                                    <span class="${eachItem?.trendLineVal === "rise" ? `rise_trend trend_move` : `fall_trend trend_move`}"  >
                                                    <img
                                                        alt='trend'
                                                        src="${eachItem?.trendLineVal === "rise" ? toAbsoluteUrl('/media/icons/socials/rise.svg') : toAbsoluteUrl('/media/icons/socials/fall.svg')}"
                                                    /> 
                                                    ${Math.abs(eachItem?.trendPercentVal)}%
                                                    </span>
                                                `: ""}
                                            
                                        </div>`

                    })
                        }
                        </div>
                            
                        </div>`

                }
            },


            chart: {
                toolbar: {
                    show: false,
                },
                events: {

                    markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
                        let allDataSets = chartContext?.series?.ctx?.w?.globals?.initialSeries;
                        let allInfo = chartContext?.series?.ctx?.w?.globals;
                        let chartElem = document.getElementById("chart2") as HTMLElement;

                        if (document.querySelector(".top_post_msg")) {
                            document.querySelector(".top_post_msg")?.remove()
                        }

                        let dataPointItems: any[] = [];
                        for (let index = 0; index <= allDataSets.length - 1; index++) {
                            dataPointItems.push({
                                brandName: allDataSets[index].name,
                                date: allInfo?.categoryLabels[dataPointIndex],
                                engagementTotal: allDataSets[index].otherData[dataPointIndex].moreData?.engagementTotal,
                                weekPeekPost: allDataSets[index].otherData[dataPointIndex].moreData?.postLink,
                            })
                        }
                        dataPointItems.sort((a: any, b: any) => (b.engagementTotal - a.engagementTotal))


                        if (dataPointItems[0]?.weekPeekPost) {
                            let elem: any = document.createElement('div');

                            elem.innerHTML = `<div class="chart_tooltip top_post_msg" style="left: ${event?.layerX}px; top: ${event?.layerY}px"> 
                                            <div class="date_txt">${dataPointItems[0]?.date}</div>
                                        <div class="post_txt">
                                        ${chosenFilter === "VideoViews"?"Highest Video This Week" :"Most Engaging Post This Week"}
                                        <div> <a class="linkto" href="${dataPointItems[0]?.weekPeekPost}" target="_blank">${chosenFilter === "VideoViews"?"Link to Video" :"Link to Post"}</a> </div>
                                    </div>
                                    
                                </div>`;

                            chartElem.appendChild(elem)
                        }else{
                        
                            let elem: any = document.createElement('div');
    
                            elem.innerHTML = `<div class="chart_tooltip top_post_msg"> 
                                            <div class="date_txt">${dataPointItems[0]?.date}</div>
                                            <div class="post_txt">
                                            ${chosenFilter === "VideoViews"?" No Highest Video This Week" :"No Most Engaging Post This Week"}
                                                
                                           
                                    </div>
                                    
                                </div>`;
    
                            chartElem.appendChild(elem)
    
                        
                    }


                    }

                }

            },
            legend: {
                markers: {
                    width: 8,
                    height: 8,
                }
            },
            stroke: {
                width: 2.5
            },
            colors: [...chartColors],
        };
        setSeriesData(series)

        setOptionsData(options)
        setChartPointDates(labelOnly)


    }, [chosenFilter]);


    const handleSetModalData = () => {
        handleShowModal()
        setChartType(chosenFilter)
        setChartSeriesData(newSeriesData)
        setChartOptions(newOptionsData)
    }







    return (
        <div className="each_engagement_section" id="slide4">
            <div className="chart_filter">
                <select name="" id=""
                    value={chosenFilter}
                    onChange={(e) => {
                        setChosenFilter(e.target.value)
                        setChartType(e.target.value)
                    }}
                >
                    <option value="Followers">Followers</option>
                    <option value="Engagement">Engagement</option>
                    <option value="EngagementPercent">Engagement %</option>
                    <option value="VideoViews">Video Views</option>
                    <option value="PostsPerday">Posts Per day</option>
                </select>
                <div className="expand_cta" onClick={() => handleSetModalData()}>
                    <img src={toAbsoluteUrl('/media/cpg/full-screen.png')} alt="" />
                </div>
            </div>
            {newSeriesData.length >= 1 && <ReactApexChart id="chart2" options={newOptionsData} series={newSeriesData} type="line" />}

        </div>
    )
}




export default function SummaryEngagements(props: any) {
    let { timeSeries, brandsList, brandSocialPosts } = props;
    const [showModal, setShowModal] = useState<boolean>(false);
    const [chartType, setChartType] = useState<boolean>(false);
    const [chartSeriesData, setChartSeriesData] = useState<any>();
    const [chartOptions, setChartOptions] = useState<any>();
    const [chartPointDates, setChartPointDates] = useState<any>();
    const [shouldExpand, setShouldExpand] = useState<boolean>(false);

    // useEffect(() => {
    //     if (!showModal) {
    //         setShouldExpand((prev) => !prev);
    //     }
    // }, [showModal]);

    const handleShowModal = () => {
        setShowModal((prev) => !prev);

    }
    return (
        <>
            {timeSeries &&

                <div className="top_engagements_wrap">
                    {(chartType && showModal && chartOptions && chartSeriesData && chartPointDates) && <ExpandedChart chartPointDates={chartPointDates} chartOptions={chartOptions} chartSeriesData={chartSeriesData} chartType={chartType} showModal={showModal} handleShowModal={handleShowModal} />}
                    <EngagementTopContent brandSocialPosts={brandSocialPosts} />
                    <EngagementChartOne setChartPointDates={setChartPointDates} shouldExpand={shouldExpand} setShouldExpand={setShouldExpand} handleShowModal={handleShowModal} setChartOptions={setChartOptions} setChartType={setChartType} setChartSeriesData={setChartSeriesData} brandsList={brandsList} timeSeries={timeSeries} />
                    <EngagementChartTwo setChartPointDates={setChartPointDates} shouldExpand={shouldExpand} setShouldExpand={setShouldExpand} handleShowModal={handleShowModal} setChartOptions={setChartOptions} setChartType={setChartType} setChartSeriesData={setChartSeriesData} brandsList={brandsList} timeSeries={timeSeries} />

                </div>
            }
        </>
    )
}