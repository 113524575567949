import React from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'

import AnalysisHeadStats from './analysis/HeadStats';
import FormatEngagement from './analysis/FormatEngagementV2'
import TopSocialPostAnalysis from './analysis/TopSocialPosts'



export default function SocialListingContentAnalysis(props: any) {
    let { brandsSummaryInfo,dateDiff, chosenBrand, timeSeries, brandsList, brandSocialPosts, isPdfPage } = props;


    let brandSelected = brandsSummaryInfo[0].metrics.filter((eachItem: any) => eachItem.companyId == chosenBrand?.focusCompanyId)[0]
    let seriesForBrandOnly = timeSeries?.social_post_series.filter((eachItem: any) => eachItem.companyId == chosenBrand?.focusCompanyId);

    let brandDataToCompare = brandsSummaryInfo?.compareData[0]?.metrics.filter((eachItem: any) => eachItem.companyId == chosenBrand?.focusCompanyId)[0]
    
    return (
        <>
            {!isPdfPage &&
                <div className="brand_glance each_section_wrap" id="content-analysis">
                    <div className="section_title">Content Analysis</div>

                    <AnalysisHeadStats dataToCompare={brandDataToCompare} chosenBrand={brandSelected} timeSeries={seriesForBrandOnly} />
                    <FormatEngagement landscapeInfo={chosenBrand} dateDiff={dateDiff} chosenBrand={brandSelected} brandSocialPostsInfo={brandSocialPosts} brandsSummaryInfo={brandsSummaryInfo} />
                    <TopSocialPostAnalysis chosenBrand={brandSelected} brandsSummaryInfo={brandsSummaryInfo} brandSocialPosts={brandSocialPosts} />


                </div>
            }
            {isPdfPage &&
                <>
                    <div className="pdf-wrap">

                        <div className="brand_glance each_section_wrap " id="content-analysis">
                            <div className="section_title">Content Analysis</div>

                            <AnalysisHeadStats dataToCompare={brandDataToCompare} chosenBrand={brandSelected} timeSeries={seriesForBrandOnly} />
                            <FormatEngagement landscapeInfo={chosenBrand} chosenBrand={brandSelected} brandSocialPostsInfo={brandSocialPosts} brandsSummaryInfo={brandsSummaryInfo} />

                        </div>
                    </div>
                    <div className="pdf-wrap">
                        <div className="brand_glance each_section_wrap" >
                            <TopSocialPostAnalysis chosenBrand={brandSelected} brandsSummaryInfo={brandsSummaryInfo} brandSocialPosts={brandSocialPosts} />
                        </div>

                    </div>
                </>

            }
        </>
    )
}