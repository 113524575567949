import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import * as functions from "../../../global/functions";
import { Link } from 'react-router-dom'


interface CreatorProps {
  avatar?: string
  online?: boolean
  followers: string
  impressions: string
  er: string
  clicks: string
  handle: string
  aqs: string
  name: string
  currency:string
  PublisherId: string
}


export default function CreatorNew(props: CreatorProps | any) {
  const { name, handle, er, aqs, Posts,currency, LastPost, fee, Status, Currency, Paid, PayoutType, avatar, PublisherId } = props;

  function addDefaultSrc(ev: any) {
    ev.target.src = toAbsoluteUrl('/media/cpg/na.png');
  }

  const limitStr = (str: string, length: number) => {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('') + '...'
    }
    return newStr;
  }

  return (
    // <a
    //   href={`/app/influencers/${PublisherId}`}
    // >
    // <Link to={`/app/influencers/${PublisherId}`} style={{ color: '#181C32' }}>
    <div className="d-flex align-items-start mb-10">
      <img onError={(ev) => addDefaultSrc(ev)} src={avatar ? avatar : toAbsoluteUrl('/media/cpg/na.png')}
        style={{ width: '120px', height: '240px', objectFit: 'cover', marginRight: '12px', borderRadius: '16px' }}
      />
      <div>
        <div className="mb-5">
          <span className="text-gray-600" style={{ fontSize: '12px' }}>@{limitStr(handle, 20)}</span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-5">
          <div className="me-3">
            <span className="d-block fw-lighter text-mute mb-1" style={{ fontSize: '12px' }}>Status</span>
            <span className="d-block fw-bolder fs-6 text-black">{Status}</span>
          </div>
          <div className="me-3">
            <span className="d-block fw-lighter text-mute mb-1" style={{ fontSize: '12px' }}>Currency</span>
            <span className="d-block fw-bolder fs-5 text-black">{Currency}</span>
          </div>
          <div>
            <span className="d-block fw-lighter text-mute mb-1" style={{ fontSize: '12px' }}>Paid</span>
            <span className="d-block fw-bolder fs-6 text-black">{Paid && Paid === 1 ? 'True' : 'False'}</span>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-5">
          <div className="me-3">
            <span className="d-block fw-lighter text-mute mb-1" style={{ fontSize: '12px' }}>Fee</span>
            <span className="d-block fw-bolder fs-5 text-black">{`${currency}${functions.number_format(fee)}`}</span>
          </div>
          <div className="me-3">
            <span className="d-block fw-lighter text-mute mb-1" style={{ fontSize: '12px' }}>PayoutType</span>
            <span className="d-block fw-bolder fs-5 text-black">{PayoutType}</span>
          </div>
          <div>
            <span className="d-block fw-lighter text-mute mb-1" style={{ fontSize: '12px' }}>Posts</span>
            <span className="d-block fw-bolder fs-5 text-black">{Posts}</span>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-start">
          <div className="me-3">
            <span className="d-block fw-lighter text-mute mb-1" style={{ fontSize: '12px' }}>Last Post Date</span>
            <span className="d-block fw-bolder fs-5 text-black">{LastPost || 'n/A'}</span>
          </div>
        </div>
      </div>
    </div>
    // </Link> 
    // </a>
  )
}
