import React from 'react'
import { ApexOptions } from 'apexcharts';
import ReactApexChart from "react-apexcharts";

import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Modal from "react-bootstrap/Modal";

export default function ExpandedChart(props: any) {
    let { showModal, handleShowModal, chartType, chartSeriesData, chartOptions, chartPointDates } = props;
    let { chart, ...otherOptions } = chartOptions;

    
    const options: ApexOptions = {
        ...otherOptions,
        chart: {
            toolbar: {
                show: false,
            },
            events: {

                markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
                    let allDataSets = chartContext?.series?.ctx?.w?.globals?.initialSeries;
                    let allInfo = chartContext?.series?.ctx?.w?.globals;
                    let chartElem = document.getElementById("chart11") as HTMLElement;

                                        
                    if (document.querySelector(".top_post_msg")) {
                        document.querySelector(".top_post_msg")?.remove()
                    }

                    let dataPointItems: any[] = [];
                    for (let index = 0; index <= allDataSets.length - 1; index++) {
                        dataPointItems.push({
                            brandName: allDataSets[index].name,
                            date: allInfo?.categoryLabels[dataPointIndex] ||chartPointDates[dataPointIndex],
                            engagementTotal: allDataSets[index].otherData[dataPointIndex].moreData?.engagementTotal,
                            weekPeekPost: allDataSets[index].otherData[dataPointIndex].moreData?.postLink,
                        })
                    }
                    dataPointItems.sort((a: any, b: any) => (b.engagementTotal - a.engagementTotal))

                    
                    if (dataPointItems[0]?.weekPeekPost) {
                        let elem: any = document.createElement('div');

                        elem.innerHTML = `<div class="chart_tooltip top_post_msg" style="left: ${event?.layerX}px; top: ${event?.layerY}px"> 
                                        <div class="date_txt">${dataPointItems[0]?.date}</div>
                                        <div class="post_txt">
                                        ${chartType === "VideoViews"?"Highest Video This Week" :"Most Engaging Post This Week"}
                                            
                                        <div> <a class="linkto" href="${dataPointItems[0]?.weekPeekPost}" target="_blank">${chartType === "VideoViews"?"Link to Video" :"Link to Post"}</a> </div>
                                </div>
                                
                            </div>`;

                        chartElem.appendChild(elem)

                    }else{
                        
                            let elem: any = document.createElement('div');
    
                            elem.innerHTML = `<div class="chart_tooltip top_post_msg"> 
                                            <div class="date_txt">${dataPointItems[0]?.date}</div>
                                            <div class="post_txt">
                                            ${chartType === "VideoViews"?" No Highest Video This Week" :"No Most Engaging Post This Week"}
                                                
                                           
                                    </div>
                                    
                                </div>`;
    
                            chartElem.appendChild(elem)
    
                        
                    }


                }

            }

        },
    }


    return (

        <Modal
            show={showModal}
            onHide={handleShowModal}
            dialogClassName="chart_modal"

        >
            <ModalHeader>{chartType} Chart </ModalHeader>
            <ModalBody>
                {chartSeriesData.length >= 1 && <ReactApexChart id="chart11" options={options} series={chartSeriesData} type="line" />}
            </ModalBody>
        </Modal>


    )
}