import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { getActionableInsightsRes } from "../../../../../../setup/features/report.slice";
import { useEffect} from "react";

import Skeleton from "react-loading-skeleton";
import InsightsComp from "../components/ActionableComp/InsightsComp";


export const Insights = ({ campaignId, isExternal }: { campaignId: string, isExternal?: boolean }) => {
    const dispatch = useAppDispatch()

    const { loading, error, data } = useAppSelector((state) => state.report.ActionableInsightsRes);
    const range  = useAppSelector((state) => state.report.range).toString() ;
    

    useEffect(() => {
        
        dispatch(getActionableInsightsRes({
            campaign_id: campaignId, 
            is_external:isExternal,
            date_range:range.toString()
        }));
        
    }, [campaignId, range, dispatch , isExternal]);

    

    return (
        <div className="mt-2 py-2" id="actionable_insights_wrap">
            
            {loading && <Skeleton height={90} count={1} />}

            {!loading && 
                (
                    <div className="bg-white p-4">

                        <InsightsComp  type="Insights" data={data || null} isExternal={isExternal} 
                        campaignId={campaignId} range={range} error={error}/>

                    </div>
                )
                
            }
        </div>
    )
}

