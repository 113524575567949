
import {topPerformingData} from '../../../../../../../setup/types/topPerformingTypes'
import {abvNum} from '../../../../../../global/functions'



interface filterObj{
    first:string,
    second:string
}

interface creatorsContentProps{
    name:string,
    image:string,
    influencerLink:string
}

// "/media/cpg/reportImg1.png"
export const topCreators = (data:topPerformingData|null, filterValues:filterObj) =>{
    let creatorsContent:creatorsContentProps[] = [];

    let sortKey:string = filterValues.first

    
    let filteredData = data?.creators || []

    

    let creatorSort = ""

    if(sortKey === "EngangementRate"){
        creatorSort = "engangementRate"
    }
    else if(sortKey === "ReachRate"){
        creatorSort = "reachRate"
    }
    else if(sortKey === "LinkClicks"){
        creatorSort = "clicks"
    }
    else{
        creatorSort = sortKey.toLowerCase()
    }

    
    let creators = data ? [...data?.creators].sort((a, b) =>  (b[creatorSort] as number) - (a[creatorSort] as number)) : [];


    creators?.map((item, index)=>{

        creatorsContent.push({
            name: item?.name || "",
            image: item?.logoURL || "",
            influencerLink: item?.influencerLink || ""
        })

        return index
    })

    return creatorsContent;
}
