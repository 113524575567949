import { useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers"
import SVG from 'react-inlinesvg';
import { useAppDispatch, useAppSelector } from "../../../../../../../setup/redux/hooks";
import {uploadStaticImages} from "../../../../../../../setup/features/report.slice"
import { getTopPerforming } from "../../../../../../../setup/features/topPerforming.slice";
import axios from "axios";

const comments = [
    {
        name: "Kristin Watson",
        avatar: toAbsoluteUrl("/media/cpg/reportImg4.png"),
        dateTime: "11:00 PM, 12 Dec 2022",
        comment: "This is my recommendation for this project. Let’s pull back funding since we have hit our target"
    },
    {
        name: "Kristin Watson",
        avatar: toAbsoluteUrl("/media/cpg/reportImg4.png"),
        dateTime: "11:00 PM, 12 Dec 2022",
        comment: "This is my recommendation for this project. Let’s pull back funding since we have hit our target"
    },
    {
        name: "Kristin Watson",
        avatar: toAbsoluteUrl("/media/cpg/reportImg4.png"),
        dateTime: "11:00 PM, 12 Dec 2022",
        comment: "This is my recommendation for this project. Let’s pull back funding since we have hit our target"
    },
]

interface Props{
    campaignId: string;
    data:any,
    isExternal:boolean | undefined;
    
}

function addDefaultSrc(ev: any, image?:string) {
    ev.target.src = toAbsoluteUrl(image || '/media/cpg/reportImg4.png');
}

const API_URL = process.env.REACT_APP_API_URL;

export default function TopComments({campaignId, data, isExternal}:Props) {
    const [hoveredImageIndex, setHoveredImageIndex] = useState<number>(-1); 
    const [commentImages, setCommentImages] = useState<any>(null)

    const containerRef = useRef<HTMLDivElement>(null); 
    const uploadRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch()
    const dateRange = useAppSelector((state) => state.report.range);

    const handleImageHover = (index: number) => {
        setHoveredImageIndex(index);
    };

    const handleImageOut = () => {
        if(hoveredImageIndex){
            setHoveredImageIndex(-1);
        }
    }

    useEffect(() => {
        // Attach a click event listener to the document
        document.addEventListener('click', handleClickOutside);
    
        // Remove the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    function handleClickOutside(event: MouseEvent) {
        // Check if the click target is inside the div container
        if (containerRef.current && !containerRef.current.contains(event.target as Node) && uploadRef.current && !uploadRef.current.contains(event.target as Node)) {
          // Clicked outside the container, update the state value
          handleImageOut()
        }
      }

    useEffect(()=>{
        axios.get(`${API_URL}api/new-reports/modal/top-comments?campaign_id=${campaignId}&influencer_id=${data.publisherId?.toString()}`)
            .then(res => {
                console.log(res.data)
                setCommentImages(res.data.data)
            })
    },[campaignId, data])

    const handleImageUpload = (event: any) => {
        const image: File = event.target.files[0];
        const formData = new FormData();

        if(data && data.publisherId){
            formData.append("campaign_id", `${campaignId ? campaignId?.toString() : ""}`);
            formData.append("file", image);
            formData.append("influencer_id", data.publisherId?.toString())
            formData.append("type", "comment")

            dispatch(uploadStaticImages(formData));
            dispatch(getTopPerforming({
                campaign_id: campaignId ? campaignId.toString() : "000000", 
                is_external:isExternal,
                date_range: dateRange ? dateRange.toString() : "7" 
            }));
        }

    }

    return (
        <div style={{ width: "600px", gap: "10px" }} className="d-flex flex-column" >
            <h4
                className="text__24 NeueMontreal-bold text-dark text-align-center p-0"
                style={{ marginBottom: "16px"}}
            >Top Comments</h4>
            
            <div 
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px"
                }}
            >
                {comments.map((comment, index:number) => {
                    if(commentImages && commentImages.length !== 0 && commentImages[index]){
                        return (
                            <div style={{position:"relative"}} ref={containerRef} 
                            onMouseOver={() => handleImageHover && handleImageHover(index)}>
                                <img 
                                onError={(ev) => {
                                    addDefaultSrc(ev)           
                                }}
                                src={commentImages[Object.keys(commentImages)[index]].url} 
                                alt="comment" style={{
                                    width: "100%",
                                    maxHeight: "150px", 
                                    objectFit: 'contain',
                                }}/>
                                {hoveredImageIndex === index && 
                                (
                                    <div ref={uploadRef} >
        
                                        <label 
                                            htmlFor="modal-post-dynamic"
                                            style={{ 
                                                border: "none", 
                                                background: "#F4F4FA", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                gap: "10px",
                                                marginBottom:"5px",
                                                padding: "10px",
                                                position:"absolute",
                                                top:"10px",
                                                right:"10px",
                                                borderRadius: "8px",
                                                zIndex: 2,
                                                cursor: "pointer"
                                            }}
                                        >
                                            <input name="" type="file" id="modal-post-dynamic" 
                                            accept=".png,.jpg,.jpeg" hidden style={{visibility:'hidden', width:"1px", height:"1px"}} 
                                            onChange={handleImageUpload} />
                                            <SVG 
                                                src={toAbsoluteUrl('/media/icons/duotune/communication/upload.svg')} 
                                                className='svg-icon-2' 
                                                style={{zIndex: 5, cursor: "pointer"}}
                                            />
                                        </label>
                                    </div>
                                )
                            }
                            </div>
                        )
                    }  else{ 
                        return (
                        <div 
                                className="d-flex flex-column justify-content-between align-items-start" 
                                key={index}
                                ref={containerRef}
                                style={{
                                    backgroundColor: "#F4F4FA",
                                    borderRadius: "24px",
                                    padding: "16px"
                                }}
                                onMouseOver={() => handleImageHover && handleImageHover(index)}
                            >
                                <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                                    <div className="d-flex justify-content-between align-items-center" style={{ gap: "12px", marginBottom: "16px" }}>
                                        {/* <img 
                                            src={comment.avatar}
                                            style={{
                                                width: "48px",
                                                height: "48px",
                                                borderRadius: "24px"
                                            }}
                                            alt="comment avatar"
                                        />
        
                                        <div>
                                            <p className="text__20 NeueMontreal-normal text-dark text-align-center p-0 m-0">
                                                {comment.name}
                                            </p>
        
                                            <p
                                                style={{ color: "#3771C8", opacity: 0.6 }}
                                                className="text__16 NeueMontreal-normal text-align-center p-0 m-0"
                                            >
                                                {comment.dateTime}
                                            </p>
                                        </div> */}
                                        <label htmlFor="modal-post-dynamic" 
                                        className="text__20 NeueMontreal-normal text-align-center p-0 m-0" 
                                        style={{ color:"#8c8c8c"}}>
                                                Add Latest Comment
                                        </label>
                                    </div>
        
                                  
        
                                {hoveredImageIndex === index && 
                                (
                                    <div ref={uploadRef}>
        
                                        <label 
                                            htmlFor="modal-post-dynamic"
                                            style={{ 
                                                border: "none", 
                                                background: "#F4F4FA", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                gap: "10px",
                                                marginBottom:"5px",
                                                padding: "10px",
                                                borderRadius: "8px",
                                                zIndex: 2,
                                                cursor: "pointer"
                                            }}
                                        >
                                            <input name="modal-post-dynamic" type="file" id="modal-post-dynamic" 
                                            accept=".png,.jpg,.jpeg" hidden style={{visibility:'hidden', width:"1px", height:"1px"}} 
                                            onChange={handleImageUpload} />
                                            <SVG 
                                                src={toAbsoluteUrl('/media/icons/duotune/communication/upload.svg')} 
                                                className='svg-icon-2' 
                                                style={{zIndex: 5, cursor: "pointer"}}
                                            />
                                        </label>
                                    </div>
                                )
                            }
                                </div> 
        
                                <p className="NeueMontreal-normal text-align-center p-0 m-0"
                                    style={{ color: "#4A4A4A", fontSize: "16px", fontWeight: 400, visibility:"hidden" }}
                                >
                                    {comment.comment}
                                </p>
                        </div>
                        )}
                })}
            </div>
        </div>
    )
}