import { useEffect, useState, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { getSummary, getKeyInsightsRes,getSectionRes, updateRange } from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { SummaryData } from "../../../../../../setup/types/report";
import { SummaryChart } from "./SummaryChart";
import { CustomSelect } from "../../../../../../_metronic/layout/components/CustomSelect";
import { CommentBox } from "../../../../../../_metronic/layout/components/CommentBox";
import { CommentListPopUp } from "../components/CommentBox";
import {OptionsDropdown} from "../components/OptionsDropdown"
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../../_redux/campaign/Actions";
import { summaryKpiData } from "./summaryData";

import { EditButton } from "../../../../../../_metronic/layout/components/EditButton";
import { SummaryWidgetEdits } from "./SummaryWidgetEdits";



type CampaignSummaryKeys = Omit<SummaryData, "topChannels" | "keyInsights" | "likes">;

interface CampaignSummary {
    [key:string] : any;
    // impressions: CampaignSummaryKeys['impressions'];
    // clicks: CampaignSummaryKeys['clicks'];
    // engangementRate: CampaignSummaryKeys['engangementRate'];
    // cpe: CampaignSummaryKeys['engangementRate'];
    // budget: CampaignSummaryKeys['budget'];
}

type SummaryWidgetProps = { 
    campaignId: any, 
    isExternal?:boolean, 
    commentsData?:any, 
    commentsFilter?:string ,
    setCommentSectionToDisplay:any,
    commentSectionToDisplay:any,
    isNotAmyris?:boolean;
    isPdfPage?: boolean
}

export const SummaryWidget = ({ campaignId, isNotAmyris, isExternal, commentsData,commentsFilter,commentSectionToDisplay, setCommentSectionToDisplay, isPdfPage }:SummaryWidgetProps ) => {
    const dispatch = useAppDispatch();
    const dispatches = useDispatch<any>();
    
    const {displayKpi, settingData} = useAppSelector((state) => ({
        displayKpi: state.report.displayKpi,
        settingData: state.report.SectionRes
    }));
    const { loading} = useAppSelector((state) => state.report.summary);
    const { data: detailData } = useAppSelector((state) => state.report.campaignDetail);

    const { campaignStats, campaignActivity, campaignDetails, campaignCurrency } = useSelector(
        (state: any) => ({
            campaignStats: state.campaign.campaignStats,
            campaignDetails: state.campaign.campaignDetails,
            campaignActivity: state.campaign.activity,
            campaignCurrency: state.campaign.campaignCurrency,
        })
    );

    const kpiData = useMemo(() => {
        return settingData?.data && settingData?.data?.kpi.length !==0 ? settingData?.data?.kpi : displayKpi || []
    }, [settingData, displayKpi])


    const [date, setDate] = useState<string>('7');
    const [campaignSummaryData,  setCampaignSummmaryData] = useState({})
    const [edit, setEdit] = useState<boolean>(false)


    useEffect(() => {
        if (campaignId) {
            dispatch(getSummary({
                campaign_id: campaignId,
                date_range: date.toString(),
                is_external: isExternal
            }));
            dispatch(getSectionRes({
                campaign_id: campaignId,
                is_external: isExternal
            }))

            dispatches(actions.fetchCampaignStats(campaignId))
            dispatch(actions.fetchCampaignDetails(campaignId));

            dispatch(getKeyInsightsRes({
                campaign_id: campaignId,
                is_external: isExternal,
                date_range: date.toString()
            }))
        }
    }, [date, campaignId]);
    

    const campaignDates: { startDate: string, endDate: string } = {
        startDate: detailData?.startDate || "",
        endDate: detailData?.endDate || ""
    }

    let weeksArray: number[] = []

    function getWeeksArray(startDate: Date, endDate: Date): number[] {
        const weeksArray = [];
        const daysDiff = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
        for (let i = 7; i <= daysDiff; i += 7) {
            weeksArray.push(i);
        }
        return weeksArray;
    }

    if (campaignDates) {
        // getting weekly filter array
        const startDate = new Date(campaignDates.startDate);
        const endDate = new Date(campaignDates.endDate);
        weeksArray = getWeeksArray(startDate, endDate); // Output: [7, 14, 21]
    }

    let weeksOptions: { label: string, value: string }[] = []

    weeksArray.forEach((number) => {
        weeksOptions.push({
            label: "week " + (number / 7),
            value: number.toString()
        })
    });


    let campaignSummary: CampaignSummary = useMemo(()=> {
        return summaryKpiData(campaignActivity,campaignStats,campaignDetails)
    },[campaignActivity,campaignStats,campaignDetails])

    useEffect(() =>{
        if (campaignStats && kpiData) {
            var est = campaignActivity?.data?.new_er || parseFloat(campaignStats?.target_data?.campaign_actual?.engangementRate)
            var es100s = (est * 100) >= 100 ? est : est * 100;
            let campaignSummaryVar = {
                ...campaignSummary,
                engangementRate: {
                    currentValue: es100s || 0,
                    target: parseFloat(campaignStats?.target_data?.target_er) || 0
                }
            }

            const filteredObject = Object.fromEntries(Object.entries(campaignSummaryVar).filter(([key, value]) => {
                if(kpiData.includes(key)){
                    return true
                }
                return false
              }));

            setCampaignSummmaryData(filteredObject)
            
        }
    },[kpiData, campaignActivity, campaignStats,campaignSummary])

    const onCancelEdits = () => {
       
        setEdit(false);

        // // Set `setEdit` to true after 200ms
        // setTimeout(() => {
        //   setEdit(true);
      
        //   // Set `setEdit` back to false after another 200ms
        //   setTimeout(() => {
        //     setEdit(false);
        //   }, 500);
        // }, 500);
    }

    // useEffect(() => {
    //     const handleFilterCampaignSummary = () => {

    //         const filteredObject = Object.fromEntries(Object.entries(campaignSummary).filter(([key, value]) => {
    //             if(kpiData.includes(key)){
    //                 return true
    //             }
    //             return false
    //           }));

    //           setCampaignSummmaryData(filteredObject)
              
    //     }
    //     if(campaignSummary !== undefined){
    //         handleFilterCampaignSummary()
    //     }
    // }, [kpiData, campaignStats, campaignActivity])
    

    return (
        <div id="performance_sum_wrap" className={`${isExternal ? "" : "container"} ${isPdfPage && "pt-0"} performance_sum_wrap`} style={{ backgroundColor: "#fff", borderRadius: "24px", padding: "40px 40px 0px 40px"}}>

            <div className="d-flex justify-content-between align-items-center section_wrap_content">
                
                {!isNotAmyris && !isPdfPage && <OptionsDropdown isNotAmyris={isNotAmyris} isExternal={isExternal}/>}
                
                <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                    <p className="NeueMontreal-bold"
                        style={{ fontSize: "30px", fontWeight: 700, margin: 0, color: "#000", letterSpacing: "0em" }}>
                        Campaign Performance Summary
                    </p>
                </div>
                
                {!isPdfPage &&
                    <div className="d-flex justify-content-between align-items-center" style={{ gap: "24px" }} >
                        {!isExternal && (
                            <>
                                {
                                    edit === false && <EditButton handleClick={() =>{
                                        setEdit(true)
                                    }} />
                                }
                            </>
                        )}
                        <CustomSelect
                            options={weeksOptions}
                            value={date}
                            onChange={(e) => {
                                setDate(e.target.value)
                                dispatch(updateRange(e.target.value))
                            }}
                            containerStyle={{ gap: 0, padding: "12px", borderRadius: "16px" }}
                            textStyle={{ width: "90px !important" }} />




                        <CommentBox commentSectionToDisplay={commentsFilter} setCommentSectionToDisplay={setCommentSectionToDisplay} comments={[...commentsData]} />
                        {(commentSectionToDisplay === commentsFilter) && <CommentListPopUp isExternal={isExternal} campaignId={campaignId} setCommentSectionToDisplay={setCommentSectionToDisplay} commentFilter={commentsFilter} allComments={commentsData} />}
                    </div>
                }
            </div>


            <div style={{ padding: "40px 0px" }}>
                {loading && (
                    <div className="d-flex flex-row flex__wrap justify-content-between align-items-stretch">
                        {new Array(5).fill(0).map((_, index) => (
                            <Skeleton key={index} height={200} width={200}
                                style={{
                                    border: "20px solid transparent",
                                    borderRadius: "50%",
                                    background: "none",
                                    borderBottomColor: "transparent",
                                    borderLeftColor: "transparent",
                                    transform: "rotate(-45deg)",
                                }} />
                        ))}
                    </div>
                )}

                {!loading && campaignSummaryData && campaignSummary && campaignStats && !edit && (
                    <div className="d-flex flex-row justify-content-between flex__wrap">
                        {Object.keys(campaignSummaryData).slice(0,5).map((dataPoint, index) => {
                            let key = dataPoint as keyof CampaignSummaryKeys;
                            
                            return (
                                <div key={dataPoint}
                                    style={{
                                        display: "flex",
                                        marginTop: "10px",
                                        maxHeight: "220px"
                                    }}>
                                    <SummaryChart
                                        dataPoint={key}
                                        valueData={campaignSummary[key]!}
                                        currency={campaignCurrency?.currency || "$"}
                                    />

                                </div>

                            )
                        })}

                    
                    </div>

                )}

                {!loading && campaignSummaryData && campaignSummary && campaignStats && edit && (
                    <SummaryWidgetEdits onCancelEdits={onCancelEdits} campaignId={campaignId} date={date} campaignSummary={campaignSummary} isExternal={isExternal}/>

                )}
            </div>
        </div>
    )
}