import ReactApexChart from "react-apexcharts";
import distinctColors from 'distinct-colors'
import { ApexOptions } from 'apexcharts';




const AllEngageMentFormats = (props: any) => {
    let { brandsSummaryInfo, chosenBrand, brandSocialPostsInfo, dateDiff, landscapeInfo } = props;



    let igPostsPerday: any = 0, twitterPostsPerday: any = 0,
        tiktokPostsPerday: any = 0, youtubePostsPerday: any = 0,
        facebookPostsPerday: any = 0,

        facebookEngagementTotal: any = 0, instagramEngagementTotal: any = 0,
        youtubeEngagementTotal: any = 0, tiktokEngagementTotal: any = 0,
        twitterEngagementTotal: any = 0,
        companyEngagementTotal: any = 0,


        facebookFollowersTotal: any = 0, instagramFollowersTotal: any = 0,
        youtubeFollowersTotal: any = 0, tiktokFollowersTotal: any = 0,
        twitterFollowersTotal: any = 0,

        videosPerDay: any = 0, reelsPerDay: any = 0,
        photosPerDay: any = 0, carouselPerDay: any = 0,


        igPostsPerdayPerCompany: any = 0, twitterPostsPerdayPerCompany: any = 0,
        tiktokPostsPerdayPerCompany: any = 0, youtubePostsPerdayPerCompany: any = 0,
        facebookPostsPerdayPerCompany: any = 0,


        videosPerDayPerCompany: any = 0, reelsPerDayPerCompany: any = 0,
        photosPerDayPerCompany: any = 0, carouselPerDayPerCompany: any = 0,

        igERPerfollower: any = 0, twitterERPerfollower: any = 0,
        tiktokERPerfollower: any = 0, youtubeERPerfollower: any = 0,
        facebookERPerfollower: any = 0,

        reelsERPerfollower: any = 0, videosERPerfollower: any = 0,
        photosERPerfollower: any = 0, carouselERPerfollower: any = 0





    let companyPosts: any[] = brandSocialPostsInfo?.socialPosts;
    let competitorsPosts: any[] = brandSocialPostsInfo?.competitorPosts?.socialPosts;

    let allPosts: any[] = [...companyPosts, ...competitorsPosts];
    let tiktokPosts: any[] = [],
        facebookPosts: any[] = [],
        companyFacebookPosts: any[] = [],
        twitterPosts: any[] = [],
        youtubePosts: any[] = [],
        instagramPosts: any[] = [],
        coomapnyinstagramPosts: any[] = [],
        videoPosts: any[] = [],
        photoPosts: any[] = [],
        reelsPosts: any[] = [],
        carouselPosts: any[] = [];



    for (let index = 0; index < allPosts.length; index++) {

        let eachItem = allPosts[index];

        if (eachItem?.channel === "instagram") {
            instagramPosts.push(eachItem)
        }
        if (eachItem?.channel === "facebook") {
            facebookPosts.push(eachItem)

        }

        if (eachItem?.channel === "youtube") {
            youtubePosts.push(eachItem)
        }
        if (eachItem?.channel === "tiktok") {
            tiktokPosts.push(eachItem)
        }
        if (eachItem?.channel === "twitter") {
            twitterPosts.push(eachItem)
        }

        if (eachItem?.type === "reel") {
            // reelsPerDay += 1
            reelsPosts.push(eachItem)
        }
        if (eachItem?.type === "carousel") {
            // carouselPerDay += 1
            carouselPosts.push(eachItem)
        }
        if (eachItem?.type === "photo") {
            // photosPerDay += 1;
            photoPosts.push(eachItem)
        }
        if (eachItem?.type === "video") {
            // videosPerDay += 1;
            videoPosts.push(eachItem)
        }
    }

    let focusIGPosts = companyPosts.filter((eachItem: any) => eachItem?.channel === "tiktok")

    for (let index = 0; index < companyPosts.length; index++) {
        let eachItem = allPosts[index];
        companyEngagementTotal += eachItem?.engagementTotal
        if (eachItem?.channel === "instagram") {
            igPostsPerday += 1;
            coomapnyinstagramPosts.push(eachItem)
        }
        if (eachItem?.channel === "facebook") {
            facebookPostsPerday += 1;
            companyFacebookPosts.push(eachItem)
        }

        if (eachItem?.channel === "youtube") {
            youtubePostsPerday += 1;
        }
        if (eachItem?.channel === "tiktok") {
            tiktokPostsPerday += 1;
        }
        if (eachItem?.channel === "twitter") {
            twitterPostsPerday += 1;
        }

        if (eachItem?.type === "reel") {
            reelsPerDay += 1
        }
        if (eachItem?.type === "carousel") {
            carouselPerDay += 1
        }
        if (eachItem?.type === "photo") {
            photosPerDay += 1;
        }
        if (eachItem?.type === "video") {
            videosPerDay += 1;
        }
    }




    let companiesWithCarouselPosts: any[] = [], companiesWithReelsPosts: any[] = [],
        companiesWithVideoPosts: any[] = [], companiesWithPhotoPosts: any[] = [];

    for (let index = 0; index < reelsPosts.length; index++) {
        reelsERPerfollower += reelsPosts[index].engagementRate || 0
        companiesWithReelsPosts.push(reelsPosts[index].companyName)
    }

    for (let index = 0; index < videoPosts.length; index++) {
        videosERPerfollower += videoPosts[index].engagementRate || 0
        companiesWithVideoPosts.push(videoPosts[index].companyName)
    }

    for (let index = 0; index < photoPosts.length; index++) {
        photosERPerfollower += photoPosts[index].engagementRate || 0
        companiesWithPhotoPosts.push(photoPosts[index].companyName)
    }

    for (let index = 0; index < carouselPosts.length; index++) {
        carouselERPerfollower += carouselPosts[index].engagementRate || 0
        companiesWithCarouselPosts.push(carouselPosts[index].companyName)
    }

    companiesWithReelsPosts = companiesWithReelsPosts.filter((c, index) => {
        return companiesWithReelsPosts.indexOf(c) === index;
    });
    companiesWithVideoPosts = companiesWithVideoPosts.filter((c, index) => {
        return companiesWithVideoPosts.indexOf(c) === index;
    });
    companiesWithPhotoPosts = companiesWithPhotoPosts.filter((c, index) => {
        return companiesWithPhotoPosts.indexOf(c) === index;
    });
    companiesWithCarouselPosts = companiesWithCarouselPosts.filter((c, index) => {
        return companiesWithCarouselPosts.indexOf(c) === index;
    });



    igPostsPerday = (igPostsPerday > 0 && dateDiff > 0) ? (igPostsPerday / dateDiff).toFixed(2) : 0;
    igPostsPerdayPerCompany = (instagramPosts.length > 0 && landscapeInfo?.companies.length > 0 && dateDiff > 0) ? ((instagramPosts.length / landscapeInfo?.companies.length) / dateDiff).toFixed(2) : 0
    facebookPostsPerday = (facebookPostsPerday > 0 && dateDiff > 0) ? (facebookPostsPerday / dateDiff).toFixed(2) : 0;
    facebookPostsPerdayPerCompany = (facebookPosts.length > 0 && landscapeInfo?.companies.length > 0 && dateDiff > 0) ? ((facebookPosts.length / landscapeInfo?.companies.length) / dateDiff).toFixed(2) : 0
    youtubePostsPerday = (youtubePostsPerday > 0 && dateDiff > 0) ? (youtubePostsPerday / dateDiff).toFixed(2) : 0;
    youtubePostsPerdayPerCompany = (youtubePosts.length > 0 && landscapeInfo?.companies.length > 0 && dateDiff > 0) ? ((youtubePosts.length / landscapeInfo?.companies.length) / dateDiff).toFixed(2) : 0
    tiktokPostsPerday = (tiktokPostsPerday > 0 && dateDiff > 0) ? Number(tiktokPostsPerday / dateDiff).toFixed(2) : 0;
    tiktokPostsPerdayPerCompany = (tiktokPosts.length > 0 && landscapeInfo?.companies.length > 0 && dateDiff > 0) ? ((tiktokPosts.length / landscapeInfo?.companies.length) / dateDiff).toFixed(2) : 0
    twitterPostsPerday = (twitterPostsPerday > 0 && dateDiff > 0) ? (twitterPostsPerday / dateDiff).toFixed(2) : 0;
    twitterPostsPerdayPerCompany = (twitterPosts.length > 0 && landscapeInfo?.companies.length > 0 && dateDiff > 0) ? ((twitterPosts.length / landscapeInfo?.companies.length) / dateDiff).toFixed(2) : 0


    reelsPerDay = (reelsPerDay > 0 && dateDiff > 0) ? (reelsPerDay / dateDiff).toFixed(2) : 0;
    reelsPerDayPerCompany = (reelsPosts.length > 0 && landscapeInfo?.companies.length > 0 && dateDiff > 0) ? ((reelsPosts.length / landscapeInfo?.companies.length) / dateDiff).toFixed(2) : 0
    carouselPerDay = (carouselPerDay > 0 && dateDiff > 0) ? (carouselPerDay / dateDiff).toFixed(2) : 0;
    carouselPerDayPerCompany = (carouselPosts.length > 0 && landscapeInfo?.companies.length > 0 && dateDiff > 0) ? ((carouselPosts.length / landscapeInfo?.companies.length) / dateDiff).toFixed(2) : 0
    photosPerDay = (photosPerDay > 0 && dateDiff > 0) ? (photosPerDay / dateDiff).toFixed(2) : 0;
    photosPerDayPerCompany = (photoPosts.length > 0 && landscapeInfo?.companies.length > 0 && dateDiff > 0) ? ((photoPosts.length / landscapeInfo?.companies.length) / dateDiff).toFixed(2) : 0
    videosPerDay = (videosPerDay > 0 && dateDiff > 0) ? (videosPerDay / dateDiff).toFixed(2) : 0;
    videosPerDayPerCompany = (videoPosts.length > 0 && landscapeInfo?.companies.length > 0 && dateDiff > 0) ? ((videoPosts.length / landscapeInfo?.companies.length) / dateDiff).toFixed(2) : 0


    let palette: any[] = distinctColors({ count: 4 }) || [];
    let chartColors: any[] = palette.map((each: any) => `${each}`)

    let channelPostPerDay: any[] = [
        {
            channelName: "Instagram",
            postPerday: Number(igPostsPerday),
            color: "#507da2"
        },
        {
            channelName: "Facebook",
            postPerday: Number(facebookPostsPerday),
            color: "#274b82"
        },
        {
            channelName: "YouTube",
            postPerday: Number(youtubePostsPerday),
            color: "#ff0000"
        },
        {
            channelName: "TikTok",
            postPerday: Number(tiktokPostsPerday),
            color: "#43DDF2"
        },
        {
            channelName: "Twitter",
            postPerday: Number(twitterPostsPerday),
            color: "#62c2f5"
        }
    ]

    let typeOfPostPerDay: any[] = [
        {
            postType: "Video",
            postPerday: Number(videosPerDay),
            color: chartColors[0],
        },
        {
            postType: "Photo",
            postPerday: Number(photosPerDay),
            color: chartColors[1],
        },
        {
            postType: "Reel",
            postPerday: Number(reelsPerDay),
            color: chartColors[2],
        },
        {
            postType: "Carousel",
            postPerday: Number(carouselPerDay),
            color: chartColors[3],
        }
    ]








    for (let index = 0; index < instagramPosts.length; index++) {
        igERPerfollower += instagramPosts[index].engagementRate || 0

        instagramEngagementTotal += instagramPosts[index].engagementTotal;
        instagramFollowersTotal += instagramPosts[index].followers;
    }

    for (let index = 0; index < facebookPosts.length; index++) {
        facebookERPerfollower += facebookPosts[index].engagementRate || 0

        facebookEngagementTotal += facebookPosts[index].engagementTotal;
        facebookFollowersTotal += facebookPosts[index].followers;
    }

    for (let index = 0; index < youtubePosts.length; index++) {
        youtubeERPerfollower += youtubePosts[index].engagementRate || 0
        youtubeEngagementTotal += youtubePosts[index].engagementTotal;

        youtubeFollowersTotal += youtubePosts[index].followers;
    }

    for (let index = 0; index < tiktokPosts.length; index++) {
        tiktokERPerfollower += tiktokPosts[index].engagementRate || 0
        tiktokEngagementTotal += tiktokPosts[index].engagementTotal;

        tiktokFollowersTotal += tiktokPosts[index].followers;
    }

    for (let index = 0; index < twitterPosts.length; index++) {
        twitterERPerfollower += twitterPosts[index].engagementRate || 0

        twitterEngagementTotal += twitterPosts[index].engagementTotal;
        twitterFollowersTotal += twitterPosts[index].followers;
    }


    igERPerfollower = instagramFollowersTotal > 0 ? (instagramEngagementTotal / instagramFollowersTotal) * 100 : 0
    facebookERPerfollower = facebookFollowersTotal > 0 ? (facebookEngagementTotal / facebookFollowersTotal) * 100 : 0
    tiktokERPerfollower = tiktokFollowersTotal > 0 ? (tiktokEngagementTotal / tiktokFollowersTotal) * 100 : 0
    youtubeERPerfollower = youtubeFollowersTotal > 0 ? (youtubeEngagementTotal / youtubeFollowersTotal) * 100 : 0
    twitterERPerfollower = twitterFollowersTotal > 0 ? twitterEngagementTotal / twitterFollowersTotal : 0




    reelsERPerfollower = (reelsERPerfollower > 0 && reelsPosts.length > 0) ? ((reelsERPerfollower / reelsPosts.length) * 100).toFixed(2) : 0;
    videosERPerfollower = (videosERPerfollower > 0 && videoPosts.length > 0) ? ((videosERPerfollower / videoPosts.length) * 100).toFixed(2) : 0;
    photosERPerfollower = (photosERPerfollower > 0 && photoPosts.length > 0) ? ((photosERPerfollower / photoPosts.length) * 100).toFixed(2) : 0;
    carouselERPerfollower = (carouselERPerfollower > 0 && carouselPosts.length > 0) ? ((carouselERPerfollower / carouselPosts.length) * 100).toFixed(2) : 0;



    igERPerfollower = (igERPerfollower > 0 && instagramPosts.length > 0) ? ((igERPerfollower / instagramPosts.length) * 100).toFixed(2) : 0;
    facebookERPerfollower = (facebookERPerfollower > 0 && facebookPosts.length > 0) ? ((facebookERPerfollower / facebookPosts.length) * 100).toFixed(2) : 0;
    youtubeERPerfollower = (youtubeERPerfollower > 0 && youtubePosts.length > 0) ? ((youtubeERPerfollower / youtubePosts.length) * 100).toFixed(2) : 0;
    tiktokERPerfollower = (tiktokERPerfollower > 0 && tiktokPostsPerday.length > 0) ? ((tiktokERPerfollower / tiktokPosts.length) * 100).toFixed(2) : 0;
    twitterERPerfollower = (twitterERPerfollower > 0 && twitterPosts.length > 0) ? ((twitterERPerfollower / twitterPosts.length) * 100).toFixed(2) : 0;



    let channelActivity: any[] = [
        {
            channelName: "Instagram",
            postPerdayPerCompany: Number(igPostsPerdayPerCompany),
            color: "#507da2",
            erPerFollower: Number(igERPerfollower)
        },
        {
            channelName: "Facebook",
            postPerdayPerCompany: Number(facebookPostsPerdayPerCompany),
            color: "#274b82",
            erPerFollower: Number(facebookERPerfollower)
        },
        {
            channelName: "YouTube",
            postPerdayPerCompany: Number(youtubePostsPerdayPerCompany),
            color: "#ff0000",
            erPerFollower: Number(youtubeERPerfollower)
        },
        {
            channelName: "TikTok",
            postPerdayPerCompany: Number(tiktokPostsPerdayPerCompany),
            color: "#43DDF2",
            erPerFollower: Number(tiktokERPerfollower)
        },
        {
            channelName: "Twitter",
            postPerdayPerCompany: Number(twitterPostsPerdayPerCompany),
            color: "#62c2f5",
            erPerFollower: Number(twitterERPerfollower)
        }
    ]

    let postTypeActivity: any[] = [
        {
            postType: "Video",
            postPerdayPerCompany: Number(videosPerDayPerCompany),
            color: chartColors[0],
            erPerFollower: Number(videosERPerfollower)
        },
        {
            postType: "Photo",
            postPerdayPerCompany: Number(photosPerDayPerCompany),
            color: chartColors[1],
            erPerFollower: Number(photosERPerfollower)
        },
        {
            postType: "Reel",
            postPerdayPerCompany: Number(reelsPerDayPerCompany),
            color: chartColors[2],
            erPerFollower: Number(reelsERPerfollower)
        },
        {
            postType: "Carousel",
            postPerdayPerCompany: Number(carouselPerDayPerCompany),
            color: chartColors[3],
            erPerFollower: Number(carouselERPerfollower)
        }
    ]





    channelPostPerDay.sort((a: any, b: any) => (b.postPerday - a.postPerday))
    channelActivity.sort((a: any, b: any) => (b.erPerFollower - a.erPerFollower))

    typeOfPostPerDay.sort((a: any, b: any) => (b.postPerday - a.postPerday))
    postTypeActivity.sort((a: any, b: any) => (b.erPerFollower - a.erPerFollower))



    channelActivity = channelActivity.map((eachItem: any, index: any) => {

        let linePercent: number = 0;
        if (index === 0) {
            linePercent = 100
        } else {
            linePercent = (eachItem?.erPerFollower * 100) / channelActivity[0]?.erPerFollower
        }

        return {
            ...eachItem,
            linePercent
        }
    })

    postTypeActivity = postTypeActivity.map((eachItem: any, index: any) => {

        let linePercent: number = 0;
        if (index === 0) {
            linePercent = 100
        } else {
            linePercent = (eachItem?.erPerFollower * 100) / postTypeActivity[0]?.erPerFollower
        }

        return {
            ...eachItem,
            linePercent
        }
    })


    let seriesData: any[] = channelPostPerDay.map((eachData: any) => eachData?.postPerday)
    let labelsData: any[] = channelPostPerDay.map((eachData: any) => eachData?.channelName)
    let colorsData: any[] = channelPostPerDay.map((eachData: any) => eachData?.color)


    let seriesDataForPostType: any[] = typeOfPostPerDay.map((eachData: any) => eachData?.postPerday)
    let labelsDataForPostType: any[] = typeOfPostPerDay.map((eachData: any) => eachData?.postType)
    let colorsDataForPostType: any[] = typeOfPostPerDay.map((eachData: any) => eachData?.color)




    const optionsOne: ApexOptions = {
        labels: [...labelsData],
        colors: [...colorsData],
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false,

        }
    }

    const optionsTwo: ApexOptions = {
        labels: [...labelsDataForPostType],
        colors: [...colorsDataForPostType],
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false,

        }
    }




    return (
        <div className="engagement_format_data " id="slide14">
            <div className="each_metric_type">
                <div className="metric_type_txt">
                    Post Channels
                </div>
                <div className="with_sides">
                    <div className="side_one">
                        <div className="side_title">
                            YOUR ACTIVITY BY CHANNEL
                        </div>
                        <div className="side_data">
                            <div className="table_data">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="format_data_heading"> <div className="head_txt">Post Channel</div></th>
                                            <th className="format_data_heading"> <div className="head_txt">Post / Day</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            channelPostPerDay.map((eachStat: any, index: any) => {
                                                return (
                                                    <tr className="each_format_data" key={index}>
                                                        <td className="format_data_type">
                                                            <div>
                                                                <span style={{ backgroundColor: eachStat?.color }} className="data_color"></span> <span className="data_value">{eachStat?.channelName}</span>
                                                            </div>
                                                        </td>
                                                        <td className="format_data_type">{eachStat?.postPerday} / day</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {/* <tr className="each_format_data">
                                    <td className="format_data_type">Instagram</td>
                                    <td className="format_data_type">{igPostsPerday} / day</td>
                                </tr>
                                <tr className="each_format_data">
                                    <td className="format_data_type">YouTube</td>
                                    <td className="format_data_type">{facebookPostsPerday} / day</td>
                                </tr>
                                <tr className="each_format_data">
                                    <td className="format_data_type">YouTube</td>
                                    <td className="format_data_type">IG Reel</td>
                                </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            <div className="chart_rep_wrap">
                                <ReactApexChart id="chart15" options={optionsOne} series={seriesData} type="pie" />
                            </div>
                        </div>
                    </div>
                    <div className="side_two">
                        <div className="side_title">
                            LANDSCAPE ACTIVITY AND Followers BY CHANNEL Followers </div>
                        <div className="side_data full">
                            <div className="table_data">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="format_data_heading"> <div className="head_txt">Post Channel</div></th>
                                            <th className="format_data_heading"> <div className="head_txt">Posts per Day per Company</div></th>
                                            <th className="format_data_heading"> <div className="head_txt">Engagement Rate by Follower</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            channelActivity.map((eachStat: any, index: any) => {
                                                return (
                                                    <tr className="each_format_data" key={index}>
                                                        <td className="format_data_type">
                                                            <div>
                                                                <span className="data_value">{eachStat?.channelName}</span>
                                                            </div>
                                                        </td>
                                                        <td className="format_data_type">{eachStat?.postPerdayPerCompany} / day</td>
                                                        <td className="format_data_type">
                                                            <div>
                                                                <div className="line_ng"><span style={{ backgroundColor: eachStat?.color, width: `${eachStat?.linePercent}%` }} className="line_data_color"></span></div>  <span className="data_value">{eachStat?.erPerFollower}%</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {/* <tr className="each_format_data">
                                    <td className="format_data_type">Instagram</td>
                                    <td className="format_data_type">{igPostsPerday} / day</td>
                                </tr>
                                <tr className="each_format_data">
                                    <td className="format_data_type">YouTube</td>
                                    <td className="format_data_type">{facebookPostsPerday} / day</td>
                                </tr>
                                <tr className="each_format_data">
                                    <td className="format_data_type">YouTube</td>
                                    <td className="format_data_type">IG Reel</td>
                                </tr> */}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="each_metric_type">
                <div className="metric_type_txt">
                    Post Type
                </div>
                <div className="with_sides">
                    <div className="side_one">
                        <div className="side_title">
                            YOUR ACTIVITY BY POST TYPE
                        </div>
                        <div className="side_data">
                            <div className="table_data">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="format_data_heading"> <div className="head_txt">Post Channel</div></th>
                                            <th className="format_data_heading"> <div className="head_txt">Post / Day</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            typeOfPostPerDay.map((eachStat: any, index: any) => {
                                                return (
                                                    <tr className="each_format_data" key={index}>
                                                        <td className="format_data_type">
                                                            <div>
                                                                <span style={{ backgroundColor: eachStat?.color }} className="data_color"></span> <span className="data_value">{eachStat?.postType}</span>
                                                            </div>
                                                        </td>
                                                        <td className="format_data_type">{eachStat?.postPerday} / day</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {/* <tr className="each_format_data">
                                    <td className="format_data_type">Instagram</td>
                                    <td className="format_data_type">{igPostsPerday} / day</td>
                                </tr>
                                <tr className="each_format_data">
                                    <td className="format_data_type">YouTube</td>
                                    <td className="format_data_type">{facebookPostsPerday} / day</td>
                                </tr>
                                <tr className="each_format_data">
                                    <td className="format_data_type">YouTube</td>
                                    <td className="format_data_type">IG Reel</td>
                                </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            <div className="chart_rep_wrap">
                                <ReactApexChart id="chart16" options={optionsTwo} series={seriesDataForPostType} type="pie" />
                            </div>
                        </div>
                    </div>
                    <div className="side_two">
                        <div className="side_title">
                            LANDSCAPE ACTIVITY AND ENGAGEMENT BY POST TYPE
                        </div>
                        <div className="side_data full">
                            <div className="table_data">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="format_data_heading"> <div className="head_txt">Post Channel</div></th>
                                            <th className="format_data_heading"> <div className="head_txt">Posts per Day per Company</div></th>
                                            <th className="format_data_heading"> <div className="head_txt">Engagement Rate by Follower</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            postTypeActivity.map((eachStat: any, index: any) => {
                                                return (
                                                    <tr className="each_format_data" key={index}>
                                                        <td className="format_data_type">
                                                            <div>
                                                                <span className="data_value">{eachStat?.postType}</span>
                                                            </div>
                                                        </td>
                                                        <td className="format_data_type">{eachStat?.postPerdayPerCompany} / day</td>
                                                        <td className="format_data_type">
                                                            <div>
                                                                <div className="line_ng"><span style={{ backgroundColor: eachStat?.color, width: `${eachStat?.linePercent}%` }} className="line_data_color"></span></div>  <span className="data_value">{eachStat?.erPerFollower}%</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {/* <tr className="each_format_data">
                                    <td className="format_data_type">Instagram</td>
                                    <td className="format_data_type">{igPostsPerday} / day</td>
                                </tr>
                                <tr className="each_format_data">
                                    <td className="format_data_type">YouTube</td>
                                    <td className="format_data_type">{facebookPostsPerday} / day</td>
                                </tr>
                                <tr className="each_format_data">
                                    <td className="format_data_type">YouTube</td>
                                    <td className="format_data_type">IG Reel</td>
                                </tr> */}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}




export default function FormatEngagement(props: any) {
    let { brandsSummaryInfo, dateDiff, chosenBrand, brandSocialPostsInfo, landscapeInfo } = props;


    return (
        <div className="engagement_formats_wrap">
            <div className="engage_section_header">
                Format Engagement

            </div>
            {chosenBrand && <AllEngageMentFormats landscapeInfo={landscapeInfo} dateDiff={dateDiff} brandSocialPostsInfo={brandSocialPostsInfo} chosenBrand={chosenBrand} brandsSummaryInfo={brandsSummaryInfo} />}
        </div>
    )


}