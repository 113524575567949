import Skeleton from "react-loading-skeleton";
import { getMonthAndDay, metricValueFormatter } from "../../../../../../helper";
// import { getKeyInsightsRes } from "../../../../../../setup/features/report.slice";

import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";

import { ErrorBoundary } from "../../../../../sharedComponents/ErrorBoundary"
import * as functions from "../../../../../global/functions";

import { useState,useRef } from "react";
import { Formik, Form, Field, FormikProps } from "formik";
import { EditButton } from "../../../../../../_metronic/layout/components/EditButton";
import { getKeyInsightsRes } from "../../../../../../setup/features/report.slice";
import { toast } from "react-toastify";
import axios from "axios";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useSession } from "../components/hooks/useSession";




const API_URL = process.env.REACT_APP_API_URL;

export const KeyInsights = ({ campaignId, isExternal }: any) => {

    const [edit, setEdit] = useState<boolean>(false)
    const { sessionData, setSessionData } = useSession("keyInsights", [
        "clicks", "engagement", "engagementRate", "impressions"
    ], 1)

    const toggleInsights = (key:string) => {
        setSessionData((prevData) => {
            prevData = prevData || [];
            if (prevData.includes(key)) {
                return prevData.filter((item) => item !== key);
            } else {
                return [...prevData, key];
            }
        });
      };

    //this is dispatched inside summarywidget
    const { data, loading, error } = useAppSelector((state) => state.report.keyInsightsRes);
    const dispatch = useAppDispatch();
    const range = useAppSelector((state) => state.report.range).toString();

    const editHandler = (value:any) => {
        if(value === "edit"){
            return setEdit(true)
        }
        else{
            return setEdit(false)
        }
    }

    const initialValues = {
        currentSales:data?.sales?.currentSales || 0,
        rate:data?.sales?.rate || 0,
        increase:data?.sales?.increase || true
    }

    const handleSubmit = async(values: any) =>{
        if (values !== initialValues) {

            const dataResponse = {
                ...data,
                "sales": {
                    "currentSales": values?.currentSales,
                    "rate": values?.rate,
                    "increase": values?.increase

                }
            }


            try {
                const response = await toast.promise(axios.post(`${API_URL}api/new-reports/edit-campaign?tab_name=key_insights&campaign_id=${campaignId || "000000"}&range=${range||"7"}`, dataResponse), {
                    pending: 'Updating campaign efficiency',
                    success: ` updated campaign successfully!`,
                    error: `Error updating: try again later`
                });

                // console.log("response data => ",response)

                if (response.status === 200) {
                    dispatch(getKeyInsightsRes({
                        campaign_id: campaignId,
                        is_external: isExternal,
                        date_range: range.toString()
                    }));
                }
            }
            catch (error) {
                console.error('Error updating value:', error);
            }
        }
    }

    const formikRef = useRef<FormikProps<any>>(null);


    const handleSalesArrowChange = () => {
        formikRef?.current?.setFieldValue('increase', !formikRef?.current?.values?.increase);
    }


    if (loading) {
        return (
            <div className="mt-4">
                <Skeleton height={190} />
            </div>
        )
    }

    if (data === null) {
        return <>
        <ErrorBoundary error={error} />
        </>;
    }

    const { clicks, engagement, engagementRate, impressions, sales } = data;

    // const monthAndDay = getMonthAndDay(date);
    // console.log("data response => ", data)
    return (

        <>
            <div className="col-xl-12 bg-white py-6 px-4  mt-6" style={{ borderRadius: 10}}>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="text-dark text__20 NeueMontreal-bold">Key Insights This Week</h4>
                    {!isExternal &&
                        <>
                        {!edit? <EditButton bgWhite handleClick={() => editHandler("edit")} /> :
                            (
                                <div className="d-flex align-items-center justify-content-around">
                                    <button className="button__delete__default Button border-none rounded p-3 mr__2" onClick={() => {  
                                        editHandler("save")
                                    }}>
                                        Cancel
                                    </button>
                                    <EditButton bgWhite text="Save" handleClick={() => {
                                        formikRef?.current?.submitForm();
                                        editHandler("save")
                                    }} />
                                </div>
                            )
                        }
                        </>
                    }
                </div>
                
                {
                    error || data === null || data?.length === 0 ? (
                        <>
                            
                            <ErrorBoundary error={error} />
                        </>
                    ) :
                        (
                            <Formik 
                            innerRef={formikRef}
                            initialValues={initialValues} 
                            onSubmit={handleSubmit} >
                                {({values}) => (
                                    <Form>
                                    <div className="mt-6 d-flex justify-content-between align-items-center ">
                                        <div className=" insight_w">
                                            <h5 className="text-dark Helvetica-Neue-Medium fs-5" style={{ fontWeight: 600 }}>
                                                Engagement Rate: 

                                            </h5>
    
                                            <div style={{ height: "fit-content" }} className="d-flex align-items-center">
                                                <p className="d-inline Helvetica-Neue-bold text-dark m-0" style={{ fontSize: 36 }}>
                                                    {functions.abvNum(engagementRate?.currentRate?.toFixed(2)) || 0}%
                                                </p>
    
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ color: engagementRate?.increase ? "#06D6A0" : "#FF0000", fontWeight: 500, marginLeft: 6, fontSize: 14, margin: 0 }}>
                                                        {engagementRate?.rate?.toFixed(2) || 0}% {" "}
                                                        <span className="ml-2">
                                                            <i
                                                                style={{
                                                                    color: engagementRate?.increase ? "#06D6A0" : "#FF0000",
                                                                    fontSize: 12, fontWeight: 500
                                                                }}
                                                                className={engagementRate?.increase ? "bi bi-arrow-up-right" : "bi bi-arrow-down-left"}>
                                                            </i>
                                                        </span>
                                                    </p >
    
                                                    <p className="text-dark Helvetica-Neue-light m-0"
                                                        style={{ fontSize: "14px" }}>
                                                        Since week {range ? (parseInt(range) / 7) - 1 : "1"}
                                                    </p>
                                                </div >
                                            </div >
                                        </div >
    
                                        <div className=" insight_w">
                                            <h5 className="text-dark fs-5 Helvetica-Neue-Medium" style={{  }}>
                                                Impressions:
                                            </h5>
    
                                            <div style={{ height: "fit-content" }} className="d-flex align-items-center">
                                                <p className="d-inline text-dark Helvetica-Neue-bold m-0" style={{ fontSize: 36 }}>
                                                    {functions.abvNum(impressions?.currentImpressions.toFixed(0))|| 0}
                                                </p>
    
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ color: impressions?.increase ? "#06D6A0" : "#FF0000", fontWeight: 500, marginLeft: 6, fontSize: 14, margin: 0 }}>
                                                        {impressions?.rate?.toFixed(2) || 0}% {" "}
                                                        <span className="ml-2">
                                                            <i
                                                                style={{
                                                                    color: impressions?.increase ? "#06D6A0" : "#FF0000",
                                                                    fontSize: 12, fontWeight: 500
                                                                }}
                                                                className={impressions?.increase ? "bi bi-arrow-up-right" : "bi bi-arrow-down-left"}>
                                                            </i>
                                                        </span>
                                                    </p >
    
                                                    <p className="text-dark Helvetica-Neue-light m-0" 
                                                    style={{ fontSize: "14px" }}>
                                                        Since week {range ? (parseInt(range) / 7) - 1 : "1"}
                                                    </p>
                                                </div >
                                            </div >
                                        </div >
    
                                        <div className=" insight_w">
                                            <h5 className="text-dark Helvetica-Neue-Medium fs-5" style={{  }}>
                                                Clicks:
                                            </h5>
    
                                            <div style={{ height: "fit-content" }} className="d-flex align-items-center">
                                                <p className="d-inline Helvetica-Neue-bold text-dark m-0" style={{ fontSize: 36 }}>
                                                    {functions.abvNum(clicks?.currentClicks) || 0}
                                                </p>
    
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ color: clicks?.increase ? "#06D6A0" : "#FF0000", fontWeight: 500, marginLeft: 6, fontSize: 14, margin: 0 }}>
                                                        {clicks?.rate?.toFixed(2) || 0}% {" "}
                                                        <span className="ml-2">
                                                            <i
                                                                style={{
                                                                    color: clicks?.increase ? "#06D6A0" : "#FF0000",
                                                                    fontSize: 12, fontWeight: 500
                                                                }}
                                                                className={clicks?.increase ? "bi bi-arrow-up-right" : "bi bi-arrow-down-left"}>
                                                            </i>
                                                        </span>
                                                    </p >
    
                                                    <p className="text-dark Helvetica-Neue-light m-0" 
                                                    style={{ fontSize: "14px" }}>
                                                        Since week {range ? (parseInt(range) / 7) - 1 : "1"}
                                                    </p>
                                                </div >
                                            </div >
                                        </div >
    
                                        <div className="insight_w">
                                            {!edit && sessionData?.includes("sales") && (
                                                <h5 className="text-dark Helvetica-Neue-Medium fs-5" style={{  }}>
                                                    Sales:
                                                </h5>
                                            ) }
                                            {edit && (
                                                <h5 className="text-dark Helvetica-Neue-Medium fs-5" style={{  }}>
                                                    Sales:
                                                    <span className="ml-2" style={{marginLeft:"6px"}}>
                                                    {
                                                        sessionData?.includes("sales") ? <FiEye onClick={() => toggleInsights("sales")} className="" style={{cursor:"pointer", fontSize:"20px"}}/> 
                                                        : <FiEyeOff onClick={() => toggleInsights("sales")} className="" style={{cursor:"pointer", fontSize:"20px"}}/>
                                                    }
                                                </span>
                                                </h5>
                                            )}
    
                                            <div style={{ height: "fit-content" }} className="d-flex align-items-center">
                                                {
                                                    !edit && sessionData?.includes("sales") && (
                                                        <>
                                                        <p className="d-inline Helvetica-Neue-bold text-dark m-0" style={{ fontSize: 36 }}>
                                                            {sales?.currentSales || 0}
                                                        </p>
    
                                                        <div style={{ marginLeft: 10 }}>
                                                            <p style={{ 
                                                                color: sales?.increase? "#06D6A0" : "#FF0000", 
                                                                fontWeight: 500, marginLeft: 6, 
                                                                fontSize: 14, margin: 0 }}>
                                                                {sales?.rate || 0}%{" "}
                                                                <span className="ml-2">
                                                                    <i
                                                                        style={{
                                                                            color: sales?.increase? "#06D6A0" : "#FF0000",
                                                                            fontSize: 12, fontWeight: 500
                                                                        }}
                                                                        className={` ${sales?.increase? "bi bi-arrow-up-right" : "bi bi-arrow-down-left"}`}>
                                                                    </i>
                                                                </span>
                                                            </p>
    
                                                            <p className="text-dark Helvetica-Neue-light m-0" style={{ fontSize: "14px", fontWeight: 500 }}>
                                                                Since week {range ? (parseInt(range) / 7) - 1 : "1"}
                                                            </p>
                                                        </div>
                                                        </>
                                                    ) }
                                                    {edit && (
                                                        <>
                                                        <Field type="text" name="currentSales" className="d-inline text-dark m-0" style={{ width:"120px", fontSize: 36, fontWeight: 600 }}/>
    
                                                        <div style={{ marginLeft: 10 }}>
                                                            <div className="d-flex">
                                                            <Field type="text" name="rate" style={{width:"80px", 
                                                            color: formikRef?.current?.values?.increase? "#06D6A0" : "#FF0000",
                                                            fontWeight: 500, marginLeft: 6, fontSize: 14, margin: 0 }}/>
                                                            <span className="ml-2" onClick={() => handleSalesArrowChange()}>
                                                                    <i
                                                                        style={{
                                                                            color: formikRef?.current?.values?.increase? "#06D6A0" : "#FF0000",
                                                                            fontSize: 12, fontWeight: 500
                                                                        }}
                                                                        className={` ${formikRef?.current?.values?.increase? "bi bi-arrow-up-right" : "bi bi-arrow-down-left"}`}>
                                                                    </i>
                                                                </span>
                                                            </div>
    
                                                            {/* <Field type="number" className="text-dark m-0" name="range" style={{ width:"80px", fontSize: "14px", fontWeight: 500 }}/> */}
                                                        </div>
                                                        </>
                                                    )
                                                }
                                            </div >
                                        </div >
                                    </div>
                                    </Form>
                                )}
                            </Formik>
                        )
                    }

            </div >
        </>


    )
}