import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { getActionableInsightsRes } from "../../../../../../setup/features/report.slice";
import { useEffect, useState, useMemo, useCallback} from "react";

import {defaultActionableData} from "../components/ActionableComp/actionableDefault"



import axios from 'axios';
import { toast } from 'react-toastify';
import Skeleton from "react-loading-skeleton";
import KeyInsightComp from "../components/ActionableComp/KeyInsightComp";
import HypothesisComp from "../components/ActionableComp/HypothesisComp";
import OptimizationComp from "../components/ActionableComp/OptimizationComp";

const API_URL = process.env.REACT_APP_API_URL;


export const ActionableInsights = ({ campaignId, isExternal }: { campaignId: string, isExternal?: boolean }) => {

    const [editState, seteditState] = useState<boolean|null>(null)
    const [cancelState, setcancelState] = useState<boolean|null>(false)
    const [resetForm, setResetForm] = useState<boolean>(false)

    const editHandler = (value:any) => {
        if(value === "edit"){
            setcancelState(false)
            setResetForm(false)
            return seteditState(true)
        }
        else{
            setResetForm(false)
            return seteditState(false)
        }
    }

    const dispatch = useAppDispatch()

    const { loading, error, data } = useAppSelector((state) => state.report.ActionableInsightsRes);
    const range  = useAppSelector((state) => state.report.range).toString() ;
    

    useEffect(() => {
        
        dispatch(getActionableInsightsRes({
            campaign_id: campaignId, 
            is_external:isExternal,
            date_range:range.toString()
        }));
        
    }, [campaignId, range]);

    let numberOfInsights = 12 
    let countOfInsights = 0

    const [dataResponse, setDataResponse] = useState(error ? {...defaultActionableData, campaign_id:campaignId, range:range.toString()} : 
        data?.data || data !== null ? {...data, range:range.toString()} : {...defaultActionableData, campaign_id:campaignId, range:range.toString()})


    
    let dataRes = error ? {...defaultActionableData, campaign_id:campaignId, range:range.toString()} : 
    data?.data || data !== null ? {...data, range:range.toString()} : {...defaultActionableData, campaign_id:campaignId, range:range.toString()}
    

    
    const handleChanges = useCallback(async(type:any, data:any, index:any) => {
        
        
        if(dataRes){
        if(type === "KeyInsights"){
            
            
            const newDetails = [...dataRes?.data[0]?.details];
            newDetails[index] = { ...newDetails[index], 
                metric: data?.metric,
                value: data?.value,
                rate: data?.rate,
                date: data?.date,
                arrow:data?.arrow
             };

            const newData = [...dataRes?.data];
            newData[0] = { ...newData[0], details: newDetails };
            setDataResponse({ ...dataRes, data: newData })
            dataRes = {...dataRes, data:newData}
        }

        if(type === "Hypothesis"){
          
            const newDetails = [...dataRes?.data[1]?.details];

            newDetails[index] = { ...newDetails[index], 
                topic: data?.topic,
                text: data?.text
             };

            const newData = [...dataRes?.data];
            newData[1] = { ...newData[1], details: newDetails };
            setDataResponse({ ...dataRes, data: newData })
            dataRes = {...dataRes, data:newData}
        }

        if(type === "Optimization"){
            
            
            const newDetails = [...dataRes?.data[2]?.details];
            
            newDetails[index] = { ...newDetails[index],
                topic: data?.topic,
                text: data?.text
             };

            const newData = [...dataRes?.data];
            newData[2] = { ...newData[2], details: newDetails };
            setDataResponse({ ...dataRes, data: newData })
            dataRes = {...dataRes, data:newData}
            
        }
        
        countOfInsights += 1
        

        if(countOfInsights%numberOfInsights === 0 ){
            
            try {
                const dataSubmit = {...dataRes, range:range.toString()}
                const response = await toast.promise(axios.post(`${API_URL}api/new-reports/save-actionable-insights`,dataSubmit),{
                  pending: 'Updating Insights',
                  success: `Insights updated successfully!`,
                  error: `Error updating Insights: try again later`
                })
                
                if(response){
                
                    const dataGet = dispatch(getActionableInsightsRes({
                        campaign_id: campaignId, 
                        is_external:isExternal,
                        date_range:range.toString()
                    }));

                    dataGet.then((res:any) => setResetForm(true))
                }
                
              } catch (error) {
                console.error('Error updating value:', error);
              }
        }

    }

    },[range])

    
    return (
        <div className="mt-8 py-8" id="actionable_insights_wrap">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="NeueMontreal-bold mb-4 text__36">Actionable Insights</h1>

            </div>
            
            {loading && <Skeleton height={90} count={3} />}

            {!loading && 
                (error)? (

                    <div className="bg-white p-4">

                        <KeyInsightComp  type="KeyInsights" data={null} error={error} isExternal={isExternal} 
                        campaignId={campaignId} range={range}/>

                        <HypothesisComp  type="Hypothesis" data={null} isExternal={isExternal} 
                        campaignId={campaignId} range={range} error={error}/>

                        <OptimizationComp  type="Optimization" data={null} isExternal={isExternal} 
                        campaignId={campaignId} range={range} error={error}/>

                    </div>
                ) :
                (
                    <div className="bg-white p-4">

                        <KeyInsightComp  type="KeyInsights" data={data} isExternal={isExternal} 
                        campaignId={campaignId} range={range} error={error}/>

                        <HypothesisComp  type="Hypothesis" data={data} isExternal={isExternal} 
                        campaignId={campaignId} range={range} error={error}/>

                        <OptimizationComp  type="Optimization" data={data} isExternal={isExternal} 
                        campaignId={campaignId} range={range} error={error}/>

                    </div>
                )
            }
        </div>
    )
}

