import { useRef, useEffect, useState, createRef } from "react";
import { toast } from 'react-toastify';
import pptxgen from "pptxgenjs";
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

import { domToPng } from 'modern-screenshot'

import { PerformanceAnalyis } from "./sections/PerformanceAnalysis";
import { ReportBannerSection } from "./sections/Banner";
import { CampaignPerformanceSum } from "./sections/CampaignPerformanceSum";
import { TopPerformingContent } from "./sections/TopPerforming";
import { CampaignEfficiency } from "./sections/CampaignEfficiency";
// import { EngagementOverview } from "./sections/EngagementOverview";
import { InfluencerEfficiencies } from "./sections/InfluencerEfficiencies";
import { ChannelPerformance } from "./sections/ChannelPerformance";
import { ActionableInsights } from "./sections/ActionableInsights";
import { InfluencerBreakdown } from "./sections/InfluencerBreakdown";
import { Attachments } from "./sections/Attachments";
 
import Skeleton from "react-loading-skeleton";
 
import { getCampaignDetails, getCampaignComments, exportReportToPdf,getSectionRes } from "../../../../../setup/features/report.slice";
   
import { useAppDispatch, useAppSelector } from "../../../../../setup/redux/hooks";
import { useDispatch} from 'react-redux';
import * as actions from "../../_redux/campaign/Actions";
import { ErrorBoundary } from "../../../../sharedComponents/ErrorBoundary";
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
  
import { EarnedMediaOverview } from "./sections/EarnedMediaOverview";
import { Insights } from "./sections/Insights";
      
const useScreenshot = require('use-react-screenshot') 
          
const CampaignReport = ({ campaignId, thumbnail, isExternal, isNotAmyris, isPdfPage }: { campaignId: string, thumbnail: string, isExternal?: boolean, isNotAmyris?: boolean, isPdfPage?: boolean }) => {
    const scrollRef = useRef<HTMLElement>();

    const {displayReports, settingData} = useAppSelector((state) => ({
        displayReports: state.report.displayReports,
        settingData: state.report.SectionRes
    }));

    const sectionOptions = settingData?.data && settingData?.data?.sections?.length !== 0 ? settingData?.data?.sections : displayReports || [] 

    
    const [loader, setLoader] = useState<boolean>(true)
    const [commentSectionToDisplay, SetCommentSectionToDisplay] = useState<any>()
    const [pdfToDownload, setPdfToDownload] = useState<any>("")
    const { loading, error, data } = useAppSelector((state) => state.report.campaignDetail);
    const { loading: commentsLoading, error: commentsError, data: commentsData, } = useAppSelector((state) => state.report.commentsRes);

    const { loading: downloadingPdf, error: pdfDownloadError, data: pdfDownloaded, } = useAppSelector((state) => state.report.exportReportPdfRes);


    const bannerRef = useRef<any>()
    const [bannerShot, setBannerShot] = useState<any>()

    const dispatch = useAppDispatch();
    const dispatches = useDispatch();
    localStorage.setItem("range", "7")
    let pptx = new pptxgen();

    // console.log("pdfDownloaded status", pdfDownloaded) 

    useEffect(() => {
        if (loader) {
            // console.log("inside if loader -> ", loader)
            dispatch(getCampaignDetails({ campaign_id: campaignId, is_external: isExternal, date_range: "7" }));
            dispatch(getSectionRes({
                campaign_id: campaignId,
                is_external: isExternal
            }))
            dispatches(actions.fetchStuff('currency', `?campaign_id=${campaignId}`));
            dispatch(getCampaignComments({ campaign_id: campaignId, is_external: isExternal }));
        }
    }, [campaignId, loader]);


    useEffect(() => {

        if (loading === false) {

            setLoader(false)
        }
    }, [loading])

    useEffect(() => {
        if (pdfToDownload) {
            let payload: any = {
                url: pdfToDownload,
                style: `.pdf-wrap { height: 1100px !important;} .pdf-wrap .mt-4.p-8.bg-white.section_wrap_content, 
                .pdf-wrap .mt-16, .pdf-wrap .mt-8.py-8 { padding-top:0 !important; margin-top: 0 !important} #area_chart1>div{width:100% !important;  min-width:100% !important;  height:300px !important}`,
                options: {
                    "format": "A3",
                    "landscape": true,
                    "printBackground": true,

                }
            }

            dispatch(exportReportToPdf({
                data: payload
            })).then(res => setPdfToDownload("")).catch(err => console.log(err))

        }
    }, [pdfToDownload])


    const GetPdfDownloadLink = () => {
        setPdfToDownload("")
        let shareableLink = `${window.location.origin}/campaign-report/download/pdf/${campaignId}#3e2s3`
        // let shareableLink = `http://dev.mgplatform.io/campaign-report/download/pdf/${campaignId}#3e2s3`
        // let shareableLink = `${window.location.origin}/campaign-report/${campaignId}#3e2s3`

        setPdfToDownload(shareableLink)
    }

    const ExportPPTReport = () => {


        pptx.layout = "LAYOUT_WIDE";

        pptx.defineSlideMaster({
            title: "MASTER_SLIDE",
            background: { color: "#F7F9F9" },
            objects: [
                { rect: { x: 0, y: 6.9, w: '100%', h: 0.6, fill: { color: '#F4F6F7' } } },
                
                { image: { x: 11.3, y: 7.0, w: 0.8, h: 0.45, path: toAbsoluteUrl('/media/logos/logo-5.png') } },
            ],
            slideNumber: { x: 1.0, y: 7.0, color: '000000' },
        });

        let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide2 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide3 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide4 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide5 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide6 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide7 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide8 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide9 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide10 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide11 = pptx.addSlide({ masterName: "MASTER_SLIDE" });
        let slide12 = pptx.addSlide({ masterName: "MASTER_SLIDE" });

        let slide2Elem = document.getElementById("performance_sum_wrap") as HTMLDivElement;
        let slide3Elem = document.getElementById("charts_insight") as HTMLDivElement;
        let slide4Elem = document.getElementById("performance_analysis") as HTMLDivElement;
        let slide5Elem = document.getElementById("top_this_week") as HTMLDivElement;
        let slide6Elem = document.getElementById("top_this_week_sections") as HTMLDivElement;
        let slide7Elem = document.getElementById("break_down_details") as HTMLDivElement;
        let slide8Elem = document.getElementById("channel_breakdown") as HTMLDivElement;
        let slide9Elem = document.getElementById("efficiency_wrap") as HTMLDivElement;
        let slide10Elem = document.getElementById("influencer_breakdown") as HTMLDivElement;
        let slide11Elem = document.getElementById("env_breakdown") as HTMLDivElement;
        let slide12Elem = document.getElementById("actionable_insights_wrap") as HTMLDivElement;
        
        domToPng(bannerRef.current).then(dataUrl => {
          
           

            let bannerImg = dataUrl.split("data:")[1]
            slide.addImage({ data: bannerImg, w: "85%", h: "80%", x: "7.5%", y: "7.5%" })




        })

        domToPng(slide2Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide2.addImage({ data: bannerImg, w: "85%", h: "40%", x: "7.5%", y: "25%" })




            

           

        })
        domToPng(slide3Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide3.addImage({ data: bannerImg, w: "85%", h: "75%", x: "7.5%", y: "15%" })



        })
        domToPng(slide4Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide4.addImage({ data: bannerImg, w: "85%", h: "45%", x: "7.5%", y: "25%" })


        })
        domToPng(slide5Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide5.addImage({ data: bannerImg, w: "85%", h: "60%", x: "7.5%", y: "25%" })


            

        })
        domToPng(slide6Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide6.addImage({ data: bannerImg, w: "85%", h: "40%", x: "7.5%", y: "25%" })


            

        })
        domToPng(slide7Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide7.addImage({ data: bannerImg, w: "85%", h: "50%", x: "7.5%", y: "15%" })


        })
        domToPng(slide8Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide8.addImage({ data: bannerImg, w: "85%", h: "40%", x: "7.5%", y: "25%" })


            

        })

        domToPng(slide9Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide9.addImage({ data: bannerImg, w: "85%", h: "25%", x: "7.5%", y: "25%" })


            

        })

        domToPng(slide10Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide10.addImage({ data: bannerImg, w: "85%", h: "40%", x: "7.5%", y: "25%" })


            
        })

        domToPng(slide11Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide11.addImage({ data: bannerImg, w: "85%", h: "70%", x: "7.5%", y: "15%" })


          

        })
        domToPng(slide12Elem).then(dataUrl => {
           
           

            let bannerImg = dataUrl.split("data:")[1]
            slide12.addImage({ data: bannerImg, w: "85%", h: "90%", x: "7.5%", y: "5%" })


            pptx.writeFile({ fileName: 'test2.pptx' });

        })
    }

    const CopyExternalUrl = () => {
        let shareableLink = `${window.location.origin}/campaign-report/${campaignId}#3e2s3`

        navigator.clipboard.writeText(shareableLink);
        toast.success('Shareable link has been copied');
    }

    const CopyExternalShareableUrl = () => {
        let shareableLink = `${window.location.origin}/campaign-report-mgp-external/${campaignId}#3e2s3`

        navigator.clipboard.writeText(shareableLink);
        toast.success('External Shareable link has been copied');
    }


    const handleScrollToView = () => {

        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start", });
    }



    return (
        <>
            {loader && <Skeleton height={200} count={10} />}
            {!loader && (
                <>
                    {
                        error ? (
                            <ErrorBoundary error={error} />
                        ) : (
                            <div>
                                <div ref={bannerRef} className={isPdfPage ? "pdf-wrap" : ""}>
                                    <ReportBannerSection
                                        campaignId={campaignId}
                                        handleScrollToView={handleScrollToView}
                                        thumbnail={thumbnail}
                                        isExternal={isExternal}
                                    />
                                </div>


                                

                                <div>
                                    <CampaignPerformanceSum isPdfPage={isPdfPage} isNotAmyris={isNotAmyris} setCommentSectionToDisplay={SetCommentSectionToDisplay} commentSectionToDisplay={commentSectionToDisplay} allCommentsData={{ commentsLoading, commentsError, commentsData }} commentsFilter="Campaign Performance Summary" isExternal={isExternal} campaignId={campaignId} scrollRef={scrollRef} />
                                </div>

                                <div className={isPdfPage ? "pdf-wrap" : ""}>
                                    {sectionOptions.includes("Actionable insights") && (
                                        <div>
                                            <Insights isExternal={isExternal} campaignId={campaignId} />
                                        </div>
                                    )}
                                </div>

                                {/* {console.log(sectionOptions)} */}
                                <div className={isPdfPage ? "pdf-wrap" : ""}>
                                    {sectionOptions.includes("Performance Analysis") && (
                                        <div>
                                            <PerformanceAnalyis isPdfPage={isPdfPage} setCommentSectionToDisplay={SetCommentSectionToDisplay} commentSectionToDisplay={commentSectionToDisplay} allCommentsData={{ commentsLoading, commentsError, commentsData }} commentsFilter="Performance Analysis" isExternal={isExternal} campaignId={campaignId} />
                                        </div>
                                    )}

                                    

                                    {sectionOptions.includes("Top Performing Content") && (
                                        <div>
                                            <TopPerformingContent isPdfPage={isPdfPage} campaignId={campaignId} isExternal={isExternal} />
                                        </div>
                                    )}
                                </div>

                                <div className={isPdfPage ? "pdf-wrap" : ""}>

                                    {sectionOptions.includes("Influencer Performance Breakdown") && (
                                        <div>
                                            <InfluencerBreakdown campaignId={campaignId} />
                                        </div>
                                    )}
                                </div>

                                <div className={isPdfPage ? "pdf-wrap" : ""}>
                                    {sectionOptions.includes("Channel Performance") && (
                                        <div>
                                            <ChannelPerformance setCommentSectionToDisplay={SetCommentSectionToDisplay} commentSectionToDisplay={commentSectionToDisplay} allCommentsData={{ commentsLoading, commentsError, commentsData }} commentsFilter="Channel Performance" isExternal={isExternal} campaignId={campaignId} />
                                        </div>
                                    )}


                                    {!isNotAmyris && sectionOptions.includes("Campaign Efficiency") &&
                                        <div className="mt-6">
                                            <CampaignEfficiency setCommentSectionToDisplay={SetCommentSectionToDisplay} commentSectionToDisplay={commentSectionToDisplay} allCommentsData={{ commentsLoading, commentsError, commentsData }} commentsFilter="Campaign Efficiency" isExternal={isExternal} campaignId={campaignId} />
                                        </div>}



                                    {/* <EngagementOverview /> */}


                                    {!isNotAmyris && sectionOptions.includes("Influencer Efficiencies") &&
                                        <div>
                                            <InfluencerEfficiencies isExternal={isExternal} campaignId={campaignId} />
                                        </div>
                                    }
                                </div>
                                <div className={isPdfPage ? "pdf-wrap" : ""}>

                                    {!isNotAmyris && sectionOptions.includes("Earned Media Value") && (
                                        <div>
                                            <EarnedMediaOverview isExternal={isExternal} campaignId={campaignId} />
                                        </div>
                                    )}

                                </div>

                                <div className={isPdfPage ? "pdf-wrap" : ""}>
                                    {sectionOptions.includes("Actionable insights") && (
                                        <div>
                                            <ActionableInsights isExternal={isExternal} campaignId={campaignId} />
                                        </div>
                                    )}
                                </div>


                                {
                                    !isPdfPage && sectionOptions.includes("Links and Attachments") && (
                                        <div>
                                            <Attachments campaignId={campaignId} isExternal={isExternal} />
                                        </div>
                                    )
                                }


                                {
                                    !isExternal &&
                                    <div className="export_ctas" style={{flexWrap: "wrap"}}>
                                        <button onClick={GetPdfDownloadLink} 
                                        style={{ marginLeft: "auto", marginRight: "auto" }}
                                        className={downloadingPdf === false ? "each_export" : "each_export ongoing_now"}>{downloadingPdf === false ? "Download as PDF" : "Downloading..."}</button>
                                        <div className="each_export" style={{ marginLeft: "auto", marginRight: "auto" }}
                                            onClick={ExportPPTReport}>Download as PowerPoint</div>
                                        <div className="each_export" style={{ marginLeft: "auto", marginRight: "auto" }}
                                            onClick={CopyExternalUrl}>Copy internal shareable link</div>
                                        <div className="each_export" style={{ marginLeft: "auto", marginRight: "auto" }}
                                            onClick={CopyExternalShareableUrl}>Copy external shareable link</div>
                                    </div>
                                }


                            </div>
                        )
                    }
                </>
            )
            }
        </>
    )
}

export { CampaignReport }