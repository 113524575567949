import { DataGraph } from "./DataGraph";
import { KeyInsights } from "./KeyInsights";
import { AreaChart } from "./AreaChart";
import { SummaryWidget } from "./SummaryWidget";
import { TopChannels } from "./TopChannels";
import { VerticalBar } from "./VerticalBar";

import { useState, useEffect } from "react"
import { getGraphRes } from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";

import { EditButton } from "../../../../../../_metronic/layout/components/EditButton";
import { Formik, Form, Field, FormikProps } from "formik";
import axios from 'axios';
import { toast } from 'react-toastify'



type Props = {
    campaignId: string,
    scrollRef: any,
    isExternal?: boolean,
    allCommentsData?: any,
    commentsFilter: string,
    setCommentSectionToDisplay: any,
    commentSectionToDisplay: string,
    isNotAmyris?: boolean;
    isPdfPage?: boolean;
}


export const CampaignPerformanceSum = ({ isPdfPage, isNotAmyris, campaignId, scrollRef, isExternal, allCommentsData, commentsFilter, setCommentSectionToDisplay, commentSectionToDisplay }: Props) => {

    const [areaOption, setAreaOption] = useState<string>("impressions")
    const [barOption, setbarOption] = useState<string>("impressions")

    const [areaTab, setAreaTab] = useState<string>("general")
    const [barTab, setbarTab] = useState<string>("general")

    const dispatch = useAppDispatch();

    const range = useAppSelector((state) => state.report.range).toString();

    useEffect(() => {
        dispatch(getGraphRes({
            campaign_id: campaignId,
            is_external: isExternal
        }));

    }, [campaignId, range, isExternal]);

    const areaOptionHandler = (option: string) => {
        
        setAreaOption(option)
    }
    const barOptionHandler = (option: string) => {
        setbarOption(option)
    }

    const areaTabHandler = (option: string) => {
        setAreaTab(option)
        
    }
    const barTabHandler = (option: string) => {
        setbarTab(option)
    }

    // console.log(" is pdpdf", isPdfPage)
    let { commentsLoading, commentsData } = allCommentsData;
    let filteredComments = !commentsLoading && commentsData ? commentsData.filter((eachComment: any) => eachComment?.tab_name === commentsFilter) : []
    return (
        <div ref={scrollRef} className="container-fluid p-0" style={{ marginBottom: 40, marginTop: 0 }}>
            <div className={isPdfPage ? "pdf-wrap" : ""}>
                <SummaryWidget isNotAmyris={isNotAmyris} isPdfPage={isPdfPage} 
                setCommentSectionToDisplay={setCommentSectionToDisplay} 
                commentSectionToDisplay={commentSectionToDisplay} commentsData={filteredComments} 
                commentsFilter={commentsFilter} isExternal={isExternal} campaignId={campaignId} />

            <div className={`row ${isExternal ? "" : "container"} Helvetica-Neue`} 
                style={{ marginTop: "24px", padding:"0px", marginLeft:"auto", marginRight:"auto" }}>

                    {!isPdfPage && <TopChannels />}

                    <div id="charts_insight" className={!isPdfPage ? "col-9 px-10" : "col-12 px-10"}>
                        <div className="row" style={{ gap: 20, flexWrap: "nowrap" }}>
                            <div className="col-6 bg-white p-3" style={{ borderRadius: 20 }}>
                                <DataGraph 
                                pointsOptions={["Impressions", "Reach", "Engagements", "Clicks"]} 
                                optionHandler={areaOptionHandler}
                                tabHandler={areaTabHandler}>
                                    <AreaChart isExternal={isExternal} campaignId={campaignId} option={areaOption} tab={areaTab} />
                                </DataGraph>
                            </div>


                            <div className="col-6 bg-white p-4" style={{ borderRadius: 20 }}>
                                <DataGraph 
                                pointsOptions={["Impressions", "Reach", "Engagements", "Clicks"]} 
                                optionHandler={barOptionHandler}
                                tabHandler={barTabHandler}>
                                    <VerticalBar isExternal={isExternal} campaignId={campaignId} option={barOption} tab={barTab} />
                                </DataGraph>
                            </div>
                        </div>



                        <div className="row">
                            <KeyInsights campaignId={campaignId} isExternal={isExternal} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}