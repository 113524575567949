import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from "react-router-dom"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import Pikaday from 'pikaday'

import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import clsx from 'clsx'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { HeaderUserMenu } from '../../../../../_metronic/partials'
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Modal from "react-bootstrap/Modal";

import "pikaday/css/pikaday.css";
import "react-datepicker/dist/react-datepicker.css";
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

const sorter = (a: any, b: any) => {
    if (typeof a?.name === 'number' && typeof b?.name === 'number') {
        return a - b;
    } else if (typeof a?.name === 'number' && typeof b?.name !== 'number') {
        return -1;
    } else if (typeof a?.name !== 'number' && typeof b?.name === 'number') {
        return 1;
    } else {
        return a?.name > b?.name ? 1 : -1;
    }
}

const DateFilterModal = (props: any) => {
    let { showModal, handleShowModal, startDate, dateFilter,
        setBothDateChosen, setStartDate, dateOptionsChange,
        endDate, onChange, onChangeCompare, setEndDate,
        compareFilter, setCompareFilter,
        currentFromTxt, setCurrentFromTxt,
        currentEndTxt, setCurrentEndTxt,
        compareStartTxt, setCompareStartTxt,
        compareEndTxt, setCompareEndTxt,
        seStartDateToCompare, setEndDateToCompare, confirmDateFilter,
        startDateToCompare, endDateToCompare, setDateRangeText } = props;
    const compareRef = useRef<any>();
    const currentSelectionRef = useRef<any>();
   

    useEffect(() => {
        

        
        let currentFromTxtTemp = `${(new Date(startDate).getDate())}/${((new Date(startDate).getMonth()) + 1)}/${(new Date(startDate).getFullYear())}`
        setCurrentFromTxt(currentFromTxtTemp)

        let currentEndTxtTemp = `${(new Date(endDate).getDate())}/${((new Date(endDate).getMonth()) + 1)}/${(new Date(endDate).getFullYear())}`
        setCurrentEndTxt(currentEndTxtTemp)



        let compareFromTxtTemp = `${(new Date(startDateToCompare).getDate())}/${((new Date(startDateToCompare).getMonth()) + 1)}/${(new Date(startDateToCompare).getFullYear())}`
        setCompareStartTxt(compareFromTxtTemp)


        let compareEndTxtTemp = `${(new Date(endDateToCompare).getDate())}/${((new Date(endDateToCompare).getMonth()) + 1)}/${(new Date(endDateToCompare).getFullYear())}`
        setCompareEndTxt(compareEndTxtTemp)


        
    }, [])



    // console.log(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.test('01/1/2222'))
    // console.log(/^(\d{1-12})\/(\d{1-31})\/(\d{4})$/.test('01/1/2222'))

    //    console.log(/^[0-3]?[1-9]\/[0-3]?[1-9]\/(?:[1-9]{2})[0-9]{2}$/.test('01/01/29'))


    const onCompareChange = () => {

    }



    return (

        <Modal
            show={showModal}
            onHide={handleShowModal}
            dialogClassName="date_modal"
            className="modaltwo"

        >
            <div className="dates_to_compare">
                <div className="each_range current_range">
                    <div className="date_options">
                        <div className="options_label">
                            <span className="ticker"></span>
                            Date Range:
                        </div>
                        <select name="" id="" onChange={(e: any) => {
                            dateOptionsChange(parseInt(e.target.value))
                        }} value={dateFilter}>
                            <option value={1}>Last Month</option>
                            <option value={2}>Last Quarter</option>
                            <option value={3}>Last Week</option>
                            <option value={4}>Custom Date</option>
                        </select>
                    </div>
                    <div className="from_date">
                        <label htmlFor="">from</label>
                        <input type="text" value={currentFromTxt} onChange={(e: any) => {

                        }} />
                    </div>
                    <div className="to_date">
                        <label htmlFor="">to</label>
                        <input type="text" value={currentEndTxt} onChange={(e: any) => {
                            
                        }} />
                    </div>
                </div>
                <div className="each_range compare_range">
                    <div className="date_options">
                        <div className="options_label">
                            <span className="ticker"></span>
                            Compare To:
                        </div>
                        <select name="" id="" value={compareFilter}
                            onChange={(e: any) => {
                                setCompareFilter(e.target.value)
                            }}
                        >
                            <option value={1}>Previous Period</option>
                            <option value={2}>Custom</option>
                        </select>
                    </div>
                    <div className="from_date">
                        <label htmlFor="">from</label>
                        <input type="text" value={compareStartTxt} onChange={(e: any) => {
                            
                        }} />
                    </div>
                    <div className="to_date">
                        <label htmlFor="">to</label>
                        <input type="text" value={compareEndTxt} onChange={(e: any) => {
                            
                        }} />
                    </div>
                </div>
            </div>
            <div className="all_dates_wrap">
                <div className="comparison_wrap">
                    <div className="compare_period">
                        <DatePicker
                            selected={startDateToCompare}
                            onChange={onChangeCompare}

                            open={true}
                            placeholderText=" "
                            dateFormat="dd/MM/yy"
                            maxDate={new Date()}
                            monthsShown={1}
                            selectsRange={true}
                            startDate={startDateToCompare}
                            endDate={endDateToCompare}
                            peekNextMonth={false}
                            showPreviousMonths={false}
                        
                        />
                        {/* <input type='text' ref={compareRef} /> */}
                    </div>
                    <div className="current_period">
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            open={true}
                            placeholderText=" "
                            dateFormat="dd/MM/yy"
                            maxDate={new Date()}
                            monthsShown={1}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            peekNextMonth={false}
                            showPreviousMonths={false}
                        
                        />
                        {/* <input type='text' ref={currentSelectionRef} /> */}
                    </div>
                </div>
            </div>
            <div className="footer_wrap">
                <button className="cancel_btn" onClick={handleShowModal}>Cancel</button>
                <button className="apply_btn" onClick={confirmDateFilter}>Apply</button>
            </div>


        </Modal>


    )

}

export default function SocialListingHeader(props: any) {

    let today = new Date();
    const { pathname } = useLocation()

    let { setSectionInView, startDateToCompare, endDateToCompare,
        startDate, setStartDate, endDate, setEndDate, chosenBrand,
        setChosenBrand, brandsList, setBothDateChosen, setFetchingData,
        returnComparedDates, getDateDiff,
        dateRangeText, setDateRangeText,

        currentFromTxt, setCurrentFromTxt,
        currentEndTxt, setCurrentEndTxt,
        compareStartTxt, setCompareStartTxt,
        compareEndTxt, setCompareEndTxt,

        dateFilter, setDateFilter, compareFilter, setCompareFilter,
        seStartDateToCompare, setEndDateToCompare,
        setDateFilterOption, isExternal, dateFilterOption } = props


    const [dateWasChosen, setDateWasChosen] = useState<boolean>(false);
    const [isPickerShown, setShowDatePicker] = useState<boolean>(true)
    const [isDateFilterOpen, setIsDateFilterOpen] = useState<boolean>(false)
    const [sortedBrands, setSortedBrands] = useState<any[]>([])
    
    const [dateOption, setDateOption] = useState<any>()
    
    const dateFilterRef = useRef<any>();

    const [showModal, setShowModal] = useState<boolean>(false);


    
    

    
    

    const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
        toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';



    
    
    
    
    

    
    

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [pathname])

    useEffect(() => {
        if (!isExternal) {

            let allBrands: any[] = [...brandsList];
            allBrands.sort(sorter)
            setSortedBrands(allBrands)
        }


    }, []);

   

    const confirmDateFilter = () => {
       
        if (startDate && endDate) {
            if (dateFilter === 2) {
                setDateRangeText("Last Month")
            }
            if (dateFilter === 2) {
                setDateRangeText("Last Quarter")
            }
            if (dateFilter === 3) {
                setDateRangeText("Last Week")
            }
            if (dateFilter === 4) {

                let currentFromTxtTemp = `${(new Date(startDate).getDate())}/${((new Date(startDate).getMonth()) + 1)}/${(new Date(startDate).getFullYear())}`


                let currentEndTxtTemp = `${(new Date(endDate).getDate())}/${((new Date(endDate).getMonth()) + 1)}/${(new Date(endDate).getFullYear())}`

                setDateRangeText(`${currentFromTxtTemp} - ${currentEndTxtTemp} `)
            }

            setBothDateChosen(true)
        }
    }



    const onChange = (dates: any) => {
        const [start, end] = dates;
        dateOptionsChange(4)
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            let currentFromTxtTemp = `${(new Date(start).getDate())}/${((new Date(start).getMonth()) + 1)}/${(new Date(start).getFullYear())}`
            setCurrentFromTxt(currentFromTxtTemp)

            let currentEndTxtTemp = `${(new Date(end).getDate())}/${((new Date(end).getMonth()) + 1)}/${(new Date(end).getFullYear())}`
            setCurrentEndTxt(currentEndTxtTemp)
            setBothDateChosen(false)
        }
      


    };

    const onChangeCompare = (dates: any) => {
        const [start, end] = dates;

        dateOptionsChange(4)

        setCompareFilter(2)
        seStartDateToCompare(start);
        setEndDateToCompare(end);




        if (start && end) {

            let compareFromTxtTemp = `${(start.getDate())}/${((start.getMonth()) + 1)}/${(start.getFullYear())}`
            setCompareStartTxt(compareFromTxtTemp)


       
            let compareEndTxtTemp = `${(end.getDate())}/${((end.getMonth()) + 1)}/${(end.getFullYear())}`
            setCompareEndTxt(compareEndTxtTemp)

            setBothDateChosen(false)
        }

    };




    const dateConverter = (dateString: any) => {
        var dateParts = dateString.split("/");

        
        var dateObject = new Date(Number(`${dateParts[2]}`), dateParts[1] - 1, +dateParts[0]);
        return dateObject.toISOString();
    }

    const dateOptionsChange = (option: any) => {
        setBothDateChosen(false)
        
        setDateFilter(option)
      
        if (option === 1) {
            let lastMonthStart = moment(new Date()).subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
            let lastMonthEnd = moment(new Date()).subtract(1, 'months').endOf('month').format('DD/MM/YYYY');

            let previousMonthStartToCompare = moment(new Date()).subtract(2, 'months').startOf('month').format('DD/MM/YYYY');
            let previousMonthEndToCompare = moment(new Date()).subtract(2, 'months').endOf('month').format('DD/MM/YYYY');



            
            setCurrentFromTxt(lastMonthStart)
            setCurrentEndTxt(lastMonthEnd)
            setCompareStartTxt(previousMonthStartToCompare)
            setCompareEndTxt(previousMonthEndToCompare)
            setStartDate(moment(dateConverter(lastMonthStart)).toDate());
            setEndDate(moment(dateConverter(lastMonthEnd)).toDate());


            seStartDateToCompare(moment(dateConverter(previousMonthStartToCompare)).toDate());
            setEndDateToCompare(moment(dateConverter(previousMonthEndToCompare)).toDate());

            setDateFilterOption("Last Month");
            


            setDateWasChosen(true)

            

            
        }

        if (option === 2) {
            let lastMonthStart = moment(new Date()).subtract(1, 'quarter').startOf('quarter').format('DD/MM/YYYY');
            let lastMonthEnd = moment(new Date()).subtract(1, 'quarter').endOf('quarter').format('DD/MM/YYYY');


            let previousMonthStartToCompare = moment(new Date()).subtract(2, 'quarter').startOf('quarter').format('DD/MM/YYYY');
            let previousMonthEndToCompare = moment(new Date()).subtract(2, 'quarter').endOf('quarter').format('DD/MM/YYYY');

            
            

            
            


            setCurrentFromTxt(lastMonthStart)
            setCurrentEndTxt(lastMonthEnd)
            setCompareStartTxt(previousMonthStartToCompare)
            setCompareEndTxt(previousMonthEndToCompare)

            setStartDate(moment(dateConverter(lastMonthStart)).toDate());
            setEndDate(moment(dateConverter(lastMonthEnd)).toDate());

            seStartDateToCompare(moment(dateConverter(previousMonthStartToCompare)).toDate());
            setEndDateToCompare(moment(dateConverter(previousMonthEndToCompare)).toDate());


            setDateFilterOption("Last Quarter");

            setDateWasChosen(true)


            

            
        }

        if (option === 3) {
            

            let lastMonthStart = moment(new Date()).subtract(1, 'week').startOf('week').format('DD/MM/YYYY');
            let lastMonthEnd = moment(new Date()).subtract(1, 'week').endOf('week').format('DD/MM/YYYY');


            let previousMonthStartToCompare = moment(new Date()).subtract(2, 'week').startOf('week').format('DD/MM/YYYY');
            let previousMonthEndToCompare = moment(new Date()).subtract(2, 'week').endOf('week').format('DD/MM/YYYY');

            

            setCurrentFromTxt(lastMonthStart)
            setCurrentEndTxt(lastMonthEnd)
            setCompareStartTxt(previousMonthStartToCompare)
            setCompareEndTxt(previousMonthEndToCompare)


            setStartDate(moment(dateConverter(lastMonthStart)).toDate());
            setEndDate(moment(dateConverter(lastMonthEnd)).toDate());

            seStartDateToCompare(moment(dateConverter(previousMonthStartToCompare)).toDate());
            setEndDateToCompare(moment(dateConverter(previousMonthEndToCompare)).toDate());


            
            setDateFilterOption("Last Week");
            

            setDateWasChosen(true)

            
        }

        if (option === 4) {
            

            setDateFilterOption("custom");

        }


    }

    const setPreviousRangeFromCuurent = (startDate: any, endDate: any) => {
        let dateDiff = getDateDiff(startDate, endDate) + 1;
        let { startDateNew, endDateNew } = returnComparedDates(startDate, endDate, dateDiff)
        seStartDateToCompare(startDateNew)
        setEndDateToCompare(endDateNew)
        
    }

    const handleShowModal = () => {
        setShowModal((prev) => !prev);

    }


    return (
        <>
            <div className="social_listing_header">
                <Link to="#" className="s_l_logo">
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/social-listing.png')}
                    />
                </Link>
                <div className="s_l_heading">
                    <div className="s_l_headleft">
                        <div className="heading_nav_items">
                            <ScrollLink className="each_head_nav" to="brand-summary" spy={true} smooth={true} duration={500}>
                                Brand Summary
                            </ScrollLink>
                            <ScrollLink className="each_head_nav" to="benchmark-wrap" spy={true} smooth={true} duration={500}>
                                Competitor Benchmark
                            </ScrollLink>
                            <ScrollLink className="each_head_nav" to="content-analysis" spy={true} smooth={true} duration={500}>
                                Content Analysis
                            </ScrollLink>

                        </div>
                    </div>
                    <div className={isExternal ? "s_l_headright with_full" : "s_l_headright"}>
                        {showModal && <DateFilterModal
                            startDate={startDate}
                            dateFilter={dateFilter}
                            onChange={onChange}
                            currentFromTxt={currentFromTxt}
                            setCurrentFromTxt={setCurrentFromTxt}
                            currentEndTxt={currentEndTxt}
                            setCurrentEndTxt={setCurrentEndTxt}
                            compareStartTxt={compareStartTxt}
                            setCompareStartTxt={setCompareStartTxt}
                            compareEndTxt={compareEndTxt}
                            setCompareEndTxt={setCompareEndTxt}
                            compareFilter={compareFilter}
                            setCompareFilter={setCompareFilter}
                            confirmDateFilter={confirmDateFilter}
                            setDateRangeText={setDateRangeText}
                            onChangeCompare={onChangeCompare}
                            dateOptionsChange={dateOptionsChange}
                            setBothDateChosen={setBothDateChosen}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            startDateToCompare={startDateToCompare}
                            endDateToCompare={endDateToCompare}
                            setStartDate={setStartDate}
                            seStartDateToCompare={seStartDateToCompare}
                            setEndDateToCompare={setEndDateToCompare}
                            showModal={showModal}
                            handleShowModal={handleShowModal}
                        />}
                        <div className="brand_n_date">
                            {!isExternal &&
                                <div className="brand_option">
                                    <select name="" id=""
                                        value={chosenBrand?.id}
                                        
                                        onChange={(e) => {
                                            let selectedBrand = sortedBrands.filter((eachItem: any) => eachItem.id == e.target.value)[0]
                                            setChosenBrand(selectedBrand)

                                        }}

                                    >
                                        {
                                            sortedBrands.map((eachBrand: any, index: any) => {
                                                return (
                                                    <option value={eachBrand?.id} key={index}>{eachBrand.name}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>
                            }
                            {isExternal &&
                                <div className="brand_option">
                                    <div className="brand_name_txt">{chosenBrand?.name}</div>
                                </div>
                            }
                            <div className="date_range_wrap"
                                onClick={handleShowModal}
                            >
                                {/* <div className={!isPickerShown ? "date_range_label" : "date_range_label shifted"}>Date Range</div> */}

                                <div className="date_selected"

                                >
                                    <div>
                                        <div className="selected_date_txt">{dateRangeText}</div>

                                    </div>



                                </div>



                            </div>
                        </div>
                        {!isExternal &&
                            <div className={`usr_menu_ico ${clsx('d-flex align-items-center', toolbarButtonMarginClass)}`}
                                id='kt_header_user_menu_toggle'
                            >
                                <div className={`user_ico ${clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}`}
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-attach='parent'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='bottom'
                                >

                                    <img
                                        alt='User'
                                        src={toAbsoluteUrl('/media/icons/socials/user_blue.png')}
                                    />
                                </div>
                                <HeaderUserMenu />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}