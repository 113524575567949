import React from 'react'
import LargeModal from "../../../../../_metronic/layout/components/modal/LargeModal";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Link } from "react-router-dom"
import ModalBody from "react-bootstrap/ModalBody";
import Modal from "react-bootstrap/Modal";
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

const RoundUpNumber = ({ number, hasDecmals }: any) => {



    if (number) {
        let valToUse = number.toFixed(0);

        if (number >= 1000000) {

            valToUse = `${Number(number / 1000000).toFixed(0)}`;
            return (
                <div className="value_txt">{valToUse}<span className="value_unit">m</span></div>
            )
        }
        if (number >= 1000) {
            valToUse = `${Number(number / 1000).toFixed(0)}`;
            return (
                <div className="value_txt">{valToUse}<span className="value_unit">k</span></div>
            )
        }

        if (hasDecmals) {
            valToUse = `${Number(number).toFixed(2)}`

            return (
                <div className="value_txt">{valToUse}</div>
            )
        }


        return (
            <div className="value_txt">{valToUse}</div>
        )
    }

    return (
        <div className="value_txt"></div>
    )

}

const getDateFromISO = ({ date, returnTime }: { date: any, returnTime?: boolean }) => {
    // if (date === null) return "";
    let toUse = new Date(date),
        year = toUse.getFullYear(),
        month: any = toUse.getMonth() + 1,
        dt: any = toUse.getDate();

    // if (dt < 10) {
    //     dt = '0' + dt;
    // }
    // if (month < 10) {
    //     month = '0' + month;
    // }
    let monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]


    let convertedDate = `${monthList[month+1]} ${dt}, ${year}`;
    // let convertedDate = `${dt}-${month}-${year}`;
    // let convertedDate = toUse.toUTCString().split(' ').slice(0, 4).join(' ');
    let convertedTime = '';
    if (returnTime === true && date.indexOf('T') === -1) {
        convertedTime = date.replace(/^[^:]*([0-2]\d:[0-5]\d).*$/, "$1");
        return convertedTime;
    }
    if (returnTime === true && date.indexOf('T') > -1) {
        convertedTime = date.replace(/^[^:]*([0-2]\d:[0-5]\d).*$/, "$1");

        let convertedDateAndTime = `${convertedDate} ${convertedTime}`;
        return convertedDateAndTime;
    }

    return convertedDate;


}
export default function ExpandedPostDetails(props: any) {
    let { showModal, handleShowModal, postDetails } = props;

    let socialIcon: any;
    if (postDetails?.channel === "instagram") {
        socialIcon = toAbsoluteUrl('/media/icons/socials/ig.svg')
    }
    if (postDetails?.channel === "tiktok") {
        socialIcon = toAbsoluteUrl('/media/icons/socials/tiktok.svg')
    }
    if (postDetails?.channel === "youtube") {
        socialIcon = toAbsoluteUrl('/media/icons/socials/yt_main.svg')
    }
    if (postDetails?.channel === "facebook") {
        socialIcon = toAbsoluteUrl('/media/icons/socials/fb.svg')
    }
    if (postDetails?.channel === "twitter") {
        socialIcon = toAbsoluteUrl('/media/icons/socials/tw.svg')
    }

    
    return (
    
        <Modal
            show={showModal}
            onHide={handleShowModal}
            dialogClassName="post_info_modal"

        >
            <ModalHeader>Post Details </ModalHeader>
            <ModalBody>
                <div className="post_info">
                    <div className="post_meta_info">
                        <div className="channel_name">
                            <img src={socialIcon} alt="" className="channel_icon" />
                        </div>
                        <div className="content_type">{postDetails?.type} </div>
                    </div>
                    <div className="post_main_info">
                        <div className="brand_name">{postDetails?.companyName} </div>
                        <div className="post_date">{postDetails?.publishedAt ? getDateFromISO({ date: postDetails?.publishedAt, returnTime: true }) : "N/A"} </div>
                        <div className="post_thumb">
                            <img className="post_thumb_pic" src={postDetails?.image} alt="" />
                        </div>
                        <div className="post_msg">
                            {postDetails?.message}
                        </div>
                        <Link to={{ pathname: postDetails?.postLink }} target="_blank" className="link_txt">
                            <img src={toAbsoluteUrl('/media/cpg/external-link.png')} alt="" className="link_icon" />
                            View on <span>{postDetails?.channel} </span>
                        </Link>
                    </div>
                    <div className="post_metrics_info">
                        <div className="each_metric">
                            <div className="metric_wrap">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><div className="metric_count"><RoundUpNumber number={postDetails?.engagementTotal} />  </div></td>
                                            <td><div className="metric_title">ENGAGEMENT TOTAL</div></td>
                                        </tr>
                                        <tr>
                                            <td><div className="metric_count_sub"><RoundUpNumber number={postDetails?.applause} />  </div></td>
                                            <td><div className="metric_title_sub">LIKES</div></td>
                                        </tr>
                                        <tr>
                                            <td><div className="metric_count_sub"><RoundUpNumber number={postDetails?.conversation} />  </div></td>
                                            <td><div className="metric_title_sub">COMMENTS</div></td>
                                        </tr>
                                        <tr>
                                            <td><div className="metric_count_sub"><RoundUpNumber number={postDetails?.amplification} />  </div></td>
                                            <td><div className="metric_title_sub">RE-POSTS</div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                        <div className="each_metric">
                            <div className="metric_wrap">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><div className="metric_count"> <RoundUpNumber number={postDetails?.followers} />  </div></td>
                                            <td><div className="metric_title">FOLLOWERS</div></td>
                                        </tr>
                                    </tbody>
                                </table>


                            </div>
                        </div>
                        <div className="each_metric">
                            <div className="metric_wrap">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><div className="metric_count"> <RoundUpNumber number={postDetails?.views} />  </div></td>
                                            <td><div className="metric_title">VIEWS</div></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className="each_metric">
                            <div className="metric_wrap">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><div className="metric_count"> <RoundUpNumber hasDecmals={true} number={postDetails?.engagementRate} />  </div></td>
                                            <td><div className="metric_title">ENGAGEMENT RATE</div></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className="each_metric">
                            <div className="metric_wrap">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><div className="metric_count"> <RoundUpNumber number={postDetails?.presenceReach} />  </div></td>
                                            <td><div className="metric_title">REACH</div></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>

        // </LargeModal>
    )
}