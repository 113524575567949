import { ApexOptions } from "apexcharts";
import { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { CommentBox } from "../../../../../../_metronic/layout/components/CommentBox";
import Skeleton from "react-loading-skeleton";
import { getPerformanceAnalysis } from "../../../../../../setup/features/report.slice";
// import { getPATopPerformingRes } from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { Formik, Form, Field, FormikProps } from "formik";
import axios from 'axios';
import { toast } from 'react-toastify'
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";


import { ErrorBoundary } from "../../../../../sharedComponents/ErrorBoundary"
import { EditButton } from "../../../../../../_metronic/layout/components/EditButton";
import { CommentListPopUp } from "../components/CommentBox";

const customColors = {
    highGreen: "#06D6A0",
    inactiveGrey: "#F4F4F4",
    lowRed: "#FF0000",
    avgYellow: "#FFDD55",
}

function getProgressColor(threshold: number) {

    if (threshold < 50) {
        return customColors.lowRed;
    } else if (threshold >= 50 && threshold <= 69) {
        return customColors.avgYellow;
    } else {
        return customColors.highGreen;
    }
}

function getThreshold(currentVal: number, targetVal: number) {
    if (targetVal === 0) return currentVal;
    return Math.floor((currentVal / targetVal) * 100);
}

type Props = {
    current: number;
    required: number;
    week?: number;
    earnedPost?: number;
    name: string;
    icon?: string;
    isPdfPage?: boolean;
    edited:boolean
}


const PerformanceChartItem = ({ current, required, week, earnedPost, name, icon, edited }: Props) => {
    const threshold = getThreshold(current, required);

    const progressColor = getProgressColor(threshold);

    const options: ApexOptions = {
        colors: [progressColor],
        plotOptions: {
            radialBar: {
                offsetY: 0,
                inverseOrder: true,
                track: {
                    background: customColors.inactiveGrey,
                },
                hollow: {
                    size: "30%"
                },
                dataLabels: {
                    show: false,
                },
            },
        },
        stroke: {
            lineCap: "round",
            width: 30
        },
        grid: {
            padding: {
                right: 0,
                left: 0
            }
        }
    }
    

    return (
        <div className="d-flex flex-row align-items-center">
            <Chart
                type="radialBar"
                options={options}
                series={[threshold]}
                width={120}
                height={180}
            />

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                <div
                    style={{
                        display: "flex",
                        height: "fit-content"
                    }}
                >
                    <p 
                        className="Helvetica-Neue-medium"
                        style={{
                            fontSize: 32,
                            fontWeight: 600,
                            color: `${edited ? "#404040":"#000"}`,
                            margin: "0 8px 0 0"
                        }}
                    >{current}</p>
                    <p
                        className="Helvetica-Neue-normal"
                        style={{
                            alignSelf: "end",
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#000",
                            opacity: 0.6,
                            margin: 0
                        }}
                    >
                        /{required}
                    </p>

                </div>

                <p className="m-0 p-0 Helvetica-Neue-light" 
                style={{ color: "#000", fontWeight: 300, fontSize: "18px" }}>
                    {name}
                </p>
                <div className="d-flex align-items-center mt-2">
                    <div className="d-flex align-items-center" style={{ paddingRight: "4px" }}>
                        <p className="mr__2 Helvetica-Neue-medium text_green text__20 "
                            style={{ margin: 0, marginRight: "0.5rem", lineHeight: "0.2" }}>+{week}</p>
                        <img
                            src={toAbsoluteUrl(`/media/cpg/${icon}.png`)}
                            alt="icon" style={{
                                width: "19px",
                                height: "19px"
                            }} />
                    </div>
                    {name === "Posts Live" &&
                        <div className="d-flex align-items-center pl-1"
                            style={{ borderLeft: "3px solid #8C8C8C", paddingLeft: "3px" }}>
                            <p className="mr__2 Helvetica-Neue-medium text_green text__20 "
                                style={{ margin: 0, marginRight: "0.5rem", lineHeight: "0.2" }}>+{earnedPost || 0}</p>
                            <img
                                src={toAbsoluteUrl(`/media/cpg/badge-dollar.png`)}
                                alt="icon" style={{
                                    width: "19px",
                                    height: "19px"
                                }} />
                        </div>}
                </div>
            </div>
        </div>
    )
}

const analysisArray = ["Posts Live", "Influencers Live", "TikTok", "Reels", "Stories", "Carousel"]
const iconsArray = ["posts-icon", "influencer-icon", "tiktok-icon", "reels-icon", "story-icon", "carousel-icon"]
const formArray = ["post", "influencers", "tiktok", "reels", "stories", "carousel"]

const API_URL = process.env.REACT_APP_API_URL;


export const PerformanceAnalyis = ({isPdfPage, campaignId, isExternal, allCommentsData, commentsFilter, commentSectionToDisplay, setCommentSectionToDisplay }: { campaignId: string, isExternal?: boolean, allCommentsData?: any, commentsFilter: string, setCommentSectionToDisplay: any, commentSectionToDisplay: any, isPdfPage?:boolean }) => {

    const { data: PAdata, loading, error } = useAppSelector((state) => state.report.performanceAly);

    const data = PAdata?.data || null

    const range = useAppSelector((state) => state.report.range);

    const [edit, setEdit] = useState<boolean>(true)

    const dispatch = useAppDispatch();

    const formikRef = useRef<FormikProps<any>>(null);

    const initialValues: any = {
        postCurrent: data?.postsLive?.current || 0,
        influencersCurrent: data?.influencersLive?.current || 0,
        tiktokCurrent: data?.tiktok?.current || 0,
        reelsCurrent: data?.reels?.current || 0,
        storiesCurrent: data?.stories?.current || 0,
        carouselCurrent: data?.carousel?.current || 0,
        // postWeek: data?.postsLive?.week || 0,
        // influencersWeek: data?.influencersLive?.week|| 0,
        // tiktokWeek: data?.tiktok?.week || 0,
        // reelsWeek: data?.reels?.week || 0,
        // storiesWeek: data?.stories?.week || 0,
        // carouselWeek: data?.carousel?.week || 0,
        postRequired: data?.postsLive?.total || 0,
        influencersRequired: data?.influencersLive?.total || 0,
        tiktokRequired: data?.tiktok?.total || 0,
        reelsRequired: data?.reels?.total || 0,
        storiesRequired: data?.stories?.total || 0,
        carouselRequired: data?.carousel?.total || 0
    };

    useEffect(() => {
        dispatch(getPerformanceAnalysis({
            campaign_id: campaignId,
            is_external: isExternal,
            date_range: range.toString()
        }));
    }, [range, campaignId])

    const handleSubmit = async (values: any) => {

        function isKeyWithValueEmpty(obj: { [key: string]: string }): boolean {
            for (const key in obj) {
              if (obj.hasOwnProperty(key) && obj[key] === '') {
                return true; // Found a key with an empty string value
              }
            }
            return false; // No key with an empty string value found
          }
        
        // handle form submission 
        if (values !== initialValues ) {
            let data = {}
            if(!isKeyWithValueEmpty(values)){
                data = {
                    "postsLive": {
                        "current": values?.postCurrent,
                        "total": values?.postRequired
                    },
                    "influencersLive": {
                        "current": values?.influencersCurrent,
                        "total": values?.influencersRequired
                    },
                    "tiktok": {
                        "current": values.tiktokCurrent,
                        "total": values.tiktokRequired
                    },
                    "reels": {
                        "current": values.reelsCurrent,
                        "total": values.reelsRequired
                    },
                    "stories": {
                        "current": values.storiesCurrent,
                        "total": values.storiesRequired
                    },
                    "carousel": {
                        "current": values.carouselCurrent,
                        "total": values.carouselRequired
                    }
                }
            }


            try {
                const response = await toast.promise(axios.post(`${API_URL}api/new-reports/edit-campaign?tab_name=performance_analysis&campaign_id=${campaignId || "000000"}&range=${range||"7"}`, data), {
                    pending: 'Updating campaign efficiency',
                    success: ` updated campaign successfully!`,
                    error: `Error updating: try again later`
                });


                if (response.status === 200) {
                    dispatch(getPerformanceAnalysis({
                        campaign_id: campaignId,
                        is_external: isExternal,
                        date_range: range.toString()
                    }));
                }
            }
            catch (error) {
                console.error('Error updating value:', error);
            }
        }

    };

    const handleResetButton = async()=>{
        
        try {
            await toast.promise(axios.delete(`${API_URL}api/new-reports/delete-campaign-edits?campaign_id=${campaignId || "000000"}&range=${range||"7"}&tab_name=performance_analysis`), {
                pending: 'Retrieving original stats data',
                success: `Retrieval successfully!`,
                error: `Error retrieving: No edited values`
            }).then(res => {
                dispatch(getPerformanceAnalysis({
                    campaign_id: campaignId,
                    is_external: isExternal,
                    date_range: range.toString()
                }))
            }).catch(err => console.log(err))
        }
        catch (error) {
            console.error('Error retrieving | no data to reset:', error);
        }
    }

    let { commentsLoading, commentsData } = allCommentsData;
    let filteredComments = !commentsLoading && commentsData ? commentsData.filter((eachComment: any) => eachComment?.tab_name === commentsFilter) : []

    return (
        
            <div id="performance_analysis" className={isPdfPage?"px-10 section_wrap_content mt-0": "px-10 pt-10 section_wrap_content"} style={{ backgroundColor: "#fff", borderRadius: 20, marginTop: 40 }}>
                {/* header */}
                <div className="d-flex flex-row align-items-center">
                    <div style={{ flexGrow: 1, textAlign: "center" }}>
                        <h2 className="NeueMontreal-bold text-align-center p-0 m-0" style={{ fontSize: "28px" }}>
                            Performance Analysis To Date
                        </h2>
                    </div>

                    <div className="d-flex flex-row align-items-center" style={{ gap: 20 }}>
                        {!isExternal && (
                            <>
                                {
                                    edit ? <EditButton handleClick={() => setEdit(false)} /> :
                                        (
                                            <div className="d-flex align-items-center justify-content-around">
                                                <button className="button__reset__default Button border-none rounded p-3 mr__2" onClick={() => {
                                                    setEdit(true)
                                                    handleResetButton()
                                                }}>
                                                    Reset
                                                </button>
                                                <button className="button__delete__default Button border-none rounded p-3 mr__2" onClick={() => {
                                                    setEdit(true)
                                                }}>
                                                    Cancel
                                                </button>
                                                <EditButton text="Save" handleClick={() => {
                                                    formikRef?.current?.submitForm();
                                                    setEdit(true)
                                                }} />
                                            </div>
                                        )
                                }
                            </>
                        )}

                        <CommentBox commentSectionToDisplay={commentsFilter} setCommentSectionToDisplay={setCommentSectionToDisplay} comments={[...filteredComments]} />
                        {(commentSectionToDisplay === commentsFilter) && <CommentListPopUp isExternal={isExternal} campaignId={campaignId} setCommentSectionToDisplay={setCommentSectionToDisplay} commentFilter={commentsFilter} allComments={filteredComments} />}
                    </div>
                </div>

                {loading && (
                    <Skeleton height={96} />
                )}

                {!loading && (
                    <>

                        {(error || data === null || Object.keys(data!).length === 0) ? (
                            <ErrorBoundary error={error} />
                        ) :
                            edit ?
                                (
                                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center py-10 pt-2">
                                        {data ? Object.keys(data)?.map((d, index) => (
                                            <PerformanceChartItem
                                                key={index}
                                                current={data[d as keyof typeof data]?.current || 0}
                                                required={data[d as keyof typeof data]?.total || 0}
                                                week={data[d as keyof typeof data]?.week || 0}
                                                earnedPost={data[d as keyof typeof data]?.earnedPost || 0}
                                                name={analysisArray[index] || ""}
                                                icon={iconsArray[index] || ""}
                                                edited={PAdata?.edited || false}
                                            />
                                        )) : (
                                            analysisArray.map((d, index) => (
                                                <PerformanceChartItem
                                                    key={index}
                                                    current={0}
                                                    required={0}
                                                    week={0}
                                                    name={d}
                                                    icon={iconsArray[index] || ""}
                                                    edited={PAdata?.edited || false}
                                                />
                                            ))
                                        )}
                                    </div>
                                ) :
                                (
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={handleSubmit}
                                        innerRef={formikRef} // Set the ref to the Formik instance
                                    >
                                        {({ errors, touched }) => (
                                            <Form className="d-flex flex-row align-items-center  justify-content-between mt-8">
                                                {
                                                    data && Object.keys(data)?.map((item: any, index: number) => {
                                                        return (
                                                            <div key={index} className="text-center d-flex flex__col align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <Field type="number" name={`${formArray[index]}Current`} className="analysis__input Helvetica-Neue-normal text-26 mr-2" />
                                                                    <span>/</span>
                                                                    <Field type="number" name={`${formArray[index]}Required`} className="analysis__input Helvetica-Neue-normal text-26 ml-2" />
                                                                </div>
                                                                <label htmlFor={`${formArray[index]}current`} className="report__label Helvetica-Neue-light mt-2">{analysisArray[index]}</label>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </Form>
                                        )}
                                    </Formik>
                                )

                        }

                    </>
                )
                }
            </div>
        // {/* </div> */}
    )
} 