import { useState, useEffect } from 'react';

export const useSession = <T>(
  key: string,
  initialValue: T | null = null,
  expirationDays: number = 1
) => {
  const [sessionData, setSessionData] = useState<T | null>(() => {
    const savedData = sessionStorage.getItem(key);
    if (savedData) {
      return JSON.parse(savedData);
    }
    return initialValue;
  });

  useEffect(() => {
    if (sessionData !== null) {
      const expirationTime = new Date();
      expirationTime.setDate(expirationTime.getDate() + expirationDays);
      sessionStorage.setItem(key, JSON.stringify(sessionData));
      sessionStorage.setItem(`${key}_expiration`, expirationTime.toString());
    } else {
      sessionStorage.removeItem(key);
      sessionStorage.removeItem(`${key}_expiration`);
    }
  }, [sessionData, key, expirationDays]);

  useEffect(() => {
    const savedTime = sessionStorage.getItem(`${key}_expiration`);
    if (savedTime) {
      const expirationTime = new Date(savedTime);
      const currentTime = new Date();
      if (currentTime > expirationTime) {
        setSessionData(null);
      }
    }
  }, [key]);

  return { sessionData, setSessionData };
};
