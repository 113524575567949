export const  defaultOptions = [ "Performance Analysis", 
"Top Performing Content", "Influencer Performance Breakdown", 
"Channel Performance", "Campaign Efficiency", "Influencer Efficiencies", 
"Earned Media Value", 
"Actionable insights", "Links and Attachments"]

export const  defaultKpiOptions = [ 
    "Views/Impressions", 
    "Reach", 
    "Engagements", 
    "Organic ER", 
    "Clicks", 
    "CPE", 
    "Budget",
    "CPA", 
    "CPM",
    "Combined CTR",
    "Organic CTR", 
    "Paid CTR",
    "Shares",
    "Add to Basket",
    "Sales",
    "EMV"
]

export const  defaultKpisOptions = [ 
    {
        label:"Views/Impressions",
        key:"impressions"
    }, 
    {
        label:"Reach",
        key:"reach"
    },
    {
        label:"Engagements",
        key:"engagements"
    },
    {
        label:"Organic ER",
        key:"engangementRate"
    },
    {
        label:"Clicks",
        key:"clicks"
    },
    {
        label:"CPE",
        key:"cpe"
    },
    {
        label:"Budget",
        key:"budget"
    },
    {
        label:"CPA", 
        key:"cpa"
    },
    {
        label:"CPM",
        key:"cpm"
    },
    {
        label:"Combined CTR",
        key:"combinedctr"
    },
    {
        label:"Organic CTR",
        key:"organicctr"
    },
    {
        label:"Paid CTR",
        key:"paidctr"
    },
    {
        label:"Shares",
        key:"shares"
    },
    {
        label:"Add to Basket",
        key:"addToBasket"
    },
    {
        label:"Sales",
        key:"sales"
    },
    {
        label:"EMV",
        key:"emv"
    },
    
]