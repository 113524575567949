import { useState } from 'react'
import { Link } from "react-router-dom"

import ExpandedPostDetails from '../shared/ExpandPost'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

const RoundUpNumber = ({ number, hasDecmals }: any) => {
    // let breakDown = number < 1000 ? number : `${Math.floor(number / 1000)}`


    if (number) {
        let valToUse = number.toFixed(0);

        if (number >= 1000000) {
            // valToUse = `${Number(number.toFixed(0)) / 1000000}`;
            valToUse = `${Number(number / 1000000).toFixed(0)}`;
            return (
                <div className="value_txt">{valToUse}<span className="value_unit">m</span></div>
            )
        }
        if (number >= 1000) {
            valToUse = `${Number(number / 1000).toFixed(0)}`;
            return (
                <div className="value_txt">{valToUse}<span className="value_unit">k</span></div>
            )
        }

        if (hasDecmals) {
            valToUse = `${Number(number).toFixed(2)}`

            return (
                <div className="value_txt">{valToUse}</div>
            )
        }


        return (
            <div className="value_txt">{valToUse}</div>
        )
    }

    return (
        <div className="value_txt"></div>
    )

}


const TopPostsFromChannel = (props: any) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [postDetails, setPostDetails] = useState<any>();
    let { brandsSummaryInfo, chosenBrand, brandSocialPosts, selectedChannel } = props;

    let allSocialPostsFromBand: any[] = [...brandSocialPosts?.socialPosts];
    let allSocialPostsFromBandCompetitors: any[] = [...brandSocialPosts?.competitorPosts?.socialPosts];

    allSocialPostsFromBand = allSocialPostsFromBand.map((eachItem: any) => {
        let calculatedRates = (eachItem.followers && eachItem?.engagementTotal && eachItem.followers > 0) ? eachItem?.engagementTotal / eachItem.followers : '-';
        return {
            ...eachItem,
            calculatedRates
        }
    })

    allSocialPostsFromBandCompetitors = allSocialPostsFromBandCompetitors.map((eachItem: any) => {
        let calculatedRates = (eachItem.followers && eachItem?.engagementTotal && eachItem.followers > 0) ? eachItem?.engagementTotal / eachItem.followers : '-';
        return {
            ...eachItem,
            calculatedRates
        }
    })

    let selectedChannelPosts: any[] = allSocialPostsFromBand.filter((eachItem: any) => eachItem?.channel == selectedChannel);
    let competitorSelectedChannelPosts: any[] = allSocialPostsFromBandCompetitors.filter((eachItem: any) => eachItem?.channel == selectedChannel);





    let channelTopPostsOnly: any[] = [];
    let channelCompetitiorTopPostsOnly: any[] = [];

    if (selectedChannel == "all") {
        allSocialPostsFromBand.sort((a: any, b: any) => (b.calculatedRates - a.calculatedRates));
      
        channelTopPostsOnly = allSocialPostsFromBand.slice(0, 8);
      

        allSocialPostsFromBandCompetitors.sort((a: any, b: any) => (b.calculatedRates - a.calculatedRates));
      
        channelCompetitiorTopPostsOnly = allSocialPostsFromBandCompetitors.slice(0, 8);


    } else {

        selectedChannelPosts.sort((a: any, b: any) => (b.calculatedRates - a.calculatedRates));
        
        channelTopPostsOnly = selectedChannelPosts.slice(0, 8);

        competitorSelectedChannelPosts.sort((a: any, b: any) => (b.calculatedRates - a.calculatedRates));
        

        channelCompetitiorTopPostsOnly = competitorSelectedChannelPosts.slice(0, 8);

    }


    const handleShowModal = () => {
        setShowModal((prev) => !prev);

    }



    return (
        <div className="competing_glance">
            {showModal && <ExpandedPostDetails postDetails={postDetails} showModal={showModal} handleShowModal={handleShowModal} />}
            <div className="each_side" id="slide15">

                <div className="side_heading">Top Social Posts - {chosenBrand?.companyName}</div>
                {channelTopPostsOnly.length >= 1 &&
                    <>
                        <div className="all_top_channel_posts">

                            {
                                channelTopPostsOnly.map((eachPost: any, index: any) => {

                                    return (
                                        <div className="each_channel_post" key={index}>
                                            <div 
                                            // to={{ pathname: eachPost?.postLink }} target="_blank"
                                                className="c_post_thumbnail"
                                                onClick={()=>{
                                                    
                                                    setPostDetails(eachPost)
                                                    handleShowModal()
                                                }}
                                            >
                                                <img src={eachPost?.image} alt="" className="post_thumb_img" />
                                                {selectedChannel == "all" &&
                                                    <div className="channel_icon">
                                                        {eachPost?.channel == "tiktok" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {eachPost?.channel == "instagram" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {eachPost?.channel == "youtube" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/yt_main.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {eachPost?.channel == "twitter" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {eachPost?.channel == "facebook" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                }
                                                {selectedChannel != "all" &&
                                                    <div className="channel_icon">
                                                        {selectedChannel == "tiktok" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {selectedChannel == "instagram" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {selectedChannel == "youtube" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/yt_main.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {selectedChannel == "twitter" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {selectedChannel == "facebook" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div className="c_post_details">
                                                {eachPost?.message &&
                                                    <div className="post_txt">
                                                        {eachPost?.message}
                                                    </div>
                                                }
                                                <div className="each_meta">
                                                    <div className="meta_name">Type</div>
                                                    <div className="meta_value">{eachPost?.type}</div>
                                                </div>
                                                <div className="each_meta">
                                                    <div className="meta_name">ER</div>
                                                    <div className="meta_value">
                                                        {isNaN(eachPost?.calculatedRates) ? "-" : `${(eachPost?.calculatedRates * 100).toFixed(2)}%`}
                                                    </div>
                                                </div>
                                                <div className="each_meta">
                                                    <div className="meta_name">Likes</div>
                                                    <div className="meta_value"><RoundUpNumber number={eachPost?.applause} /> </div>
                                                </div>
                                                <div className="each_meta">
                                                    <div className="meta_name">Comments</div>
                                                    <div className="meta_value"> <RoundUpNumber number={eachPost?.conversation} /></div>
                                                </div>
                                                <div className="each_meta">
                                                    <div className="meta_name">Views</div>
                                                    <div className="meta_value"> <RoundUpNumber number={eachPost?.estimatedImpressions} /> </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }
                {channelTopPostsOnly.length == 0 &&
                    <div className="no-posts">No Posts in this channel</div>
                }
            </div>
            <div className="each_side" id="slide16">

                <div className="side_heading">Top Social Posts - Competitors</div>
                {channelCompetitiorTopPostsOnly.length >= 1 &&
                    <>
                        <div className="all_top_channel_posts">

                            {
                                channelCompetitiorTopPostsOnly.map((eachPost: any, index: any) => {
                                    return (
                                        <div className="each_channel_post" key={index}>
                                            <div 
                                            // to={{ pathname: eachPost?.postLink }} target="_blank"
                                                className="c_post_thumbnail"
                                                onClick={()=>{
                                                    setPostDetails(eachPost)
                                                    handleShowModal()
                                                }}
                                            >
                                                <img src={eachPost?.image} alt="" className="post_thumb_img" />
                                                {selectedChannel == "all" &&
                                                    <div className="channel_icon">
                                                        {eachPost?.channel == "tiktok" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {eachPost?.channel == "instagram" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {eachPost?.channel == "youtube" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/yt_main.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {eachPost?.channel == "twitter" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {eachPost?.channel == "facebook" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                }
                                                {selectedChannel != "all" &&
                                                    <div className="channel_icon">
                                                        {selectedChannel == "tiktok" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {selectedChannel == "instagram" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {selectedChannel == "youtube" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/yt_main.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {selectedChannel == "twitter" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                        {selectedChannel == "facebook" &&
                                                            <img src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div className="c_post_details">
                                                {eachPost?.companyName &&
                                                    <div className="company_name">
                                                        {eachPost?.companyName}
                                                    </div>
                                                }
                                                {eachPost?.message &&
                                                    <div className="post_txt">
                                                        {eachPost?.message}
                                                    </div>
                                                }
                                                <div className="each_meta">
                                                    <div className="meta_name">Type</div>
                                                    <div className="meta_value">{eachPost?.type}</div>
                                                </div>
                                                <div className="each_meta">
                                                    <div className="meta_name">ER</div>
                                                    <div className="meta_value">
                                                        {isNaN(eachPost?.calculatedRates) ? "-" : `${(eachPost?.calculatedRates * 100).toFixed(2)}%`}
                                                    </div>
                                                </div>
                                                <div className="each_meta">
                                                    <div className="meta_name">Likes</div>
                                                    <div className="meta_value"><RoundUpNumber number={eachPost?.applause} /> </div>
                                                </div>
                                                <div className="each_meta">
                                                    <div className="meta_name">Comments</div>
                                                    <div className="meta_value"> <RoundUpNumber number={eachPost?.conversation} /></div>
                                                </div>
                                                <div className="each_meta">
                                                    <div className="meta_name">Views</div>
                                                    <div className="meta_value"> <RoundUpNumber number={eachPost?.estimatedImpressions} /> </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }
                {channelCompetitiorTopPostsOnly.length == 0 &&
                    <div className="no-posts">No Posts in this channel</div>
                }
            </div>
        </div>
    )
}




export default function TopSocialPostAnalysis(props: any) {
    
    const [selectedChannel, setSelectedChannel] = useState<any>("all")
    let { brandsSummaryInfo, chosenBrand, brandSocialPosts } = props;

    
    return (
        <div className="engagement_formats_wrap">
            <div className="engage_section_header with_action">
            
                <div className="section_heading_txt">Top Social Posts</div>
                <div className="list_channels">
                    <div className={selectedChannel == "all" ? "each_channel active_channel" : "each_channel"} onClick={() => setSelectedChannel("all")}>ALL</div>
                    <div className={selectedChannel == "tiktok" ? "each_channel active_channel" : "each_channel"} onClick={() => setSelectedChannel("tiktok")}>
                        <img src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                            alt=""
                        />
                    </div>
                    <div className={selectedChannel == "facebook" ? "each_channel active_channel" : "each_channel"} onClick={() => setSelectedChannel("facebook")}>
                        <img src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                            alt=""
                        />
                    </div>
                    <div className={selectedChannel == "twitter" ? "each_channel active_channel" : "each_channel"} onClick={() => setSelectedChannel("twitter")}>
                        <img src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                            alt=""
                        />
                    </div>
                    <div className={selectedChannel == "instagram" ? "each_channel active_channel" : "each_channel"} onClick={() => setSelectedChannel("instagram")}>
                        <img src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                            alt=""
                        />
                    </div>
                    <div className={selectedChannel == "youtube" ? "each_channel active_channel" : "each_channel"} onClick={() => setSelectedChannel("youtube")}>
                        <img src={toAbsoluteUrl('/media/icons/socials/yt_main.svg')}
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <TopPostsFromChannel selectedChannel={selectedChannel} chosenBrand={chosenBrand} brandsSummaryInfo={brandsSummaryInfo} brandSocialPosts={brandSocialPosts} />
        </div>
    )


}