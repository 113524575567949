export const currencyList = [
    {
        symbol:"$",
        label: "Dollar"
    },
    {
        symbol:"£",
        label: "Pound"
    },
    {
        symbol:"€",
        label: "Euro"
    },
    {
        symbol:"R$",
        label: "Brazillian dollar"
    },
    {
        symbol:"Mex$",
        label: "Mexican dollar"
    }
]