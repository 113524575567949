import { ApexOptions } from "apexcharts";
import { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import {defaultKpisOptions} from '../components/OptionsDropdown/defaultOptions'

import { metricValueFormatter } from "../../../../../../helper";


type DataPoint = "impressions" | "engangementRate" | "clicks" | "cpe" | "budget" | string;

type SummaryChartProp = {
    dataPoint: DataPoint,
    valueData: Record<string, any>;
    currency:string;
}

const customColors = {
    highGreen: "#06D6A0",
    inactiveGrey: "#F4F4F4",
    lowRed: "#FF0000",
    avgYellow: "#FFDD55",
}

function getThreshold(currentVal: number, targetVal: number) {
    if (targetVal === 0) return currentVal;
    return Math.floor((currentVal / targetVal) * 100);
}

// function thresholdToCurrentValue(threshold: number, targetVal: number) {
//     if (targetVal === 0) return threshold;
//     return (threshold * targetVal) / 100; 
// }

function getProgressColor(threshold: number, datapoint:string, currentValue: number, targetValue:number ) {
    // if ((datapoint === "cpm" || 
    // datapoint === "cpa" ) && threshold <= 100) {
    //     return customColors.highGreen;
    // }

    // if (( datapoint === "cpm" ||
    // datapoint === "cpa" ) && threshold >= 100){
    //     return customColors.lowRed;
    // }
    if (currentValue !== 0 && targetValue === 0) {
        return customColors.highGreen;
    }
    if (threshold < 50) {
        return customColors.lowRed;
    } else if (threshold >= 50 && threshold <= 69) {
        return customColors.avgYellow;
    } 
    else {
        return customColors.highGreen;
    }
}

function getValueFormat(dataPoint: DataPoint, value: number, currency:string): string {
    
    switch(dataPoint) {
        case "impressions": 
            return metricValueFormatter(value) || "0"
        case "clicks": 
            return metricValueFormatter(value) || "0"
        case "engangementRate":
            return `${value.toFixed(2) || "0"}%`;
        case "cpe": 
            return `${currency}${metricValueFormatter(value) || "0"}`;
        case "budget":
            return `${currency}${metricValueFormatter(value) || "0"}`;
        case "reach": 
            return metricValueFormatter(value) || "0"
        case "shares": 
            return metricValueFormatter(value) || "0"
        case "combinedctr": 
            return `${value.toFixed(2) || "0"}%`;
        case "cpm": 
            return `${currency}${metricValueFormatter(value) || "0"}`;
        case "cpa": 
            return `${currency}${metricValueFormatter(value) || "0"}`;
        case "emv": 
            return `${currency}${metricValueFormatter(value) || "0"}`;
        case "engagements": 
            return metricValueFormatter(value) || "0"
        case "addToBasket": 
            return metricValueFormatter(value) || "0"
        case "organicctr": 
            return `${value.toFixed(2) || "0"}%`;
        case "paidctr": 
            return `${value.toFixed(2) || "0"}%`;
        case "sales": 
            return `${currency}${metricValueFormatter(value) || "0"}`;
        default:
            return "-";
    }
}


function getLabel(dataPoint: DataPoint) {
    let itemValue = ""
    defaultKpisOptions.map(item =>{
        if(item?.key?.toString() === dataPoint.toString()){
            itemValue = item.label
        }
    })
    return itemValue
    // return defaultKpisOptions[dataPoint.toString()].label
    // switch (dataPoint) {
    //     case "impressions":
    //         return "Impressions";
    //     case "engangementRate":
    //         return "ER";
    //     case "clicks": 
    //         return "Clicks";
    //     case "cpe":
    //         return "CPE";
    //     case "budget":
    //         return "Budget";
    //     default:
    //         return "";
    // }
}

export const SummaryChart = ({dataPoint, valueData:values, currency }: SummaryChartProp) => {    
    const [stateOptions, setStateOptions] = useState<ApexOptions>({});
    

    let currentValue: number = 0
    let targetValue: number = 0;

    if (dataPoint === "budget") {
        currentValue = values?.totalSpend ?? 0;
        targetValue = values?.totalBudget ?? 0;
    } else {
        currentValue = values?.currentValue ?? 0;
        targetValue = values?.target ?? 0;
    }

   
    let threshold: number = 0

    if (dataPoint === "cpe" || dataPoint === "cpa" || dataPoint === "cpm"){
        threshold = getThreshold(targetValue, currentValue);
    }
    else {
        threshold = getThreshold(currentValue, targetValue);
    }

    useEffect(() => {
        let mounted = true;

        const progressColor = getProgressColor(threshold,dataPoint,currentValue,targetValue);
        const value = getValueFormat(dataPoint, currentValue, currency) || "0" ;
        const target = getValueFormat(dataPoint, targetValue, currency) || "0";
    
    
        const options: ApexOptions = {
            colors: [progressColor],
            plotOptions: {
                radialBar: {
                    startAngle: -110,
                    endAngle: 110,
                    offsetX: 0,
                    offsetY: 0,
                    track: {
                        background: customColors.inactiveGrey,
                        startAngle: -110,
                        endAngle: 110
                    },
                    hollow: {
                        size: "65"
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: "14px",
                            offsetY: 35,
                            color: "#8C8C8C"
                        },
                        
                        value: {
                            fontSize: "32px",
                            show: true,
                            color: "#000",
                            fontWeight: '700',
                            offsetY: -10,
                            formatter: function (val: number) {
                                return value;
                            },
                        }
                    },
                },
            },
            stroke: {
                lineCap: "round",
                width: 0
            },
            yaxis: {
                show: false
            },
            legend: {
                show: false
            },
            grid: {
                padding: {
                    top: -20,
                    bottom: -26,
                    left: -10,
                    right: -10
                }
            },
            labels: [`${' / ' + target || 0/0}`]
        }

        if (mounted) {
            setStateOptions(options);
        }

        return () => { mounted = false }
    }, [dataPoint, values]);
  

    return (
        // <div className="d-flex flex-column align-items-center justify-content-start">
        <div className="" style={{
            display:"grid"
        }}>
            
            {values && <div className="Helvetica-Neue-normal" style={{height:"200px", width:"200px"}}>
                <Chart type="radialBar" options={stateOptions} series={[threshold]} height={"100%"} width={"100%"}/>
            </div>}
            
            <span className="text-center Helvetica-Neue-normal d-block fw-bolder" style={{ fontSize: "20px", marginTop: "-45px"}}>{getLabel(dataPoint)}</span>
        </div>
    )
}  