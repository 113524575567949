import { toAbsoluteUrl } from '../../_metronic/helpers'

export function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
  }
  


  export function abvNum (n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  }

  export function addDefaultSrc(ev, banner) {
    if(banner){
      return ev.target.src = toAbsoluteUrl('/media/cpg/reportImg4.png');
    }
    else{
      return ev.target.src = toAbsoluteUrl('/media/cpg/na.png');
    }
  }


  export const percentage = (a,b) => {
    if(typeof(a) === "undefined" || typeof(b) === "undefined"){
        return "0"
    }
    let result  = (a/b)*100
    return result.toFixed(0)
}


export const handleSocialGradient = (name)=>{
  let social = {iconPath:"",color:""}
  
  if (name?.toLowerCase() === "instagram") {social.iconPath = "/media/cpg/insta.png"; social.color = "purple"};
  if (name?.toLowerCase() === "tiktok") { social.iconPath = "/media/cpg/tiktok.png"; social.color = "green"};
  if (name?.toLowerCase() === "youtube") {social.iconPath = "/media/cpg/youtube.png"; social.color = "red"};
  if (name?.toLowerCase() === "facebook") {social.iconPath = "/media/cpg/facebook.png"; social.color = "blue"};
  if (name?.toLowerCase() === "twitter") {social.iconPath = "/media/cpg/twitter.png"; social.color = "black"};
  if (name === "") {social.iconPath = "/media/cpg/na.png"; social.color = "green"};
  return social
}

export const handleSocialIcons = (socialNetwork) =>{
  if (socialNetwork.toLowerCase() === "instagram") 
        return "/media/cpg/insta.png";
  else if (socialNetwork.toLowerCase()=== "tiktok") 
      return "/media/cpg/tiktok.png";
  else if (socialNetwork.toLowerCase() === "youtube") 
      return "/media/cpg/youtube.png";
  else if (socialNetwork.toLowerCase() === "facebook") 
      return "/media/cpg/facebook.png";
  else {
      return "/media/cpg/twitter.png";
  }
}

export function formatFileSize(sizeInKB) {
  const units = ["KB", "MB", "GB"];
  let index = 0;
  while (sizeInKB >= 1024 && index < units.length - 1) {
    sizeInKB /= 1024;
    index++;
  }
  return `${sizeInKB.toFixed(2)} ${units[index]}`;
}

// Function to save data to session storage
const saveToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

// Function to remove data from session storage
const removeFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};