import {useEffect, useMemo, useRef, useState } from "react";

import { getSummary} from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";

import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../../_redux/campaign/Actions";
import { Formik, Form, Field, FormikProps } from "formik";
import axios from 'axios';
import { toast } from 'react-toastify'
import { EditButton } from "../../../../../../_metronic/layout/components/EditButton";

import {defaultKpisOptions} from "../components/OptionsDropdown/defaultOptions"


//component type declaration
type SummaryWidgetProps = { 
    campaignId: any, 
    isExternal?:boolean, 
    date:string;
    campaignSummary:any;
    onCancelEdits:()=>void;
    
}

export const SummaryWidgetEdits = ({ onCancelEdits, campaignId, date,campaignSummary,isExternal }:SummaryWidgetProps ) => {
    //reports dashboard redux slice action call
    const dispatch = useAppDispatch();
    //general redux slice action call
    const dispatches = useDispatch<any>();

    //holds the current KPI the user wishes to see
    const {displayKpi, settingData} = useAppSelector((state) => ({
        displayKpi: state.report.displayKpi,
        settingData: state.report.SectionRes
    }));

    const kpiData = (settingData?.data?.kpi.length !==0 ? settingData?.data?.kpi : displayKpi) || []
    
    const { loading} = useAppSelector((state) => state.report.summary);

    const formikRef = useRef<FormikProps<any>>(null);

    
    const { campaignStats} = useSelector(
        (state: any) => ({
            campaignStats: state.campaign.campaignStats,
        })
    );

    
    const [initValue, setInitValue] = useState({})

    const [campaignSummmaryData, setCampaignSummmaryData] = useState({})
    
    //updating useState variable initValue with initial kpi values 
    const updateValues = useMemo(()=>{
        setInitValue((prevState) => {
            const updatedValue: { [key: string]: any } = { ...prevState };
            Object.keys(campaignSummary).forEach((item) => {
        
              Object.keys(campaignSummary[item]).forEach((itemInner) => {
                // Perform any desired manipulation on the object properties here
                // For example, you can update specific values or add new properties
                // updatedValue[item][itemInner] = /* Updated value or new property */;
                
                if(itemInner === "currentValue"){
                    updatedValue[item+"current"] = campaignSummary[item][itemInner]
                }

                if(itemInner === "target"){
                    updatedValue[item+"target"] = campaignSummary[item][itemInner]
                }

                if(itemInner === "totalSpend"){
                    updatedValue[item+"current"] = campaignSummary[item][itemInner]
                }

                if(itemInner === "totalBudget"){
                    updatedValue[item+"target"] = campaignSummary[item][itemInner]
                }
                
                

              });
            });
          
            return updatedValue;
        });
    },[campaignSummary])

    //filtering what should be displayed in the view on if it's part of the kpi the user selected
    useEffect(() => {
        const handleFilterCampaignSummary = () => {

            const filteredObject = Object.fromEntries(Object.entries(campaignSummary).filter(([key, value]) => {
                if(kpiData.includes(key)){
                    return true
                }
                return false
              }));

              setCampaignSummmaryData(filteredObject)
              
        }
        if(campaignSummary !== undefined){
            handleFilterCampaignSummary()
        }
    }, [kpiData])
    
    //initialvalue decalration for formik
    const initialValues: any = initValue
    
    //submitting the editted values
    const handleSubmit = async (values: any) => {
        const API_URL = process.env.REACT_APP_API_URL;

        const data: { [key: string]: any } = {
            "campaign_id": campaignId,
            "campaign_actual": {},
            "campaign_target": {}
        }
        
        Object.keys(values).forEach((item) => {
            if(item.includes("current")){
                const kpiKey = item.replace(new RegExp("current", "g"), "");
                data["campaign_actual"][kpiKey] = values[item].toString()
            }


            if(item.includes("target")){
                const kpiKey = item.replace(new RegExp("target", "g"), "");
                if(kpiKey === "impressions"){
                    data["campaign_target"]["target_impression"] = values[item].toString()
                } else if(kpiKey === "engagements"){
                    data["campaign_target"]["target_engagement"] = values[item].toString()
                } 
                else if(kpiKey === "engangementRate"){
                    data["campaign_target"]["target_er"] = values[item].toString()
                } else{
                    data["campaign_target"]["target_"+kpiKey] = values[item].toString()
                }
            }
        })

        const payload = data

        try {
            const response = await toast.promise(axios.post(`${API_URL}api/new-reports/edit-campaign-actual`, payload), {
                pending: 'Updating campaign performanace summary',
                success: ` updated campaign performanace summary successfully!`,
                error: `Error updating: try again later`
            });

            if (response.status === 200) {
                dispatch(actions.fetchCampaignDetails(campaignId));
                dispatches(actions.fetchCampaignStats(campaignId)).then((res:any)=> {
                    onCancelEdits()
                })
                dispatch(actions.fetchStuff('activity',`?campaign_id=${campaignId}`));
                dispatch(getSummary({
                    campaign_id: campaignId,
                    date_range: date.toString(),
                    is_external: isExternal
                }));
                
            }
        }
        catch (error) {
            console.error('Error updating value:', error);
        }

    }
    

    
    

    return (
        <>
                {!loading && campaignSummary && campaignStats && (
                    <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    innerRef={formikRef} // Set the ref to the Formik instance
                >
                    {({ errors, touched }) => (
                        <Form className="d-flex flex-row align-items-center  justify-content-between mt-8">
                            {
                                Object.keys(campaignSummmaryData)?.slice(0,5).map((item: any, index: number) => {
                                    
                                    return (
                                        <div key={index} className="text-center d-flex flex__col align-items-center">
                                            <div className="d-flex align-items-center">
                                                <Field type="number" name={`${item}current`} className="analysis__input Helvetica-Neue-normal text-26 mr-2" />
                                                <span>/</span>
                                                <Field type="number" name={`${item}target`} className="analysis__input Helvetica-Neue-normal text-26 ml-2" />
                                            </div>
                                            <label htmlFor={`${item}`} className="report__label Helvetica-Neue-light mt-2">{item}</label>
                                        </div>
                                    )
                                })
                            }

                        </Form>
                    )}
                </Formik>
                )}

            <div className="d-flex align-items-center justify-content-end mt-6">
                <button className="button__delete__default Button border-none rounded p-3 mr__2"
                style={{width:"fit-content"}} 
                onClick={() => {
                    formikRef.current?.resetForm()
                    onCancelEdits()
                }}>
                    Cancel
                </button>
                <EditButton text="Save" handleClick={() => {
                    formikRef?.current?.submitForm();
                    
                }} />
            </div>
        </>
    )
}