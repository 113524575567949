interface CampaignSummary {
    [key:string] : any;
    // impressions: CampaignSummaryKeys['impressions'];
    // clicks: CampaignSummaryKeys['clicks'];
    // engangementRate: CampaignSummaryKeys['engangementRate'];
    // cpe: CampaignSummaryKeys['engangementRate'];
    // budget: CampaignSummaryKeys['budget'];
}

export const summaryKpiData = (campaignActivity:any,campaignStats:any, campaignDetails:any) => {
    var est = campaignActivity?.data?.new_er || parseFloat(campaignStats?.target_data?.campaign_actual?.engangementRate) || parseFloat(campaignStats?.target_data?.campaign_actual?.er) || 0
    var es100s = (est * 100) >= 100 ? est : est * 100;
    
    let campaignSummary: CampaignSummary = {
        impressions: {
            currentValue: campaignActivity?.data?.new_impressions || parseFloat(campaignStats?.target_data?.campaign_actual?.impressions) || 0,
            target: parseFloat(campaignStats?.target_data?.target_impression) || 0
        },
        clicks: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.clicks) || 0,
            target: parseFloat(campaignStats?.target_data?.target_clicks) || 0
        },
        engangementRate: {
            // currentValue:  parseFloat(campaignStats?.target_data?.campaign_actual?.engangementRate) || 0,
            currentValue: es100s || 0,
            target: parseFloat(campaignStats?.target_data?.target_er) || 0
        },
        cpe: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.cpe).toFixed(2) || 0,
            target: parseFloat(campaignStats?.target_data?.target_cpe) || 0
        },
        budget: {
            totalSpend: parseFloat(campaignStats?.target_data?.campaign_summary?.paid) || 0,
            totalBudget: (parseFloat(campaignStats?.target_data?.campaign_summary?.TotalBudget) ? 
            parseFloat(campaignStats?.target_data?.campaign_summary?.TotalBudget) : 
            parseFloat(campaignDetails?.TotalBudget))  || 0
        },
        combinedctr: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.combinedctr) || 0,
            target: parseFloat(campaignStats?.target_data?.target_combinedctr) || 0
        },
        cpm: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.cpm).toFixed(2) || 0,
            target: parseFloat(campaignStats?.target_data?.target_cpm) || 0
        },
        cpa: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.cpa).toFixed(2) || 0,
            target: parseFloat(campaignStats?.target_data?.target_cpa) || 0
        },
        emv: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.emv) || 0,
            target: parseFloat(campaignStats?.target_data?.target_emv) || 0
        },
        engagements: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.engagements) || 0,
            target: parseFloat(campaignStats?.target_data?.target_engagement) || 0
        },
        organicctr: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.organicctr) || 0,
            target: parseFloat(campaignStats?.target_data?.target_organicctr) || 0
        },
        paidctr: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.paidctr) || 0,
            target: parseFloat(campaignStats?.target_data?.target_paidctr) || 0
        },
        reach: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.reach) || 0,
            target: parseFloat(campaignStats?.target_data?.target_reach) || 0
        },
        sales: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.sales) || 0,
            target: parseFloat(campaignStats?.target_data?.target_sales) || 0
        },
        shares: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.shares) || 0,
            target: parseFloat(campaignStats?.target_data?.target_shares) || 0
        },
        addToBasket: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.addToBasket) || 0,
            target: parseFloat(campaignStats?.target_data?.target_addToBasket) || 0
        },
        
    }

    return campaignSummary
}