import { useState, useEffect } from "react";

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface Props {
    handleChanges: (type:any, data:any, index:any) => any;
    bgWhite?:boolean;
    editState?:boolean|null;
    type?:string;
    text?:string;
    data?:any;
    index?:number;
    cancelState: boolean|null;
    resetForm?:boolean;
}

function isHTMLElement(variable: any): variable is HTMLElement {
    return variable instanceof HTMLElement;
  }

export const BlockEditor = ({handleChanges, resetForm, cancelState, data, index, type, bgWhite, editState, text}: Props) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const handleDoubleTap = () => {
        setIsTruncated(!isTruncated);
    };
    
    const [editorState, setEditorState] = useState(
        () => {
            if (data && typeof(data) === "string") {
                return EditorState.createWithContent(
                    // @ts-ignore
                    ContentState.createFromBlockArray(convertFromHTML(data))
                )
            }
            return EditorState.createEmpty()

        },
    );
    const [convertedContent, setConvertedContent] = useState<any>("");

    useEffect(() => {

        let html: any = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);

    }, [editorState]);

    const createMarkup = (html: any) => {
        
        if (html && typeof(html) === "string") {
            return {
                __html: DOMPurify.sanitize(html)
            }
        }
        return {
            __html: `${text}</>`
        }
    }

    useEffect(()=>{
        
        if(resetForm){
            setEditorState(
                () => {
                    if (data && typeof(data) === "string") {
                        return EditorState.createWithContent(
                            // @ts-ignore
                            ContentState.createFromBlockArray(convertFromHTML(data))
                        )
                    }
                    return EditorState.createEmpty()
        
                },
            )
        }
    }, [resetForm])

    useEffect(()=>{
        
        setEditorState(
            () => {
                if (data && typeof(data) === "string") {
                    
                    return EditorState.createWithContent(
                        // @ts-ignore
                        ContentState.createFromBlockArray(convertFromHTML(data))
                    )
                }
                return EditorState.createEmpty()
    
            },
        )
    },[editState, data])

    
      useEffect(()=>{
        if(editState === false  && !cancelState){
            // console.log("hello submit form")
            handleChanges(type, convertedContent, index)
        }
    }, [editState])

    return(
        <>
        {
            !editState ? (
                <div className="bg-white p-6 border__round_24 ">
                    
                    <>
                    <div className={`text-container ${!isTruncated ? 'truncated' : 'text-truncate'}`} onDoubleClick={handleDoubleTap}
                        dangerouslySetInnerHTML={(convertedContent === "<p></p>" || convertedContent === "" || data) ? 
                        createMarkup(data) : createMarkup(convertedContent)}>
                            
                    </div>
                    <button onClick={handleDoubleTap} 
                    className="read-more-button Helvetica-Neue-light">
                        {isTruncated ? "Read More":"Read Less"}
                    </button>
                    </>
                </div>
            )  : 
        (
            <div style={{width:"100%"}}>
                <Editor
                editorStyle={{ height: '500px', overflow:"scroll"}}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="actionableEditorWrapper"
                editorClassName="actionableEditor Helvetica-Neue-normal"
                toolbarClassName="actionableEditorToolbar Helvetica-Neue-normal"
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image'],
                }}
            />
            </div>
        )
        }
        </>
    )
}