import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { Formik, Field } from 'formik';
import { Dropdown } from 'react-bootstrap';
import { useAppDispatch, useAppSelector} from "../../../../../../../setup/redux/hooks";
import { updateDisplayReports, updateDisplayKpis, getSectionRes } from "../../../../../../../setup/features/report.slice";

import {defaultOptions, defaultKpisOptions} from './defaultOptions.js'
import { useCallback, useEffect, useState } from "react";
import axios from 'axios';
import { toast } from 'react-toastify'
import { useParams } from "react-router-dom";

interface MyDropdownProps {
  optionList?: string[];
  isExternal?: boolean;
  isNotAmyris?:boolean;
}

export const OptionsDropdown: React.FC<MyDropdownProps> = ({ optionList,isNotAmyris,  isExternal }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [optionSelect, setOptionSelect] = useState("")
    // const [options, setOptions] = useState<string[]>(defaultOptions)

    let { id } = useParams<any>();
    

    const dispatch = useAppDispatch()

    const {displayKpi,displayReports, settingData} = useAppSelector((state) => ({
      displayKpi: state.report.displayKpi,
      displayReports: state.report.displayReports,
      settingData: state.report.SectionRes
  }));

    let sectionOptions = settingData?.data && settingData?.data?.sections?.length !== 0 ? settingData?.data?.sections : displayReports || [] 
    let kpiOptions = settingData?.data && settingData?.data?.kpi.length !==0 ? settingData?.data?.kpi : displayKpi || []

    useEffect(()=>{
      console.log("called multiple")
      dispatch(getSectionRes({
        campaign_id: id,
        is_external: isExternal
    }))
    },[id, isExternal, dispatch])
    

    const handleDropdownToggle = () => {
        setDropdownOpen((prevState) => !prevState);
        setOptionSelect("")
        // setDropdownOpen(!dropdownOpen);
      };
    
  return (

    <Formik
      initialValues={{ selectedOptions: sectionOptions, kpiOptions:kpiOptions }}
      onSubmit={async(values) => {
        const API_URL = process.env.REACT_APP_API_URL;

        if(optionSelect === "sections"){
          if(!isExternal){
            try{
              await toast.promise(axios.post(`${API_URL}/api/new-reports/save-sections`, {
                options:values.selectedOptions || [] ,
                type: "sections",
                campaign_id:id}),
                {
                pending: 'Updating sections',
                success: `Sections updated successfully!`,
                error: `Error updating sections : try again later`
              }).then(res =>{
                dispatch(getSectionRes({
                  campaign_id: id,
                  is_external: isExternal
              }))
              }).catch(err => console.log(err))
              
              
            } catch (error) {
              console.error('Error updating sections:', error);
            }
          }
          dispatch(updateDisplayReports(values.selectedOptions) )
        }

        
        if(optionSelect === "kpi"){
          
          if(!isExternal){
            try{
              await toast.promise(axios.post(`${API_URL}/api/new-reports/save-sections`, {
                options:values.kpiOptions || [] ,
                type: "kpi",
              campaign_id:id}),
                {
                pending: 'Updating kpi',
                success: `Sections updated successfully!`,
                error: `Error updating kpi : try again later`
              }).then(res =>{
                dispatch(getSectionRes({
                  campaign_id: id,
                  is_external: isExternal
              }))
              }).catch(err => console.log(err))
              
              
            } catch (error) {
              console.error('Error updating kpi:', error);
            }
          }
          dispatch(updateDisplayKpis(values.kpiOptions))
        }


        setOptionSelect("")
        handleDropdownToggle()
        
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Dropdown onToggle={handleDropdownToggle} show={dropdownOpen}>
            <Dropdown.Toggle  id="dropdown-basic" className="options-button" >
                Edit Settings
                <img src={toAbsoluteUrl("/media/cpg/cog-icon.png")} 
                alt="edit sections" style={{marginLeft:"8px"}}/>
            </Dropdown.Toggle>

            { dropdownOpen &&
             (
                <Dropdown.Menu className="dropdownMenu-attachment">
                    
                    <div className="editSettingsMenu">
                        <div className="attachmentsMenu__item" onClick={() => setOptionSelect("sections")}>
                            <img src={toAbsoluteUrl("/media/cpg/cog-icon.png")} alt="sections" 
                            style={{marginRight:"4px", width:"15px", height:"15px"}}/>
                            <p style={{fontSize:"14px", margin:0}}>Edit Sections</p>
                        </div>
                        <div className="attachmentsMenu__item" onClick={() => setOptionSelect("kpi")}>
                            <img src={toAbsoluteUrl("/media/cpg/cog-icon.png")} alt="kpi" 
                            style={{marginRight:"4px", width:"15px", height:"15px"}}/>
                            <p style={{fontSize:"14px", margin:0}}>Edit KPIs</p>
                        </div>
                    </div>
                </Dropdown.Menu>
            )
        }

           {dropdownOpen && optionSelect === "sections" && (
             <Dropdown.Menu>
                <div className="optionsMenu">
                {defaultOptions.map((option:string) => (
                    <div key={option} className="" 
                    style={{padding:"5px"}}>
                    <label className="d-flex justify-content-between align-items-center">
                        {option}
                        <Field
                        type="checkbox"
                        name="selectedOptions"
                        value={option}
                        className="custom-options-checkbox"
                        style={{accentColor:"teal", color:"white", width:"20px", height:"20px", 
                        borderRadius:"16px", cursor:"pointer"}}
                        />
                        
                    </label>
                    
                    </div>
                    
                    )
                )}

                <div className="d-flex align-items-center my-2 py-2" style={{gap:"20px"}}>
                  {/* {!isExternal &&  */}
                  <button onClick={() => {
                    handleDropdownToggle()
                    setOptionSelect("")
                  }} className="Button border-none section-button-delete font__600">Cancel</button>
                  <div className="section-button-submit d-flex align-items-center justify-content-center">
                    <button type="submit"  className="Button  border-none font__600 edit_section_submit" >Save</button>
                  </div>

                </div>
                    
                </div>
            </Dropdown.Menu>
           )}

          {dropdownOpen && optionSelect === "kpi" && (
             <Dropdown.Menu>
                <div className="optionsMenu">
                {defaultKpisOptions.map((option:any) => (
                    <div key={option?.label} className="" 
                    style={{padding:"5px"}}>
                    <label className="d-flex justify-content-between align-items-center">
                        {option?.label}
                        <Field
                        type="checkbox"
                        name="kpiOptions"
                        value={option?.key}
                        className="custom-options-checkbox"
                        style={{accentColor:"teal", color:"white", width:"20px", height:"20px", 
                        borderRadius:"16px", cursor:"pointer"}}
                        />
                        
                    </label>
                    
                    </div>
                    
                    )
                )}

                <div className="d-flex align-items-center my-2 py-2" style={{gap:"20px"}}>
                  {/* {!isExternal &&  */}
                  <button onClick={() => {
                    handleDropdownToggle()
                    setOptionSelect("")
                  }} className="Button border-none section-button-delete font__600">Cancel</button>
                  <div className="section-button-submit d-flex align-items-center justify-content-center">
                    <button type="submit"  className="Button  border-none font__600 edit_section_submit" >Save</button>
                  </div>

                </div>
                    
                </div>
            </Dropdown.Menu>
           )}

            
          </Dropdown>
        </form>
      )}
    </Formik>
  );
};

// export default OptionsDropdown;
